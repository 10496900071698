import React from 'react';

const PublicContext = React.createContext({
  SnackBarControl: {
    handleNewSnackBar: (
      message = 'عملیات با موفقیت انجام شد',
      variant = 'default',
      autoHideDuration = 4000,
      anchorOrigin = { horizontal: 'center', vertical: 'top' },
      haveCloseButton = true
    ) => {},
  },
  ShopButtonControl: {
    handleHide: () => {},
    handleShow: () => {},
    show: true,
  },
  BasketCounterControl: {
    HandleIncreaseBasket: () => {},
    HandleDecreaseBasket: () => {},
    HandleUpdateBasketCount: () => {},
    GetBasketCount: () => {},
    BasketCount: 0,
  },
  ShopDetails: {
    deliveryPrice: 0,
    minFreePrice: 0,
  },
  theme: {},
  ThemeMode: {
    mode: 'light',
    HandleUpdateThemeMode: (mode) => {},
  },
  AdminDetails: {
    fullName: '',
  },
});

export default PublicContext;
