import { useEffect, useState, useContext, createRef } from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import AddProductImageList from '../../components/admin/AddProductImageList';
import CategoriesTreeView from '../../components/admin/CategoriesTreeView';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';
import Delete from '@mui/icons-material/Delete';

const AddPost = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  const imageFileRef = createRef();

  const handleClearErrors = () => setErrorMsg([]);

  const handleAddProduct = async (e) => {
    setUploadPercent(0);
    setOpenConfrimDialog(false);
    setWaiting(true);
    let formData = new FormData();
    handleClearErrors();
    await Promise.all(
      images.map(async (imgageItem) => {
        formData.append('images', imgageItem.data);
      })
    );
    formData.append('text', document.getElementById('text-input').value);
    formData.append('title', document.getElementById('title-input').value);
    formData.append(
      'titleHead',
      document.getElementById('title-head-input').value
    );
    formData.append(
      'metaDescription',
      document.getElementById('metaDescription-input').value
    );
    formData.append(
      'metaKeyword',
      document.getElementById('metaKeyword-input').value
    );

    HttpClient.post('/admin/posts/add', formData, {
      onUploadProgress: (progressEvent) => {
        setUploadPercent(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(`ثبت شد`, 'success');
          router('/admin/posts');
        }
      })
      .catch((error) => {
        setErrorMsg(error.response.data.data.errors);
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setWaiting(false);
      });
  };

  const handleFileChange = (e) => {
    let _file = null;
    if (e.target.files[0]) {
      _file = e.target.files[0];
    }
    const img = {
      imageUrl: URL.createObjectURL(_file),
      data: _file,
      fileName: _file.name,
      _id: Math.random() * 100000,
      component: _file.type.includes('image/') ? 'img' : 'video',
    };
    if (img.data.size > 1048576 * 100) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر فایل (100 مگابایت)',
        'error'
      );
      return;
    }
    setImages((old) => {
      return [...old, img];
    });
  };

  const handleDeleteImage = (img) => {
    setImages((old) => {
      let newList = old.filter((i) => i._id != img._id);
      return newList;
    });
  };

  const Input = styled('input')({
    display: 'none',
  });

  useEffect(() => {}, []);

  const handleClickSave = () => {
    setOpenConfrimDialog(true);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <AdminLayout>
      {TitleSetter('افزودن پست | ' + SITE_NAME)}

      <Grid container spacing={2} pb={10}>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router('/admin/posts');
            }}
            size="small"
          >
            پست ها
          </Button>

          <Divider sx={{ marginY: 1 }} />

          <LoadingButton
            loading={loading || waiting}
            color="success"
            variant="contained"
            size="large"
            sx={{
              position: 'fixed',
              bottom: 10,
              left: 10,
              paddingY: 1,
              paddingX: 5,
              zIndex: (theme) => theme.zIndex.fab + 1,
            }}
            startIcon={<SaveIcon />}
            onClick={handleClickSave}
          >
            ذخیره
          </LoadingButton>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Input
                accept="image/*,video/*"
                id="images"
                type="file"
                onChange={handleFileChange}
                ref={imageFileRef}
              />
              <Button
                startIcon={<PhotoCamera />}
                aria-label="upload picture"
                variant="contained"
                onClick={() => {
                  imageFileRef.current.click();
                }}
              >
                افزودن فیلم یا تصویر
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {images.map((img) => {
                  return (
                    <Grid item xs={6} md={3}>
                      <Box
                        sx={{
                          p: 2,
                          border: '2px solid gray',
                          borderRadius: 4,
                          height: '100%',
                          position: 'relative',
                        }}
                      >
                        <Box
                          component={img.component}
                          sx={{
                            width: '100%',
                            maxWidth: 600,
                            height: 'auto',
                          }}
                          src={img.imageUrl}
                          controls={true}
                          width={50}
                          height={50}
                        />
                        <IconButton
                          color="error"
                          sx={{
                            mt: 1,
                            position: 'absolute',
                            left: '-10px',
                            top: '-18px',
                            backgroundColor: 'white',
                          }}
                          size="small"
                          varinat="filled"
                          onClick={() => {
                            handleDeleteImage(img);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                label="کپشن"
                id="text-input"
              />
              {errorMsg?.text && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.text}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="عنوان"
                id="title-input"
              />
              {errorMsg?.title && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.title}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="عنوان صفحه"
                id="title-head-input"
              />
              {errorMsg?.title && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.titleHead}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="توضیحات"
                id="metaDescription-input"
              />
              {errorMsg?.metaDescription && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.metaDescription}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="کلمه های کلیدی"
                id="metaKeyword-input"
              />
              {errorMsg?.metaKeyword && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.metaKeyword}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        loadingConfrimButton={waiting}
        loadingDeclineButton={waiting}
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        handleConfirm={handleAddProduct}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        open={waiting}
      >
        <CircularProgressWithLabel value={uploadPercent} />{' '}
      </Backdrop>
    </AdminLayout>
  );
};

export default AddPost;
