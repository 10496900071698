import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@mui/material';

export default function CustomFormDialog({
  children,
  handleClose,
  open,
  dialogTitle,
  isFullScreen = false,
}) {
  return (
    <div>
      <Dialog
        dir="rtl"
        fullScreen={isFullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
}
