import { useEffect, useState, useContext, createRef } from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import AddProductImageList from '../../components/admin/AddProductImageList';
import CategoriesTreeView from '../../components/admin/CategoriesTreeView';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';

let schema = yup.object().shape({
  bannerLink: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .required('نام الزامی است')
    .min(3, 'نام حداقل باید 3 حرفی باشد')
    .max(200, 'نام حداکثر باید 200 حرفی باشد'),
});

const AddBanner = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bannerLink, setBannerLink] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMsg, setErrorMsg] = useState([]);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);

  const imageFileRef = createRef();

  const handleClearErrors = () => setErrorMsg([]);

  const handleAddProduct = (e) => {
    if (!selectedImage) {
      return;
    }
    setWaiting(true);
    let formData = new FormData();

    formData.append('image', selectedImage.data);
    formData.append('Link', bannerLink);

    HttpClient.post('/admin/banners/add', formData)
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(`ثبت شد`, 'success');
          router('/admin/banners');
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally((re) => {
        setWaiting(false);
      });
  };

  const handleFileChange = (e) => {
    const img = {
      imageUrl: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      fileName: e.target.files[0].name,
    };
    if (img.data.size > 1048576) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر تصویر 1048576 بایت (1 مگابایت)',
        'error'
      );
      return;
    }
    setSelectedImage(img);
  };

  const handleDeleteImage = (img) => {
    setSelectedImage(null);
  };

  const Input = styled('input')({
    display: 'none',
  });

  useEffect(() => {}, []);

  const handleClickSave = () => {
    setLoading(true);
    schema
      .validate(
        {
          bannerLink: bannerLink,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        setOpenConfrimDialog(true);
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }

        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };
  return (
    <AdminLayout>
      {TitleSetter('افزودن بنر | ' + SITE_NAME)}

      <Grid container spacing={2} pb={10}>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router('/admin/banners');
            }}
            size="small"
          >
            بنر ها
          </Button>

          <Divider sx={{ marginY: 1 }} />

          <LoadingButton
            loading={loading || waiting}
            color="success"
            variant="contained"
            size="large"
            sx={{
              position: 'fixed',
              bottom: 10,
              left: 10,
              paddingY: 1,
              paddingX: 5,
              zIndex: (theme) => theme.zIndex.fab + 1,
            }}
            startIcon={<SaveIcon />}
            onClick={handleClickSave}
          >
            ذخیره
          </LoadingButton>
        </Grid>
        {errorMsg.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ paddingY: 1 }}>
              <Alert severity="warning">
                <AlertTitle>
                  پیغام اعتبار سنجی
                  <IconButton color="inherit" onClick={handleClearErrors}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </AlertTitle>
                {errorMsg.map((msg, index) => (
                  <div key={index}> - {msg}</div>
                ))}
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label="لینک"
                value={bannerLink}
                onChange={(e) => {
                  setBannerLink(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Input
                accept="image/*"
                id="images"
                type="file"
                onChange={handleFileChange}
                ref={imageFileRef}
              />
              <Button
                startIcon={<PhotoCamera />}
                aria-label="upload picture"
                variant="contained"
                onClick={() => {
                  imageFileRef.current.click();
                }}
              >
                انتخاب تصویر
              </Button>
            </Grid>
            {selectedImage != null ? (
              <Grid item xs={12}>
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    maxWidth: 600,
                    mx: 'auto',
                    height: 'auto',
                  }}
                  src={selectedImage.imageUrl}
                  width={50}
                  height={50}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        loadingConfrimButton={waiting}
        loadingDeclineButton={waiting}
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        handleConfirm={handleAddProduct}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AdminLayout>
  );
};

export default AddBanner;
