import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import httpStatus from 'http-status';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterJalali from '@date-io/date-fns-jalali';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Typography from '@mui/material/Typography';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import {
  EnglishToFarsiDateAndTimeWithTimestamp,
  EnglishToFarsiWithTimestamp,
} from '../../utils/DateManager';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';

const Lottery = () => {
  const publicContext = useContext(PublicContext);

  let nowTimestamp = Date.now();

  const [loading, setLoading] = useState(false);
  const [fromTimestamp, setFromTimestamp] = useState(nowTimestamp);
  const [toTimestamp, setToTimestamp] = useState(nowTimestamp);
  const [fromDate, setFromDate] = useState(new Date(nowTimestamp));
  const [toDate, setToDate] = useState(new Date(nowTimestamp));
  const [fromEnabledDate, setFromEnabledDate] = useState(false);
  const [toEnabledDate, setToEnabledDate] = useState(false);
  const [lotteryResult, setLotteryResult] = useState({});

  const handleChangeToDateValue = (newValue) => {
    setToDate(newValue);
    setToTimestamp(newValue.getTime());
  };

  const handleChangeFromDateValue = (newValue) => {
    setFromDate(newValue);
    setFromTimestamp(newValue.getTime());
  };
  const handeClickLottery = (newValue) => {
    setLoading(true);
    HttpClient.post('/admin/lottery/select-users-by-dates', {
      fromTimestamp: fromEnabledDate ? fromTimestamp : 0,
      toTimestamp: toEnabledDate ? toTimestamp : 0,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setLotteryResult(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {}, []);

  return (
    <LocalizationProvider dateAdapter={AdapterJalali}>
      <AdminLayout>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {TitleSetter('قرعه کشی | ' + SITE_NAME)}
            <Box sx={{ textAlign: 'center' }}>
              <Button startIcon={<LocalActivityIcon />}>قرعه کشی</Button>
              <Divider sx={{ marginY: 1 }} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={6} sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fromEnabledDate}
                        onChange={(e) => {
                          setFromEnabledDate(Boolean(e.target.checked));
                          setFromTimestamp(
                            Boolean(e.target.checked) ? Date.now() : 0
                          );
                        }}
                      />
                    }
                    label="از تاریخ"
                  />
                  {fromEnabledDate === true ? (
                    <DateTimePicker
                      label="از"
                      disableMaskedInput={true}
                      ampm={false}
                      value={fromDate}
                      onChange={handleChangeFromDateValue}
                      renderInput={(props) => (
                        <TextField fullWidth={true} {...props} />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={toEnabledDate}
                        onChange={(e) => {
                          setToEnabledDate(Boolean(e.target.checked));
                          setToTimestamp(
                            Boolean(e.target.checked) ? Date.now() : 0
                          );
                        }}
                      />
                    }
                    label="تا تاریخ"
                  />
                  {toEnabledDate === true ? (
                    <DateTimePicker
                      label="تا"
                      disableMaskedInput={true}
                      ampm={false}
                      value={toDate}
                      onChange={handleChangeToDateValue}
                      renderInput={(props) => (
                        <TextField fullWidth={true} {...props} />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton
                    variant="contained"
                    color="success"
                    loading={loading}
                    startIcon={<RadioButtonCheckedIcon />}
                    sx={{ marginX: 1 }}
                    onClick={handeClickLottery}
                  >
                    قرعه کشی
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <>
            <Grid item xs={12}>
              <Paper sx={{ p: 2.5, textAlign: 'center' }} elevation={10}>
                {lotteryResult.user ? (
                  <>
                    <Typography>
                      <InsertEmoticonIcon
                        sx={{ fontSize: 70, color: 'green' }}
                      />
                      <EmojiEventsIcon
                        sx={{ fontSize: 70, color: 'goldenrod' }}
                      />
                    </Typography>
                    <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
                      {lotteryResult.user.Phone}
                    </Typography>
                    <Typography varinat="body2">
                      تاریخ عضویت :{' '}
                      {EnglishToFarsiWithTimestamp(
                        lotteryResult.user.FirstSignin
                      )}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body2" color="gary">
                      در انتظار قرعه کشی...
                    </Typography>
                  </>
                )}
              </Paper>
            </Grid>
          </>
        </Grid>
      </AdminLayout>
    </LocalizationProvider>
  );
};

export default Lottery;
