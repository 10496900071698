import { useEffect, useState, useContext, Fragment, createRef } from 'react';
import * as yup from 'yup';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';

let schema = yup.object().shape({
  fullName: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .required('نام الزامی است')
    .min(3, 'نام حداقل باید 3 حرفی باشد')
    .max(80, 'نام حداکثر باید 80 حرفی باشد'),
  adminPassword: yup
    .string()
    .test(
      'len',
      'کلمه عبور باید حداقل 12 کاراکتر و شامل حروف بزرگ، حروف کوچک، اعداد و علائم باشد',
      (val) => {
        if (val.trim().length >= 0 && validator.isStrongPassword(val.trim()))
          return true;
        else return false;
      }
    ),
  phone: yup
    .string()
    .test('len', 'شماره مانند نمونه باید 11 رقمی باشد', (value) => {
      if (!value || value.length !== 11 || value[0] + value[1] !== '09')
        return false;
      else return true;
    }),
});

const AddAdmin = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [loading, setLoading] = useState(true);
  const [adminPassword, setAdminPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');

  const [errorMsg, setErrorMsg] = useState([]);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);

  const imageFileRef = createRef();

  const handleClearErrors = () => setErrorMsg([]);
  const handleChangeAdminPassword = (e) => {
    setAdminPassword(e.target.value);
  };
  const handleChangeFullName = (e) => {
    setFullName(e.target.value);
  };
  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAddAdmin = () => {
    setLoading(true);
    HttpClient.post('/admin/manage-admins/add', {
      adminPassword: adminPassword,
      fullName: fullName,
      phone: phone,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'مدیر جدید ثبت شد',
            'success'
          );
          router('/admin/manage-admins');
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  };

  const handleClickSave = () => {
    setLoading(true);
    schema
      .validate(
        {
          adminPassword: adminPassword.trim(),
          fullName: fullName.trim(),
          phone: phone.trim(),
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        setOpenConfrimDialog(true);
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }

        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('افزودن مدیر | ' + SITE_NAME)}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router('/admin/manage-admins');
            }}
            size="small"
          >
            مدیران
          </Button>

          <Divider sx={{ marginY: 1 }} />
        </Grid>
        {errorMsg.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ paddingY: 1 }}>
              <Alert severity="warning">
                <AlertTitle>
                  پیغام اعتبار سنجی
                  <IconButton color="inherit" onClick={handleClearErrors}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </AlertTitle>
                {errorMsg.map((msg, index) => (
                  <div key={index}> - {msg}</div>
                ))}
              </Alert>
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={fullName}
                onChange={handleChangeFullName}
                fullWidth={true}
                variant="outlined"
                label="نام کامل مدیر"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={phone}
                onChange={handleChangePhone}
                fullWidth={true}
                variant="outlined"
                label="شماره تلفن"
                placeholder="نمونه : (09112345678)"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={adminPassword}
                onChange={handleChangeAdminPassword}
                fullWidth={true}
                variant="outlined"
                label="کلمه عبور"
                helperText="کلمه عبور باید حداقل 12 کاراکتر و شامل حروف بزرگ، حروف کوچک، اعداد و علائم باشد"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                color="success"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleClickSave}
              >
                ذخیره
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        loadingDeclineButton={loading}
        loadingConfrimButton={loading}
        handleConfirm={handleAddAdmin}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AdminLayout>
  );
};

export default AddAdmin;
