import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuAppBar from '../components/admin/AppBar';
import BackToTop from '../components/default/BackToTop';

const AdminLayout = ({ children }) => {
  return (
    <>
      <div id="back-to-top-anchor"></div>
      <MenuAppBar />
      <Container maxWidth="xl">
        <Paper elevation={0} sx={{ mt: 1, p: 0.5, mb: 15 }}>
          {children}
        </Paper>
      </Container>
      <BackToTop />
    </>
  );
};

export default AdminLayout;
