import { useEffect, useState, useContext, Fragment, createRef } from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';

import ManageProductImageList from '../../components/admin/ManageProductImageList';
import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import { API_URL } from '../../utils/Constants';
import PublicContext from '../../contexts/PublicContext';
import CategoriesTreeView from '../../components/admin/CategoriesTreeView';
import React from 'react';

let schema = yup.object().shape({
  productName: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .required('نام الزامی است')
    .min(3, 'نام حداقل باید 3 حرفی باشد')
    .max(200, 'نام حداکثر باید 200 حرفی باشد'),
  productDescription: yup
    .string()
    .typeError('توضیحات را درست وارد کنید')
    .required('توضیحات الزامی است')
    .min(3, 'توضیحات حداقل باید 3 حرفی باشد')
    .max(10000, 'توضیحات حداکثر باید 10000 حرفی باشد'),
  optionId: yup
    .number()
    .typeError('گزینه انتخاب باید عدد باشد')
    .test('len', 'گزینه انتخاب را لحاظ نمایید', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
  categories: yup
    .array()
    .test('len', 'حداقل یک دسته را برای کالا انتخاب کنید', (val) => {
      if (val && val.length > 0) return true;
      return false;
    }),
});

const Input = styled('input')({
  display: 'none',
});

const ProductManage = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [productId, setProductId] = useState(params['productId']);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = React.useState('');
  const [product, setProduct] = useState({});
  const [stocks, setStocks] = useState([]);
  const [views, setViews] = useState(0);
  const [imageList, setImageList] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);

  const handleClearErrors = () => setErrorMsg([]);

  const mainImageFileRef = createRef();
  const galleryImageFileRef = createRef();

  const handleChangeOption = (e) => {
    setSelectedOption(e.target.value);
  };

  const GetProductDetails = () => {
    setLoading(true);
    HttpClient.post('/admin/products/get-one', {
      id: productId,
    })
      .then(async (httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setProduct(httpRes.data.data.productDetails);
          setProductName(httpRes.data.data.productDetails.Name);
          setProductDescription(httpRes.data.data.productDetails.Description);
          setSelectedOption(httpRes.data.data.productDetails.OptionId);
          setStocks(httpRes.data.data.productStocks);
          setViews(httpRes.data.data.Views ?? 0);
          setImageList(httpRes.data.data.images);
          if (
            httpRes.data.data.productCategories &&
            httpRes.data.data.productCategories.length >= 0
          ) {
            let oldCats = [];
            await Promise.all(
              httpRes.data.data.productCategories.map(async (cat) => {
                oldCats.push(Number(cat.CategoryId));
              })
            ).then(() => {
              setSelectedCategoryList([...oldCats]);
            });
          }
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);

        router(`/admin/products`);
      });
  };

  useEffect(() => {
    setLoading(true);

    HttpClient.post('/admin/category/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setCategoryList(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });

    GetProductDetails();

    HttpClient.post('/admin/product-options/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setOptionList(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleEditProduct = () => {
    setLoading(true);
    schema
      .validate(
        {
          productName: productName.trim(),
          optionId: selectedOption,
          categories: selectedCategoryList,
          productDescription: productDescription,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();

        HttpClient.post('/admin/products/edit-details', {
          productId: productId,
          productName: productName.trim(),
          optionId: selectedOption,
          categories: selectedCategoryList,
          productDescription: productDescription,
        })
          .then((httpRes) => {
            if (httpRes.status === httpStatus.OK) {
              publicContext.SnackBarControl.handleNewSnackBar(
                'اطلاعات با موفقیت ذخیره شد',
                'success'
              );
            }
          })
          .catch((error) => {
            HandleHttpError(error, publicContext);
          });
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }

        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeCheckbox = async (CategoryId) => {
    let categoryId = Number(CategoryId);
    let _selectedCategoryList = [...selectedCategoryList];
    let findedIndex = _selectedCategoryList.indexOf(categoryId);

    if (findedIndex !== -1) {
      _selectedCategoryList.splice(findedIndex, 1);
    } else {
      _selectedCategoryList.push(categoryId);
    }
    setSelectedCategoryList([..._selectedCategoryList]);
  };

  const handleDeleteImage = async (imageItem) => {
    setLoading(true);

    HttpClient.post('/admin/products/remove-product-picture', {
      pictureId: imageItem.PictureId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          let arrayOfObjects = [...imageList];
          let findedIndex = imageList.indexOf(imageItem);
          if (findedIndex !== -1) {
            arrayOfObjects.splice(findedIndex, 1);
          }
          setImageList([...arrayOfObjects]);

          GetProductDetails();
          publicContext.SnackBarControl.handleNewSnackBar(
            `تصویر حذف شد`,
            'success'
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        setLoading(false);
      });
  };

  const handleMainImageFileChange = (e) => {
    if (e.target.files[0].size > 1048576) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر تصویر 1048576 بایت (1 مگابایت)',
        'error'
      );
      return;
    }

    let formData = new FormData();

    formData.append('image', e.target.files[0]);
    formData.append('id', productId);

    HttpClient.post('/admin/products/edit-main-image', formData)
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          GetProductDetails();
          publicContext.SnackBarControl.handleNewSnackBar(
            `تصویر جدید ثبت شد`,
            'success'
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  };

  const handleGalleryImageFileChange = (e) => {
    if (e.target.files[0].size > 1048576) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر تصویر 1048576 بایت (1 مگابایت)',
        'error'
      );
      return;
    }

    let formData = new FormData();

    formData.append('image', e.target.files[0]);
    formData.append('id', productId);

    HttpClient.post('/admin/products/add-product-image', formData)
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          GetProductDetails();
          publicContext.SnackBarControl.handleNewSnackBar(
            `تصویر جدید ثبت شد`,
            'success'
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  };

  return (
    <AdminLayout>
      <Grid container>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router('/admin/products');
            }}
            size="small"
          >
            کالاها
          </Button>
          <Divider sx={{ marginY: 1 }} />
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={700} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: 1, pt: 3 }}>
              <Grid container spacing={1.5}>
                {errorMsg.length > 0 && (
                  <Grid item xs={12}>
                    <Box sx={{ paddingY: 1 }}>
                      <Alert severity="warning">
                        <AlertTitle>
                          پیغام اعتبار سنجی
                          <IconButton
                            color="inherit"
                            onClick={handleClearErrors}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </AlertTitle>
                        {errorMsg.map((msg, index) => (
                          <div key={index}> - {msg}</div>
                        ))}
                      </Alert>
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: 'end',
                  }}
                >
                  <LoadingButton
                    loading={loading}
                    color="warning"
                    variant="contained"
                    startIcon={<DynamicFeedIcon />}
                    onClick={() => {
                      router(`/admin/products/manage-stock/${productId}`);
                    }}
                  >
                    موجودی ها
                  </LoadingButton>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    تصویر اصلی
                    <Input
                      accept="image/*"
                      id="images"
                      type="file"
                      onChange={handleMainImageFileChange}
                      ref={mainImageFileRef}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => {
                        mainImageFileRef.current.click();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Typography>
                  <Paper sx={{ p: 1, mt: 1 }}>
                    <img
                      src={`${API_URL}${product.MainImage}`}
                      alt={product.Name}
                      style={{ width: '100%' }}
                    />
                  </Paper>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    گالری تصاویر
                    <Input
                      accept="image/*"
                      id="images"
                      type="file"
                      onChange={handleGalleryImageFileChange}
                      ref={galleryImageFileRef}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => {
                        galleryImageFileRef.current.click();
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Typography>

                  <ManageProductImageList
                    imageList={imageList}
                    handleDeleteImage={handleDeleteImage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 3 }} elevation={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth={true}
                          variant="outlined"
                          label="نام کالا"
                          id="Name"
                          value={productName}
                          onChange={(e) => {
                            setProductName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth={true}
                          variant="outlined"
                          label="توضیحات"
                          multiline
                          minRows={5}
                          maxRows={10}
                          id="Description"
                          inputProps={{
                            dir: 'ltr',
                            style: {
                              direction: 'ltr',
                            },
                          }}
                          value={productDescription}
                          onChange={(e) => {
                            setProductDescription(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="option-label">
                            گزینه انتخاب
                          </InputLabel>
                          <Select
                            labelId="option-label"
                            id="optionId"
                            value={selectedOption}
                            label="گزینه انتخاب"
                            onChange={handleChangeOption}
                          >
                            {optionList.map((opt, index) => (
                              <MenuItem
                                key={index}
                                value={Number(opt.OptionId)}
                              >
                                {String(opt.Name) === 'default'
                                  ? 'پیش فرض'
                                  : opt.Name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ marginY: 1 }} />
                        <Typography variant="body1">
                          انتخاب دسته بندی
                        </Typography>
                        <Paper sx={{ p: 2, marginY: 1.5 }}>
                          <CategoriesTreeView
                            list={categoryList}
                            selectedItems={selectedCategoryList}
                            handleChangeCheckbox={handleChangeCheckbox}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="GrayText">
                          تاریخ ثبت :
                          <Chip
                            label={product.CreateDate}
                            component="span"
                            size="small"
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="GrayText">
                          تعداد فروش تا این لحظه :{' '}
                          <Chip
                            label={product.SellCount}
                            component="span"
                            size="small"
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="GrayText">
                          تعداد بازدید :{' '}
                          <Chip label={views} component="span" size="small" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <LoadingButton
                          variant="contained"
                          color="success"
                          sx={{ mr: 1 }}
                          startIcon={<SaveIcon />}
                          loading={loading}
                          onClick={handleEditProduct}
                        >
                          ذخیره تغییرات
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Box mt={4}>
        <div
          dangerouslySetInnerHTML={{
            __html: productDescription,
          }}
        ></div>
      </Box>
    </AdminLayout>
  );
};

export default ProductManage;
