import { useEffect, useState, useContext, Fragment, createRef } from 'react';
import * as yup from 'yup';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';

let schema = yup.object().shape({
  fullName: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .required('نام الزامی است')
    .min(3, 'نام حداقل باید 3 حرفی باشد')
    .max(80, 'نام حداکثر باید 80 حرفی باشد'),
  newPassword: yup
    .string()
    .test(
      'len',
      'کلمه عبور جدید باید حداقل 12 کاراکتر و شامل حروف بزرگ، حروف کوچک، اعداد و علائم باشد',
      (val) => {
        if (val.trim().length > 0) {
          if (validator.isStrongPassword(val.trim())) return true;
          else return false;
        } else return true;
      }
    ),
  currentPassword: yup
    .string()
    .test(
      'len',
      'کلمه عبور فعلی باید حداقل 12 کاراکتر و شامل حروف بزرگ، حروف کوچک، اعداد و علائم باشد',
      (val) => {
        if (validator.isStrongPassword(val.trim())) return true;
        else return false;
      }
    ),
  email: yup.string().test('len', 'در وارد کردن ایمیل دقت نمایید', (value) => {
    if (value.trim().length > 0 && value.trim().length < 299) {
      if (validator.isEmail(value)) return true;
      else return false;
    } else {
      return true;
    }
  }),
});

const EditProfile = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');

  const [errorMsg, setErrorMsg] = useState([]);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);

  const handleClearErrors = () => setErrorMsg([]);

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };
  const handleChangeCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };
  const handleChangeFullName = (e) => {
    setFullName(e.target.value);
  };
  const handleChangePhone = (e) => {
    setEmail(e.target.value);
  };

  const handleEditAdmin = () => {
    setOpenConfrimDialog(false);
    setLoading(true);
    HttpClient.post('/admin/manage-admins/edit-admin-details', {
      currentPassword: currentPassword,
      newPassword: newPassword,
      fullName: fullName.trim(),
      email: email.trim(),
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.AdminDetails.fullName = fullName;
          publicContext.SnackBarControl.handleNewSnackBar(
            'تغییرات ذخیره شد',
            'success'
          );
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickSave = () => {
    setLoading(true);
    schema
      .validate(
        {
          currentPassword: currentPassword.trim(),
          newPassword: newPassword.trim(),
          fullName: fullName.trim(),
          email: email.trim(),
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        setOpenConfrimDialog(true);
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }

        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };

  useEffect(() => {
    HttpClient.post('/admin/manage-admins/get-admin-details')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setFullName(httpRes.data.data.FullName);
          setEmail(httpRes.data.data.Email);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('پروفایل مدیر | ' + SITE_NAME)}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <Button startIcon={<AdminPanelSettingsIcon />}>پروفایل مدیر</Button>
            <Divider sx={{ marginY: 1 }} />
          </Box>
        </Grid>
        {errorMsg.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ paddingY: 1 }}>
              <Alert severity="warning">
                <AlertTitle>
                  پیغام اعتبار سنجی
                  <IconButton color="inherit" onClick={handleClearErrors}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </AlertTitle>
                {errorMsg.map((msg, index) => (
                  <div key={index}> - {msg}</div>
                ))}
              </Alert>
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={fullName}
                onChange={handleChangeFullName}
                fullWidth={true}
                variant="outlined"
                label="نام کامل"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={email}
                onChange={handleChangePhone}
                fullWidth={true}
                variant="outlined"
                label="ایمیل"
                placeholder="نمونه : (example@mail.com)"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={currentPassword}
                onChange={handleChangeCurrentPassword}
                fullWidth={true}
                variant="outlined"
                type={'password'}
                label="کلمه عبور فعلی"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={newPassword}
                onChange={handleChangeNewPassword}
                fullWidth={true}
                type={'password'}
                variant="outlined"
                label="کلمه عبور جدید"
                helperText="کلمه عبور باید حداقل 12 کاراکتر و شامل حروف بزرگ، حروف کوچک، اعداد و علائم باشد"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                color="success"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleClickSave}
              >
                ذخیره
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        loadingDeclineButton={loading}
        loadingConfrimButton={loading}
        handleConfirm={handleEditAdmin}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AdminLayout>
  );
};

export default EditProfile;
