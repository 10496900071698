import { useEffect, useState, useContext, Fragment, createRef } from 'react';
import * as yup from 'yup';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import AddProductImageList from '../../components/admin/AddProductImageList';
import CategoriesTreeView from '../../components/admin/CategoriesTreeView';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import { TitleSetter } from '../../utils/HeadSetter';
import { API_URL, SITE_NAME } from '../../utils/Constants';

let schema = yup.object().shape({
  categoryName: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .required('نام الزامی است')
    .min(3, 'نام حداقل باید 3 حرفی باشد')
    .max(200, 'نام حداکثر باید 200 حرفی باشد'),

  categoryId: yup
    .number()
    .typeError('کد دسته باید عدد باشد')
    .test('len', 'کد دسته را لحاظ نمایید', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
});

const Input = styled('input')({
  display: 'none',
});

const EditCategory = ({
  loading,
  setLoading,
  handleCloseFormDialog,
  getCategories,
  categoryId = 0,
  parentId = 0,
}) => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [categoryImageUrl, setCategoryImageUrl] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);

  const handleClearErrors = () => setErrorMsg([]);
  const mainImageFileRef = createRef();

  const handleAddCategory = () => {
    setLoading(true);
    HttpClient.post('/admin/category/edit', {
      id: categoryId,
      categoryName: categoryName,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            `تغییرات ذخیره شد`,
            'success'
          );
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setOpenConfrimDialog(false);
        setLoading(false);
      });
  };

  const handleClickSave = () => {
    setLoading(true);
    schema
      .validate(
        {
          categoryName: categoryName.trim(),
          categoryId: categoryId,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        setOpenConfrimDialog(true);
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }
        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };

  const handleMainImageFileChange = (e) => {
    if (e.target.files[0].size > 1048576) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر تصویر 1048576 بایت (1 مگابایت)',
        'error'
      );
      return;
    }

    let formData = new FormData();

    formData.append('image', e.target.files[0]);
    formData.append('id', categoryId);

    HttpClient.post('/admin/category/edit-image', formData)
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          getCatDetails();
          publicContext.SnackBarControl.handleNewSnackBar(
            `تصویر جدید ثبت شد`,
            'success'
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  };

  const getCatDetails = () => {
    setLoading(true);
    HttpClient.post('/admin/category/get-one', {
      id: categoryId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setCategoryName(httpRes.data.data.Name);
          setCategoryImageUrl(httpRes.data.data.ImageUrl);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        handleCloseFormDialog();
        getCategories(parentId);
      });
  };
  useEffect(() => {
    getCatDetails();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {errorMsg.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ paddingY: 1 }}>
              <Alert severity="warning">
                <AlertTitle>
                  پیغام اعتبار سنجی
                  <IconButton color="inherit" onClick={handleClearErrors}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </AlertTitle>
                {errorMsg.map((msg, index) => (
                  <div key={index}> - {msg}</div>
                ))}
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label="نام دسته"
                value={categoryName}
                onChange={(e) => {
                  setCategoryName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                تصویر اصلی
                <Input
                  accept="image/*"
                  id="images"
                  type="file"
                  onChange={handleMainImageFileChange}
                  ref={mainImageFileRef}
                />
                <IconButton
                  color="primary"
                  onClick={() => {
                    mainImageFileRef.current.click();
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Typography>
              <Paper sx={{ p: 1, mt: 1 }}>
                {categoryImageUrl.length > 1 && (
                  <img
                    src={`${API_URL}${categoryImageUrl}`}
                    alt={categoryName}
                    style={{ width: '100%' }}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                color="success"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleClickSave}
              >
                ذخیره
              </LoadingButton>
              <LoadingButton
                loading={loading}
                size="large"
                onClick={() => {
                  setCategoryName('');
                  handleCloseFormDialog();
                }}
              >
                بستن
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        loadingDeclineButton={loading}
        loadingConfrimButton={loading}
        handleConfirm={handleAddCategory}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default EditCategory;
