import { useEffect, useState, useContext, Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';

import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME, API_URL } from '../../utils/Constants';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';

const ManageAdmins = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [adminsList, setaAdminList] = useState(0);
  const [willDeletedPhone, setWillDeletedPhone] = useState(0);

  const GetList = () => {
    setLoading(true);
    HttpClient.post('/admin/manage-admins/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setaAdminList(httpRes.data.data.rows);

          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        router('/admin', { replace: true });
      });
  };

  const ChangeAdminDisabled = (phone) => {
    setLoading(true);
    HttpClient.post('/admin/manage-admins/change-disbaled', {
      phone: phone,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'ذخیره شد',
            'default'
          );
          GetList();
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ChangeAdminToUser = () => {
    setOpenConfirm(false);
    setLoading(true);

    HttpClient.post('/admin/manage-admins/change-admin-to-user', {
      phone: willDeletedPhone,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'مدیر از لیست حذف شد',
            'success'
          );
          GetList();
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetList();
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('مدیران | ' + SITE_NAME)}
      <Box sx={{ textAlign: 'center' }}>
        <Button startIcon={<GroupIcon />}>مدیران</Button>
        <Divider sx={{ marginY: 1 }} />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <>
          <Paper sx={{ p: 1 }} elevation={5}>
            <Button
              startIcon={<AddIcon />}
              sx={{ mb: 2 }}
              onClick={() => {
                router('/admin/add-admin');
              }}
            >
              افزودن
            </Button>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {adminsList.map((adminsItem, index) => (
                <Fragment key={index}>
                  <ListItemButton alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Chip
                          label={adminsItem.FullName}
                          color="info"
                          variant="filled"
                          sx={{ mb: 0.5 }}
                          component="span"
                        />
                      }
                      secondary={
                        <Box sx={{ mb: 1 }} component="span">
                          <Chip
                            size="small"
                            label={adminsItem.UserId}
                            component="span"
                            variant="outlined"
                          />{' '}
                          <Chip
                            label={adminsItem.Phone}
                            size="small"
                            component="span"
                          />
                        </Box>
                      }
                    />

                    <Chip
                      component="span"
                      variant="outlined"
                      label={Boolean(adminsItem.Disabled) ? 'غیر فعال' : 'فعال'}
                      color={Boolean(adminsItem.Disabled) ? 'error' : 'success'}
                      onClick={(e) => {
                        e.stopPropagation();
                        ChangeAdminDisabled(adminsItem.Phone);
                      }}
                    />
                    <IconButton
                      component="span"
                      size="small"
                      color={'error'}
                      onClick={(e) => {
                        e.stopPropagation();
                        setWillDeletedPhone(adminsItem.Phone);
                        setOpenConfirm(true);
                      }}
                      sx={{ marginX: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemButton>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </Paper>

          <CustomConfirmDialog
            open={openConfirm}
            handleClose={() => {
              setOpenConfirm(false);
            }}
            handleConfirm={ChangeAdminToUser}
            text={'آیا مایل به حذف کارب از مدیریت هستید'}
          />
        </>
      )}
    </AdminLayout>
  );
};

export default ManageAdmins;
