import { useEffect, useState, useContext, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

const EditStockFeatureItem = ({
  stockFeatureName,
  stockFeatureText,
  productFeatureId,
  handleClickDeleteFeature,
  handleUpdateFeature,
}) => {
  const [stockFeatureTextState, setStockFeatureTextState] = useState('');

  useEffect(() => {
    setStockFeatureTextState(stockFeatureText);
  }, [stockFeatureText]);
  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2 }} elevation={2}>
        <Chip label={stockFeatureName} size="small" />
        <Grid container>
          <Grid item xs={1.5} sx={{ textAlign: 'center' }}>
            <IconButton
              color="error"
              onClick={() => {
                handleClickDeleteFeature(productFeatureId);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={9.5}>
            <TextField
              fullWidth={true}
              label="مقدار(متن)"
              variant="outlined"
              value={stockFeatureTextState}
              onChange={(e) => {
                setStockFeatureTextState(e.target.value);
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'center' }}>
            <IconButton
              color="primary"
              onClick={() => {
                handleUpdateFeature(stockFeatureTextState, productFeatureId);
              }}
            >
              <SaveIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default EditStockFeatureItem;
