import { useEffect, useState, useContext, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import {
  EnglishToFarsi,
  EnglishToFarsiDateAndTimeWithTimestamp,
} from '../../utils/DateManager';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME, API_URL } from '../../utils/Constants';
import Add from '@mui/icons-material/Add';
import PriceSplitter from '../../utils/PriceSplitter';

const GiftCodes = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(0);

  const GetList = () => {
    setLoading(true);
    HttpClient.get('/admin/gift-codes/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setData(httpRes.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        router('/admin', { replace: true });
      });
  };

  useEffect(() => {
    GetList();
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('کد تخفیف  | ' + SITE_NAME)}
      <Box sx={{ textAlign: 'center' }}>
        <Button startIcon={<SupervisedUserCircleIcon />}>کد تخفیف </Button>
        <Divider sx={{ marginY: 1 }} />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <>
          <Paper sx={{ p: 1 }} elevation={5}>
            <Box>
              <Button
                startIcon={<Add />}
                onClick={() => {
                  router('/admin/add-gift-code');
                }}
              >
                افزودن
              </Button>
            </Box>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {data.map((item) => (
                <Fragment key={item.GiftId}>
                  <ListItemButton alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Box sx={{ mb: 1, fontWeight: 700 }}>
                          {<Chip label={item.Code} variant="outlined" />}{' '}
                        </Box>
                      }
                      secondary={
                        <span dir="ltr">
                          <span dir="ltr">
                            {`${item.CountRemain + ' عدد مانده'} - ${
                              PriceSplitter(item.GiftOffPrice) + ' تومان'
                            } - ${item.Name}  - `}{' '}
                          </span>

                          <span dir="ltr">({item.OptionValue})</span>
                        </span>
                      }
                    />
                    <Button
                      onClick={() => {
                        router(`/admin/edit-gift-codes/${item.GiftId}`);
                      }}
                    >
                      ویرایش
                    </Button>
                  </ListItemButton>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </Paper>
        </>
      )}
    </AdminLayout>
  );
};

export default GiftCodes;
