import { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import validator from 'validator';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PriceSplitter from '../../utils/PriceSplitter';
import { SITE_NAME } from '../../utils/Constants';
import PublicContext from '../../contexts/PublicContext';

import CustomFormDialog from '../../components/CustomFormDialog';
import { TitleSetter } from '../../utils/HeadSetter';

let addStockSchma = yup.object().shape({
  optionValue: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .notRequired()
    .max(200, 'نام حداکثر باید 200 حرفی باشد'),

  buyPrice: yup
    .number()
    .typeError('قیمت خرید باید عدد باشد')
    .test('len', 'قیمت خرید باید از 0 بیشتر باشد', (val) => {
      if (val > 0) return true;
      else return false;
    }),
  offPrice: yup
    .number()
    .typeError('قیمت تخفیف باید عدد باشد')
    .test('len', 'قیمت تخفیف باید 0 یا بیشتر از 0  باشد', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
  finalPrice: yup
    .number()
    .typeError('قیمت نهایی باید عدد باشد')
    .test('len', 'قیمت نهایی باید از 0 بیشتر باشد', (val) => {
      if (val > 0) return true;
      else return false;
    }),
  interestPrice: yup
    .number()
    .typeError('قیمت سود باید عدد باشد')
    .test('len', 'قیمت سود باید از -1 بیشتر باشد', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
  availableStock: yup
    .boolean()
    .typeError('در وارد کردن حداقل موجودی هشدار دقت نمایید'),
  secondHand: yup.boolean().typeError('در وارد کردن دست دوم بودن دقت نمایید'),
});

const StockManage = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [productId, setProductId] = useState(params['productId']);
  const [product, setProduct] = useState({});
  const [stocks, setStocks] = useState([]);
  const [productDetails, setProductDetails] = useState('');
  const [errorMsg, setErrorMsg] = useState([]);
  const [optionValue, setOptionValue] = useState('');
  const [offPrice, setOffPrice] = useState(0);
  const [buyPrice, setBuyPrice] = useState(1);
  const [interestPrice, setInterestPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [alertMinStock, setAlertMinStock] = useState(0);
  const [availableStock, setAvailableStock] = useState(true);
  const [secondHand, setSecondHand] = useState(false);

  const handleClearErrors = () => setErrorMsg([]);

  const handleCloseFormDialog = (e) => {
    handleClearErrors();
    setOffPrice(0);
    setOptionValue('');
    setBuyPrice(0);
    setInterestPrice(0);
    setFinalPrice(0);
    setAvailableStock(true);
    setOpenFormDialog(false);
  };

  const GetProductDetails = async () => {
    setLoading(true);
    HttpClient.post('/admin/products/get-one', {
      id: productId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setProduct(httpRes.data.data.productDetails);
          setStocks(httpRes.data.data.productStocks);
          setProductDetails(httpRes.data.data.productDetails);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        router('/admin/products');
      });
  };

  useEffect(() => {
    GetProductDetails();
  }, []);

  const handleAddStock = () => {
    setLoading(true);
    addStockSchma
      .validate(
        {
          productId: productId,
          optionValue: optionValue,
          buyPrice: buyPrice,
          offPrice: offPrice,
          finalPrice: finalPrice,
          interestPrice: interestPrice,
          availableStock: availableStock,
          secondHand: secondHand,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();

        HttpClient.post('/admin/products/add-stock', {
          productId: productId,
          secondHand: secondHand,
          optionValue: optionValue.trim(),
          buyPrice: buyPrice,
          interestPrice: interestPrice,
          offPrice: offPrice,
          finalPrice: finalPrice,
          alertMinStock: alertMinStock,
          availableStock: availableStock,
        })
          .then((httpRes) => {
            if (httpRes.status === httpStatus.OK) {
              publicContext.SnackBarControl.handleNewSnackBar(
                'گزینه جدید ثبت شد',
                'success'
              );
              GetProductDetails();
              handleCloseFormDialog();
            }
          })
          .catch((error) => {
            HandleHttpError(error, publicContext);
          });
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }

        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };

  return (
    <AdminLayout>
      {TitleSetter('مدیریت موجودی | ' + SITE_NAME)}
      <Grid container>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router(`/admin/products/${productId}`);
            }}
            size="small"
          >
            جزئیات کالا
          </Button>
          <Divider sx={{ marginY: 1 }} />
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={700} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h6" textAlign="center">
                  {product.Name}
                </Typography>
                <Typography variant="h6">
                  <IconButton
                    sx={{ marginX: 3 }}
                    onClick={() => {
                      setOpenFormDialog(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>

                  <Typography variant="body1" component="span">
                    گزینه های (
                    {productDetails.OptionName === 'default'
                      ? 'پیش فرض'
                      : productDetails.OptionName}
                    )
                  </Typography>
                  <Chip
                    label={stocks.length}
                    component="span"
                    size="small"
                    sx={{ marginX: 2 }}
                  />
                </Typography>
                <Divider sx={{ marginY: 1.5 }} />
              </Grid>
              {stocks.map((stockItem, index) => (
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                  <Paper elevation={5} sx={{ p: 2 }}>
                    <Chip
                      label={
                        stockItem.OptionValue === 'default'
                          ? 'پیش فرض'
                          : stockItem.OptionValue
                      }
                    />
                    <IconButton
                      onClick={() => {
                        router(
                          `/admin/products/edit-stock/${stockItem.ProductStockId}`
                        );
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <Divider sx={{ marginY: 0.5 }} />

                    <List>
                      <ListItemButton>
                        <ListItemText>
                          موجودی فعلی{' '}
                          <Chip
                            variant="filled"
                            label={stockItem.Stock}
                            color={
                              Number(stockItem.Stock) <=
                              Number(stockItem.AlertMinimumStock)
                                ? 'error'
                                : 'default'
                            }
                          />
                        </ListItemText>
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemText>
                          قابلیت نمایش و خرید{' '}
                          <Chip
                            variant="outlined"
                            color={stockItem.Available ? 'success' : 'error'}
                            label={stockItem.Available ? 'فعال' : 'غیر فعال'}
                          />
                        </ListItemText>
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemText>
                          قیمت نهایی{' '}
                          <Chip
                            variant="outlined"
                            label={`${PriceSplitter(
                              stockItem.FinalPrice
                            )} تومان`}
                          />
                        </ListItemText>
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemText>
                          قیمت خرید{' '}
                          <Chip
                            label={`${PriceSplitter(stockItem.BuyPrice)} تومان`}
                          />
                        </ListItemText>
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemText>
                          مبلغ سود{' '}
                          <Chip
                            label={`${PriceSplitter(
                              stockItem.InterestPrice
                            )} تومان`}
                          />
                        </ListItemText>
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemText>
                          مبلغ تخفیف{' '}
                          <Chip
                            label={`${PriceSplitter(stockItem.OffPrice)} تومان`}
                          />
                          <Chip
                            label={`${stockItem.OffPercent} %`}
                            sx={{ marginX: 1 }}
                          />
                        </ListItemText>
                      </ListItemButton>
                    </List>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>

      <CustomFormDialog
        open={openFormDialog}
        dialogTitle={`افزودن گزینه (${productDetails.Name})`}
        handleClose={handleCloseFormDialog}
      >
        <Grid
          container
          spacing={2.5}
          sx={{
            pt: 1.5,
          }}
        >
          {errorMsg.length > 0 && (
            <Grid item xs={12}>
              <Box sx={{ paddingY: 1 }}>
                <Alert severity="warning">
                  <AlertTitle>
                    پیغام اعتبار سنجی
                    <IconButton color="inherit" onClick={handleClearErrors}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </AlertTitle>
                  {errorMsg.map((msg, index) => (
                    <div key={index}> - {msg}</div>
                  ))}
                </Alert>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setAvailableStock(e.target.checked);
                  }}
                  checked={availableStock}
                />
              }
              label="قابلیت نمایش و خرید"
            />
          </Grid>
          {productDetails.OptionName !== 'default' && (
            <Grid item xs={12}>
              <TextField
                label="نام گزینه کالا"
                fullWidth={true}
                value={optionValue}
                onChange={(e) => {
                  setOptionValue(e.target.value);
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              label="قیمت خرید"
              fullWidth={true}
              value={buyPrice}
              onChange={(e) => {
                if (
                  validator.isNumeric(e.target.value) &&
                  validator.isInt(e.target.value, { gt: 0 })
                ) {
                  setBuyPrice(Number(e.target.value));

                  setFinalPrice(
                    Number(e.target.value) +
                      Number(interestPrice) -
                      Number(offPrice) <
                      0
                      ? 0
                      : Number(e.target.value) +
                          Number(interestPrice) -
                          Number(offPrice)
                  );
                }
              }}
              helperText={`${PriceSplitter(buyPrice)} تومان`}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="قیمت سود"
              fullWidth={true}
              value={interestPrice}
              onChange={(e) => {
                if (validator.isNumeric(e.target.value)) {
                  setInterestPrice(Number(e.target.value));
                  setFinalPrice(
                    Number(buyPrice) +
                      Number(e.target.value) -
                      Number(offPrice) <
                      0
                      ? 0
                      : Number(buyPrice) +
                          Number(e.target.value) -
                          Number(offPrice)
                  );
                }
              }}
              helperText={`${PriceSplitter(interestPrice)} تومان`}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="تخفیف"
              fullWidth={true}
              value={offPrice}
              onChange={(e) => {
                if (
                  validator.isInt(e.target.value, { gt: -1 }) &&
                  Number(buyPrice) + Number(interestPrice) >=
                    Number(e.target.value)
                ) {
                  setOffPrice(Number(e.target.value));

                  setFinalPrice(
                    Number(buyPrice) +
                      Number(interestPrice) -
                      Number(e.target.value) <
                      0
                      ? 0
                      : Number(buyPrice) +
                          Number(interestPrice) -
                          Number(e.target.value)
                  );
                }
              }}
              helperText={`${PriceSplitter(offPrice)} تومان - (${Math.ceil(
                (100 * Number(offPrice)) /
                  (Number(buyPrice) + Number(interestPrice))
              )} %)`}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="قیمت نهایی"
              fullWidth={true}
              value={`${PriceSplitter(Number(finalPrice))} تومان`}
              variant="filled"
              disabled={true}
              onChange={(e) => {
                if (validator.isInt(e.target.value, { gt: 0 })) {
                  setFinalPrice(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="قیمت نهایی بدون تخفیف"
              fullWidth={true}
              value={`${PriceSplitter(
                Number(finalPrice) + Number(offPrice)
              )} تومان`}
              variant="filled"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="حداقل موجودی برای هشدار"
              fullWidth={true}
              value={alertMinStock}
              onChange={(e) => {
                if (validator.isNumeric(e.target.value))
                  setAlertMinStock(Number(e.target.value));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setSecondHand(e.target.checked);
                  }}
                  checked={secondHand}
                />
              }
              label="دست دوم"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ marginY: 1 }} />
            <LoadingButton
              variant="contained"
              color="success"
              startIcon={<SaveIcon />}
              onClick={handleAddStock}
            >
              ذخیره
            </LoadingButton>
            <LoadingButton
              sx={{
                marginX: 2,
              }}
              onClick={handleCloseFormDialog}
            >
              بستن
            </LoadingButton>
          </Grid>
        </Grid>
      </CustomFormDialog>
    </AdminLayout>
  );
};

export default StockManage;
