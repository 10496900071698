import { useEffect, useState, useContext, Fragment, createRef } from 'react';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME, API_URL } from '../../utils/Constants';
import { Grid, IconButton, styled } from '@mui/material';

const Input = styled('input')({
  display: 'none',
});

const Banners = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  const [filteredBanners, setFilteredBanners] = useState([]);
  const [productNameSearch, setProductNameSearch] = useState('');
  const [editBannerId, setEditBannerId] = useState(null);
  const imageFileRef = createRef();

  const handleProductNameSearch = (e) => {
    setProductNameSearch(e.target.value);
    setFilteredBanners([
      ...banners.filter((product) =>
        product.Link.includes(e.target.value.trim())
      ),
    ]);
  };
  const handleOnClickEditImage = (BannerId) => {
    setEditBannerId(BannerId);
  };
  const handleEditLink = (BannerId, Link) => {
    setWaiting(true);
    HttpClient.post('/admin/banners/edit-link', {
      BannerId,
      Link,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            `ویرایش شد`,
            'success'
          );
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally((re) => {
        setWaiting(false);
      });
  };

  const handleFileChange = (e) => {
    const img = {
      imageUrl: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      fileName: e.target.files[0].name,
    };
    if (img.data.size > 1048576) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر تصویر 1048576 بایت (1 مگابایت)',
        'error'
      );
      return;
    }

    // upalod
    let formData = new FormData();

    formData.append('image', e.target.files[0]);
    formData.append('BannerId', editBannerId);

    HttpClient.post('/admin/banners/edit-image', formData)
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          fetchdata();
          publicContext.SnackBarControl.handleNewSnackBar(`ثبت شد`, 'success');
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally((re) => {
        setWaiting(false);
      });
  };

  const handleDelete = (BannerId) => {
    let asnwer = window.confirm('آیا مایل به حذف هستید');
    if (asnwer) {
      setWaiting(true);
      HttpClient.post('/admin/banners/delete', {
        BannerId,
      })
        .then((httpRes) => {
          fetchdata();

          if (httpRes.status === httpStatus.OK) {
            publicContext.SnackBarControl.handleNewSnackBar(
              `حذف شد`,
              'success'
            );
          }
        })
        .catch((error) => {
          HandleHttpError(error, publicContext);
        })
        .finally((re) => {
          setWaiting(false);
        });
    }
  };
  function fetchdata() {
    HttpClient.get('/admin/banners/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setBanners(httpRes.data.data);
          setFilteredBanners([...httpRes.data.data]);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  }
  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <AdminLayout>
      {TitleSetter('بنر ها | ' + SITE_NAME)}
      <Box sx={{ textAlign: 'center' }}>
        <Button startIcon={<PlaylistAddCheckRoundedIcon />}>بنرها</Button>
        <Divider sx={{ marginY: 1 }} />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <Paper sx={{ p: 1 }} elevation={5}>
          <Input
            accept="image/*"
            id="images"
            type="file"
            onChange={handleFileChange}
            ref={imageFileRef}
          />

          <TextField
            sx={{ marginY: 2 }}
            fullWidth={true}
            value={productNameSearch}
            onChange={handleProductNameSearch}
            variant="filled"
            label="جستجو"
            autoFocus={true}
          />
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              router('/admin/add-banner');
            }}
            sx={{ mb: 4 }}
          >
            افزودن
          </Button>
          <Grid spacing={3} container>
            {filteredBanners.map((product, index) => (
              <Grid item xs={6} sm={4} key={product.BannerId}>
                <Box
                  sx={{
                    border: '2px solid lightgray',
                    borderRadius: '10px',
                  }}
                >
                  <Box>
                    <Box
                      component="img"
                      sx={{
                        width: '100%',
                        aspectRatio: '2/1',
                        height: 'auto',
                        borderRadius: '9px',
                      }}
                      src={`${API_URL}${product.ImageUrl}`}
                    />
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <TextField
                      label="لینک"
                      disabled={waiting}
                      size="small"
                      fullWidth
                      defaultValue={product.Link}
                      id={`inp-${product.BannerId}`}
                    />
                    <Button
                      fullWidth
                      sx={{ mt: 2, borderRadius: 4 }}
                      variant="contained"
                      color="success"
                      onClick={() => {
                        handleEditLink(
                          product.BannerId,
                          document.getElementById(`inp-${product.BannerId}`)
                            .value
                        );
                      }}
                    >
                      ذخیره متن لینک
                    </Button>
                    <Button
                      fullWidth
                      sx={{ mt: 0.5, borderRadius: 4 }}
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        handleOnClickEditImage(product.BannerId);
                        imageFileRef.current.click();
                      }}
                    >
                      ویرایش تصویر
                    </Button>
                    <Button
                      fullWidth
                      sx={{ mt: 0.5, borderRadius: 4 }}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handleDelete(product.BannerId);
                      }}
                    >
                      حذف
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
    </AdminLayout>
  );
};

export default Banners;
