import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import StorefrontIcon from '@mui/icons-material/Storefront';

import AppMenuDrawer from './AppMenuDrawer';
import PublicContext from '../../contexts/PublicContext';
const MenuAppBar = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  return (
    <>
      <AppBar
        position="sticky"
        color="primary"
        sx={{
          mb: 2,
        }}
      >
        <Toolbar>
          <AppMenuDrawer />
          <Typography
            variant="h6"
            gutterBottom
            component="h1"
            sx={{ flexGrow: 1 }}
          >
            پنل مدیران
          </Typography>

          <IconButton
            size="large"
            aria-label={
              publicContext.ThemeMode.mode === 'dark' ? 'حالت روز' : 'حالت شب'
            }
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            title={
              publicContext.ThemeMode.mode === 'dark' ? 'حالت روز' : 'حالت شب'
            }
            onClick={() => {
              publicContext.ThemeMode.HandleUpdateThemeMode(
                publicContext.ThemeMode.mode === 'dark' ? 'light' : 'dark'
              );
            }}
          >
            {publicContext.ThemeMode.mode === 'dark' ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
          <IconButton
            size="large"
            aria-label={'فروشگاه'}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            title={'فروشگاه'}
            onClick={() => {
              router('/');
            }}
          >
            <StorefrontIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MenuAppBar;
