import { Route } from 'react-router-dom';
import Dashboard from '../pages/admin/Dashboard';
import Products from '../pages/admin/Products';
import ProductManage from '../pages/admin/ProductManage';
import { AdminProtectedRoute } from './ProtectedRoute';
import AddProduct from '../pages/admin/AddProduct';
import StockManage from '../pages/admin/StockManage';
import EditStock from '../pages/admin/EditStock';
import Categories from '../pages/admin/Categories';
import ProductFeatureDefinitions from '../pages/admin/ProductFeatureDefinitions';
import ProductOptions from '../pages/admin/ProductOptions';
import EditStockFeatures from '../pages/admin/EditStockFeatures';
import Customers from '../pages/admin/Customers';
import ManageAdmins from '../pages/admin/ManageAdmins';
import AddAdmin from '../pages/admin/AddAdmin';
import EditProfile from '../pages/admin/EditProfile';
import EditSetting from '../pages/admin/EditSetting';
import Orders from '../pages/admin/Orders';
import ManageOrder from '../pages/admin/ManageOrder';
import Reports from '../pages/admin/Reports';
import Lottery from '../pages/admin/Lottery';
import GiftCodes from '../pages/admin/GiftCodes';
import AddGiftCode from '../pages/admin/AddGiftCode';
import EditGiftCode from '../pages/admin/EditGiftCode';
import Banners from '../pages/admin/Banners';
import AddBanner from '../pages/admin/AddBanner';
import Posts from '../pages/admin/Posts';
import AddPost from '../pages/admin/AddPost';
import EditPost from '../pages/admin/EditPost';

const AdminRoutes = () => {
  return (
    <>
      <Route index element={<Dashboard />}></Route>
      <Route path="/admin">
        <Route element={<AdminProtectedRoute />}>
          <Route index element={<Dashboard />}></Route>
          <Route path="add-product" element={<AddProduct />}></Route>
          <Route path="add-post" element={<AddPost />}></Route>
          <Route path="banners" element={<Banners />}></Route>
          <Route path="posts" element={<Posts />}></Route>
          <Route path="posts/edit/:postId" element={<EditPost />} />
          <Route path="add-banner" element={<AddBanner />}></Route>
          <Route path="products">
            <Route index element={<Products />}></Route>
            <Route path=":productId" element={<ProductManage />}></Route>
            <Route path="manage-stock/:productId" element={<StockManage />} />
            <Route path="edit-stock/:stockId" element={<EditStock />}></Route>
            <Route
              path="edit-stock-features/:stockId"
              element={<EditStockFeatures />}
            ></Route>
          </Route>
          <Route path="category">
            <Route index element={<Categories />}></Route>
            <Route path=":parentId" element={<Categories />} />
          </Route>
          <Route
            path="product-feature-definitions"
            element={<ProductFeatureDefinitions />}
          />
          <Route path="gift-codes" element={<GiftCodes />} />
          <Route path="edit-gift-codes/:giftId" element={<EditGiftCode />} />
          <Route path="add-gift-code" element={<AddGiftCode />} />
          <Route path="product-options" element={<ProductOptions />} />
          <Route path="customers" element={<Customers />} />
          <Route path="manage-admins" element={<ManageAdmins />} />
          <Route path="add-admin" element={<AddAdmin />} />
          <Route path="profile" element={<EditProfile />} />
          <Route path="setting" element={<EditSetting />} />
          <Route path="orders" element={<Orders />} />
          <Route path="reports" element={<Reports />} />
          <Route path="lottery" element={<Lottery />} />
          <Route path="order/:orderId" element={<ManageOrder />} />
          {/* <Route path="delivery-price" element={<DeliveryPrice />}></Route> */}
        </Route>
      </Route>
    </>
  );
};

export default AdminRoutes;
