import { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';

let schema = yup.object().shape({
  optionName: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .required('نام الزامی است')
    .min(3, 'نام حداقل باید 3 حرفی باشد')
    .max(200, 'نام حداکثر باید 200 حرفی باشد'),

  optionId: yup
    .number()
    .typeError('کد دسته باید عدد باشد')
    .test('len', 'کد دسته را لحاظ نمایید', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
});

const EditOptionDefinition = ({
  loading,
  setLoading,
  handleCloseFormDialog,
  getList,
  optionId = 0,
}) => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [optionName, setOptionName] = useState('');
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);

  const handleClearErrors = () => setErrorMsg([]);

  const handleAddOption = () => {
    setLoading(true);
    HttpClient.post('/admin/product-options/edit', {
      id: optionId,
      optionName: optionName,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          handleCloseFormDialog();
          getList();
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setOpenConfrimDialog(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    HttpClient.post('/admin/product-options/get-one', {
      id: optionId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setOptionName(httpRes.data.data.Name);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        handleCloseFormDialog();
        getList();
      });
  }, []);

  const handleClickSave = () => {
    setLoading(true);
    schema
      .validate(
        {
          optionName: optionName.trim(),
          optionId: optionId,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        setOpenConfrimDialog(true);
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }
        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        {errorMsg.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ paddingY: 1 }}>
              <Alert severity="warning">
                <AlertTitle>
                  پیغام اعتبار سنجی
                  <IconButton color="inherit" onClick={handleClearErrors}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </AlertTitle>
                {errorMsg.map((msg, index) => (
                  <div key={index}> - {msg}</div>
                ))}
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label="نام ویژگی"
                value={optionName}
                onChange={(e) => {
                  setOptionName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                color="success"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleClickSave}
              >
                ذخیره
              </LoadingButton>
              <LoadingButton
                loading={loading}
                size="large"
                onClick={() => {
                  setOptionName('');
                  handleCloseFormDialog();
                }}
              >
                بستن
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        loadingDeclineButton={loading}
        loadingConfrimButton={loading}
        handleConfirm={handleAddOption}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default EditOptionDefinition;
