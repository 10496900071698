import orderStatus from '../assets/orderStatus.json';

export const getOrderStatusColor = (stautsCode) => {
  let statusColor = 'default';
  switch (stautsCode) {
    case 1:
      statusColor = 'default';
      break;
    case 2:
      statusColor = 'primary';
      break;
    case 3:
      statusColor = 'warning';
      break;
    case 4:
      statusColor = 'success';
      break;
    case 5:
      statusColor = 'secondary';
      break;
    case 6:
      statusColor = 'error';
      break;
    default:
      statusColor = '';
      break;
  }
  return statusColor;
};

export const getOrderStatusText = (stautsCode) => {
  let status = orderStatus.find((item) => item.id === Number(stautsCode));
  return status.faTitle;
};
export const isProccessed = (stautsCode) => {
  return orderStatus.find((item) => item.id === Number(stautsCode)).id == 1;
};
