import { useEffect, useState, useContext, Fragment, createRef } from 'react';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME, API_URL } from '../../utils/Constants';
import { Grid, IconButton, styled } from '@mui/material';
import {
  EnglishToFarsi,
  EnglishToFarsiDateAndTimeWithTimestamp,
} from '../../utils/DateManager';

const Input = styled('input')({
  display: 'none',
});

const Posts = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [filteredBanners, setFilteredBanners] = useState([]);
  const [productNameSearch, setProductNameSearch] = useState('');
  const [editBannerId, setEditBannerId] = useState(null);
  const [page, setPage] = useState(1);
  const imageFileRef = createRef();

  const handleDelete = (postId) => {
    let asnwer = window.confirm('آیا مایل به حذف هستید');
    if (asnwer) {
      setWaiting(true);
      HttpClient.post('/admin/posts/delete', {
        postId,
      })
        .then((httpRes) => {
          fetchdata();

          if (httpRes.status === httpStatus.OK) {
            publicContext.SnackBarControl.handleNewSnackBar(
              `حذف شد`,
              'success'
            );
          }
        })
        .catch((error) => {
          HandleHttpError(error, publicContext);
        })
        .finally((re) => {
          setWaiting(false);
        });
    }
  };
  function fetchdata(_page) {
    HttpClient.post('/admin/posts/get', {
      page: _page ?? page ?? 1,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setPosts(httpRes.data.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  }
  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <AdminLayout>
      {TitleSetter('پست ها | ' + SITE_NAME)}
      <Box sx={{ textAlign: 'center' }}>
        <Button startIcon={<PlaylistAddCheckRoundedIcon />}>پست ها</Button>
        <Divider sx={{ marginY: 1 }} />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <Paper sx={{ p: 1 }} elevation={5}>
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              router('/admin/add-post');
            }}
            sx={{ mb: 4 }}
          >
            افزودن
          </Button>
          <Grid
            spacing={3}
            container
            style={{
              pointerEvents: waiting ? 'none' : '',
            }}
          >
            {posts.map((post) => (
              <Grid item xs={6} sm={4} key={post.PostId}>
                <Box
                  sx={{
                    border: '2px solid lightgray',
                    borderRadius: '10px',
                    p: 2,
                    cursor: 'pointer',
                  }}
                >
                  {post.Title}
                  <hr />
                  {EnglishToFarsiDateAndTimeWithTimestamp(post.CreatTimestamp)}
                  <hr />
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleDelete(post.PostId);
                    }}
                  >
                    حذف
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mx: 1,
                    }}
                    onClick={() => {
                      router(`/admin/posts/edit/${post.PostId}`);
                    }}
                  >
                    نمایش
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
    </AdminLayout>
  );
};

export default Posts;
