import { useEffect, useState, useContext, useRef } from 'react';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import SmsIcon from '@mui/icons-material/Sms';
import { useReactToPrint } from 'react-to-print';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PriceSplitter from '../../utils/PriceSplitter';
import { SITE_NAME } from '../../utils/Constants';
import PublicContext from '../../contexts/PublicContext';
import { EnglishToFarsiDateAndTimeWithTimestamp } from '../../utils/DateManager';
import { TitleSetter } from '../../utils/HeadSetter';
import CustomConfrimDialog from '../../components/CustomConfirmDialog';
import CustomConfrimDeleteDialog from '../../components/CustomConfirmDialog';
import {
  cityIdToFaName,
  provinceIdToFaName,
} from '../../utils/LocationConvertor';
import OrderStatusJson from './../../assets/orderStatus.json';

let editOrderSchema = yup.object().shape({
  addressText: yup
    .string()
    .typeError('آدرس را درست وارد کنید')
    .notRequired()
    .max(1000, 'نام حداکثر باید 1000 حرفی باشد'),

  orderId: yup
    .number()
    .typeError('کد سفارش باید عدد باشد')
    .test('len', 'کد سفارش باید از 0 بیشتر باشد', (val) => {
      if (val > 0) return true;
      else return false;
    }),

  orderStatus: yup
    .number()
    .typeError('وضعیت باید عدد باشد')
    .test('len', 'وضعیت باید 1 تا 5 باشد', (val) => {
      if (val >= 1 && val <= 5) return true;
      else return false;
    }),
});

const ManageOrder = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [orderId, setOrderId] = useState(params['orderId']);
  const [order, setOrder] = useState({});
  const [orderDetails, setOrderDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [openConfrimDeleteDialog, setOpenConfrimDeleteDialog] = useState(false);

  const handleClearErrors = () => setErrorMsg([]);

  const handlePrint = useReactToPrint({
    content: () => printAreaRef.current,
  });

  const printAreaRef = useRef();

  const GetOrderDetails = () => {
    setLoading(true);
    HttpClient.post('/admin/orders/get-one', {
      orderId: orderId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setOrder(httpRes.data.data.order);
          setOrderDetails(httpRes.data.data.details);
          setCurrentStatus(Number(httpRes.data.data.order.CurrentStatus));
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetOrderDetails();
  }, []);

  const handleDeleteOrder = () => {
    setOpenConfrimDeleteDialog(false);
    setLoading(true);
    HttpClient.post('/admin/orders/remove', {
      orderId: orderId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'سفارش حذف شد',
            'success'
          );
          router('/admin/orders', { replace: true });
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendOrderSms = () => {
    setOpenConfrimDialog(false);
    setLoading(true);
    HttpClient.post('/admin/orders/send-order-sms', {
      orderId: orderId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'پیامک ارسال شد',
            'success'
          );
          GetOrderDetails();
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditOrder = () => {
    setLoading(true);
    editOrderSchema
      .validate(
        {
          orderId: orderId,
          addressText: order.Address,
          orderStatus: currentStatus,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        HttpClient.post('/admin/orders/edit', {
          orderId: orderId,
          addressText: order.Address,
          orderStatus: currentStatus,
        })
          .then((httpRes) => {
            if (httpRes.status === httpStatus.OK) {
              publicContext.SnackBarControl.handleNewSnackBar(
                'تغییرات ثبت شد',
                'success'
              );
              GetOrderDetails();
            }
          })
          .catch((error) => {
            HandleHttpError(error, publicContext);
          });
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }
        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };

  const tableTheme = createTheme({
    ...publicContext.theme,
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: 16,
          },
        },
      },
    },
  });

  return (
    <AdminLayout>
      {TitleSetter(`سفارش ${orderId} | ` + SITE_NAME)}
      <Grid container>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router(`/admin/orders`);
            }}
            size="small"
          >
            سفارشات
          </Button>
          <Divider sx={{ marginY: 1 }} />
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={700} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Grid container spacing={2.5}>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      کاربر : {order.Phone}
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      شماره کارت پرداخت کننده : {order.PaymentGetewayCardPan}
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      مبلغ پرداختی :{' '}
                      {PriceSplitter(
                        Number(order.SumSellPrice) + Number(order.DeliveryPrice)
                      )}{' '}
                      تومان
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      مبلغ تخفیف : {PriceSplitter(order.SumOffPrice)} تومان
                    </Grid>
                    {order.OffCodeValue ? (
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                          fontSize: 12,
                        }}
                      >
                        ({order.OffCodeValue}) تخفیف کد هدیه :{' '}
                        {PriceSplitter(order.OffCodePrice)} تومان
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      هزینه کارمزد درگاه :{' '}
                      {PriceSplitter(order.PaymentGetewayFee)} ریال
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      شناسه پرداخت درگاه : {order.PaymentGetewayRefId}
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      هزینه کرایه :{' '}
                      {Number(order.DeliveryPrice) === 0
                        ? 'رایگان'
                        : `${PriceSplitter(order.DeliveryPrice)} تومان`}
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      تاریخ ثبت سفارش :{' '}
                      {EnglishToFarsiDateAndTimeWithTimestamp(
                        order.CreateTimeStamp
                      )}{' '}
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      تاریخ پرداخت :{' '}
                      {EnglishToFarsiDateAndTimeWithTimestamp(
                        order.PayTimestamp
                      )}{' '}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      تاریخ ارسال :{' '}
                      {Number(order.SendTimestamp) > 0
                        ? EnglishToFarsiDateAndTimeWithTimestamp(
                            order.SendTimestamp
                          )
                        : ' - '}{' '}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      تاریخ تحویل :{' '}
                      {Number(order.DeliverTimestamp) > 0
                        ? EnglishToFarsiDateAndTimeWithTimestamp(
                            order.DeliverTimestamp
                          )
                        : ' - '}{' '}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      تاریخ لغو :{' '}
                      {Number(order.CancelTimestamp) > 0
                        ? EnglishToFarsiDateAndTimeWithTimestamp(
                            order.CancelTimestamp
                          )
                        : ' - '}{' '}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      آدرس : استان ({provinceIdToFaName(order.Province)}){' - '}{' '}
                      شهرستان ({cityIdToFaName(order.City)}) - {order.Address} -
                      کد پستی ({order.PostalCode}) - به نام ({order.Fullname})
                      <Divider sx={{ marginY: 1 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="order-status-label">وضعیت</InputLabel>
                        <Select
                          size="small"
                          label="وضعیت"
                          labelId="order-status-label"
                          id="order-status-label"
                          value={currentStatus}
                          onChange={(e) => {
                            setCurrentStatus(Number(e.target.value));
                          }}
                        >
                          {OrderStatusJson.map((orderSts, index) => (
                            <MenuItem key={index} value={orderSts.id}>
                              {orderSts.faTitle}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Typography variant="caption" fontSize={9}>
                        بعد از ذخیره وضعیت، تاریخ های ارسال، لغو و تحویل ثبت می
                        شوند
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <LoadingButton
                        color="success"
                        startIcon={<SaveIcon />}
                        onClick={handleEditOrder}
                        size="small"
                      >
                        ذخیره
                      </LoadingButton>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <LoadingButton
                        startIcon={<SmsIcon />}
                        onClick={() => {
                          setOpenConfrimDialog(true);
                        }}
                        size="small"
                      >
                        فرستادن پیامک ارسال سفارش ({order.SendSmsCount ?? 0} بار
                        ارسال شده)
                      </LoadingButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        textAlign: 'end',
                      }}
                    >
                      <LoadingButton
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          setOpenConfrimDeleteDialog(true);
                        }}
                        size="small"
                      >
                        حذف سفارش
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {errorMsg.length > 0 && (
                <Grid item xs={12}>
                  <Box sx={{ paddingY: 1 }}>
                    <Alert severity="warning">
                      <AlertTitle>
                        پیغام اعتبار سنجی
                        <IconButton color="inherit" onClick={handleClearErrors}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </AlertTitle>
                      {errorMsg.map((msg, index) => (
                        <div key={index}> - {msg}</div>
                      ))}
                    </Alert>
                  </Box>
                </Grid>
              )}

              <Grid item xs={12}>
                <Divider sx={{ marginY: 2 }} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" component="h6" textAlign="center">
                  نسخه چاپی
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box
                  ref={printAreaRef}
                  sx={{
                    p: 2,
                  }}
                >
                  <Grid
                    container
                    sx={{
                      p: 1,
                      mb: 1,
                      border: '2px solid black',
                      borderRadius: 3,
                    }}
                    spacing={0.75}
                  >
                    <Grid item xs={12}>
                      <Typography
                        fontSize={15}
                        fontWeight={800}
                        textAlign={'center'}
                      >
                        {SITE_NAME} | سفارش کد : {orderId}
                      </Typography>
                      <Divider sx={{ borderWidth: 1, marginY: 0.5 }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontSize={9}
                      >
                        تاریخ سفارش :{' '}
                        {
                          EnglishToFarsiDateAndTimeWithTimestamp(
                            order.CreateTimeStamp
                          ).split(' ')[0]
                        }{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontSize={9}
                      >
                        مبلغ پرداختی : {PriceSplitter(order.PayAmount)} تومان
                      </Typography>
                    </Grid>
                    {Number(order.SumOffPrice) > 0 && (
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle1"
                          component="p"
                          fontSize={9}
                        >
                          مبلغ تخفیف : {PriceSplitter(order.SumOffPrice)} تومان
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={Number(order.SumOffPrice) > 0 ? 6 : 12}>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontSize={9}
                      >
                        هزینه کرایه :{' '}
                        {Number(order.DeliveryPrice) === 0
                          ? 'رایگان'
                          : `${PriceSplitter(order.DeliveryPrice)} تومان`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontSize={9}
                      >
                        آدرس : استان ({provinceIdToFaName(order.Province)})
                        {' - '} شهرستان ({cityIdToFaName(order.City)}) -{' '}
                        {order.Address} - کد پستی ({order.PostalCode}) - به نام
                        ({order.Fullname})
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider sx={{ borderWidth: 1 }} />
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 0.75 }}>
                      <ThemeProvider theme={tableTheme}>
                        <Paper
                          elevation={0}
                          sx={{ border: '2px solid black', borderRadius: 2 }}
                        >
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      fontSize: 10,
                                      width: '15%',
                                    }}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell align="left" sx={{ fontSize: 10 }}>
                                    نام
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{ fontSize: 10, width: '18%' }}
                                  >
                                    تعداد
                                  </TableCell>
                                  <TableCell align="left" sx={{ fontSize: 10 }}>
                                    قیمت
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {orderDetails.map((row, index) => {
                                  return (
                                    <TableRow
                                      key={row.DetailId}
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                        cursor: 'pointer',
                                        ':hover': {
                                          opacity: 0.5,
                                        },
                                      }}
                                      onClick={() => {
                                        router(
                                          `/admin/products/edit-stock/${row.ProductStockId}`
                                        );
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ fontSize: 10, width: '15%' }}
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        sx={{ fontSize: 10 }}
                                      >
                                        {row.Name}{' '}
                                        {row.OptionName !== 'default'
                                          ? `- (${row.OptionName} : ${row.OptionValue})`
                                          : ''}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        sx={{ fontSize: 10, width: '18%' }}
                                      >
                                        {row.Quantity}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        sx={{ fontSize: 10 }}
                                      >
                                        {PriceSplitter(row.SellPrice)} تومان
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                                <TableRow
                                  sx={{
                                    borderTop: '2px solid black',
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ fontSize: 10 }}
                                  >
                                    مجموع کالاها بدون تخفیف
                                  </TableCell>
                                  <TableCell align="left" sx={{ fontSize: 10 }}>
                                    {PriceSplitter(
                                      Number(order.SumSellPrice) +
                                        Number(order.SumOffPrice)
                                    )}{' '}
                                    تومان
                                  </TableCell>
                                  <TableCell align="left" sx={{ fontSize: 10 }}>
                                    مجموع کالاها با تخفیف
                                  </TableCell>
                                  <TableCell align="left" sx={{ fontSize: 10 }}>
                                    {PriceSplitter(Number(order.SumSellPrice))}{' '}
                                    تومان
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ fontSize: 10 }}
                                  >
                                    تخفیف کل
                                  </TableCell>
                                  <TableCell align="left" sx={{ fontSize: 10 }}>
                                    {PriceSplitter(Number(order.SumOffPrice))}{' '}
                                    تومان
                                  </TableCell>
                                  <TableCell align="left" sx={{ fontSize: 10 }}>
                                    کرایه
                                  </TableCell>
                                  <TableCell align="left" sx={{ fontSize: 10 }}>
                                    {Number(order.DeliveryPrice) === 0
                                      ? 'رایگان'
                                      : `${PriceSplitter(
                                          order.DeliveryPrice
                                        )} تومان`}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                  ></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left" sx={{ fontSize: 10 }}>
                                    مبلغ نهایی پرداخت
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{ fontSize: 10, fontWeight: 'bold' }}
                                  >
                                    {PriceSplitter(
                                      Number(order.SumSellPrice) +
                                        Number(order.DeliveryPrice) -
                                        (order.OffCodeValue &&
                                        order.OffCodeValue.length > 0
                                          ? parseInt(order.OffCodePrice)
                                          : 0)
                                    )}{' '}
                                    تومان
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={0.5}>
                    <Grid container flexWrap={'nowrap'}>
                      <Grid item xs={6} my={0.75}>
                        <Typography
                          variant="caption"
                          component="p"
                          textAlign={'start'}
                          fontSize={9}
                          noWrap
                        >
                          مدیر سفارش :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} my={0.75}>
                        <Typography
                          variant="caption"
                          component="p"
                          textAlign={'end'}
                          fontSize={9}
                        >
                          تاریخ چاپ :{' '}
                          {EnglishToFarsiDateAndTimeWithTimestamp(Date.now())}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6} my={1}>
                        <Box
                          sx={{
                            border: '3px solid gray',
                            borderRadius: 2,
                            height: 100,
                            px: 1,
                          }}
                        >
                          <Typography
                            component="p"
                            textAlign={'center'}
                            sx={{
                              mt: 1,
                              fontSize: 11,
                            }}
                          >
                            امضا و مهر فروشگاه
                          </Typography>
                        </Box>
                      </Grid>
                      {/* <Grid item xs={6} my={1} pl={1.5}>
                        <Box
                          sx={{
                            border: '3px solid gray',
                            borderRadius: 2,
                            height: 100,
                            px: 1,
                          }}
                        >
                          <Typography
                            component="p"
                            textAlign={'center'}
                            sx={{
                              mt: 1,
                              fontSize: 11,
                            }}
                          >
                            امضا و اثر انگشت مشتری
                          </Typography>
                        </Box>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Box>
                <LoadingButton
                  variant="contained"
                  startIcon={<PrintIcon />}
                  onClick={handlePrint}
                  sx={{ marginX: 0.25 }}
                  szie="small"
                >
                  چاپ
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <CustomConfrimDialog
        text={'آیا از ارسال پیامک مطمعن هستید؟'}
        open={openConfrimDialog}
        confrimButtonText="بـله"
        declineButtonText="خـیر"
        declineButtonVariant="contained"
        confrimButtonVariant="default"
        loadingConfrimButton={loading}
        handleConfirm={handleSendOrderSms}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <CustomConfrimDeleteDialog
        text={'آیا از حذف سفارش مطمعن هستید؟'}
        open={openConfrimDeleteDialog}
        confrimButtonText="بـله"
        declineButtonText="خـیر"
        declineButtonVariant="contained"
        confrimButtonVariant="default"
        loadingConfrimButton={loading}
        handleConfirm={handleDeleteOrder}
        handleClose={() => {
          setOpenConfrimDeleteDialog(false);
        }}
      />
    </AdminLayout>
  );
};

export default ManageOrder;
