import { useEffect, useState, useContext, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import validator from 'validator';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import { API_URL, SITE_NAME } from '../../utils/Constants';
import PublicContext from '../../contexts/PublicContext';
import { TitleSetter } from '../../utils/HeadSetter';
import CustomConfrimDialog from '../../components/CustomConfirmDialog';
import EditStockFeatureItem from '../../components/admin/EditStockFeatureItem';

let addSchma = yup.object().shape({
  text: yup
    .string()
    .required('مقدار الزامی است')
    .typeError('مقدار را درست وارد کنید')
    .min(1, 'مقدار حداقل باید 1 حرفی باشد')
    .max(200, 'مقدار حداکثر باید 200 حرفی باشد'),

  productFeatureDefinitionsId: yup
    .number()
    .typeError('کد ویژگی باید عدد باشد')
    .test('len', 'ویژگی را انتخاب کنید', (val) => {
      if (val > 0) return true;
      else return false;
    }),
  productStockId: yup
    .number()
    .typeError('کد گزینه باید عدد باشد')
    .test('len', 'کد گزینه باید از 0 بیشتر باشد', (val) => {
      if (val > 0) return true;
      else return false;
    }),
});

const EditStockFeatures = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [stockId, setStockId] = useState(params['stockId']);
  const [stockFeatures, setStockFeatures] = useState([]);
  const [selectedFeatureId, setSelectedFeatureId] = useState(0);
  const [willDeletedFeatureId, setWillDeletedFeatureId] = useState(0);
  const [selecteablsFeatures, setSelecteablsFeatures] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(0);
  const [newFeatureText, setNewFeatureText] = useState('');

  const GetList = async () => {
    HttpClient.post('/admin/product-features/get-stock-features', {
      productStockId: stockId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setStockFeatures(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  };

  const handleChangeSelectedFeatureId = (event) => {
    setSelectedFeatureId(event.target.value);
  };

  const handleClickDeleteFeature = (id) => {
    setWillDeletedFeatureId(Number(id));
    setOpenConfrimDialog(true);
  };

  const handleUpdateFeature = (text, id) => {
    setLoading(true);
    if (!validator.isLength(text.trim(), { min: 1, max: 200 })) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداقل 1 و حداکثر 200 حرف باید باشد',
        'warning'
      );
      setLoading(false);
      return;
    }
    HttpClient.post('/admin/product-features/edit', {
      id: id,
      text: text.trim(),
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'ویرایش ثبت شد',
            'success'
          );
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(async () => {
        await GetList();

        setLoading(false);
      });
  };

  const handleConfirmDeleteFeature = () => {
    setOpenConfrimDialog(false);
    setLoading(true);

    HttpClient.post('/admin/product-features/remove', {
      id: willDeletedFeatureId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'ویژگی حذف شد',
            'success'
          );
          GetList();
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeNewFeatureText = (event) => {
    setNewFeatureText(event.target.value);
  };

  const handleClearErrors = () => setErrorMsg([]);

  const handleAddFeature = () => {
    setLoading(true);

    addSchma
      .validate(
        {
          text: newFeatureText.trim(),
          productFeatureDefinitionsId: selectedFeatureId,
          productStockId: stockId,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();

        HttpClient.post('/admin/product-features/add', {
          text: newFeatureText.trim(),
          productFeatureDefinitionsId: selectedFeatureId,
          productStockId: stockId,
        })
          .then((httpRes) => {
            if (httpRes.status === httpStatus.OK) {
              GetList();
              publicContext.SnackBarControl.handleNewSnackBar(
                'ویژگی جدید ثبت شد',
                'success'
              );
              setNewFeatureText('');
              setSelectedFeatureId(0);
            }
          })
          .catch((error) => {
            HandleHttpError(error, publicContext);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }
        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    GetList();

    HttpClient.post('/admin/product-feature-definitions/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setSelecteablsFeatures(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('ویرایش ویژگی ها | ' + SITE_NAME)}
      <Grid container>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router(`/admin/products/edit-stock/${stockId}`);
            }}
            size="small"
          >
            گزینه
          </Button>
          <Divider sx={{ marginY: 1 }} />
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={700} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              spacing={2.5}
              sx={{
                pt: 1.5,
              }}
            >
              {errorMsg.length > 0 && (
                <Grid item xs={12}>
                  <Box sx={{ paddingY: 1 }}>
                    <Alert severity="warning">
                      <AlertTitle>
                        پیغام اعتبار سنجی
                        <IconButton color="inherit" onClick={handleClearErrors}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </AlertTitle>
                      {errorMsg.map((msg, index) => (
                        <div key={index}> - {msg}</div>
                      ))}
                    </Alert>
                  </Box>
                </Grid>
              )}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="add-feature-select-label">
                    انتخاب ویژگی
                  </InputLabel>
                  <Select
                    labelId="add-feature-select-labe"
                    value={selectedFeatureId}
                    label="انتخاب ویژگی"
                    onChange={handleChangeSelectedFeatureId}
                    size="small"
                  >
                    <MenuItem value={0}>انتخاب کنید</MenuItem>
                    {selecteablsFeatures.map((featureItem, index) => {
                      return (
                        <MenuItem value={featureItem.FeatureId} key={index}>
                          {featureItem.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  fullWidth={true}
                  label="مقدار(متن)"
                  variant="outlined"
                  value={newFeatureText}
                  onChange={handleChangeNewFeatureText}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={loading}
                  startIcon={<AddIcon />}
                  onClick={handleAddFeature}
                >
                  افزودن به لیست
                </LoadingButton>
              </Grid>

              {stockFeatures.map((stockFeature, index) => {
                return (
                  <EditStockFeatureItem
                    key={index}
                    stockFeatureName={stockFeature.Name}
                    stockFeatureText={stockFeature.Text}
                    productFeatureId={stockFeature.id}
                    handleClickDeleteFeature={handleClickDeleteFeature}
                    handleUpdateFeature={handleUpdateFeature}
                  />
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>

      <CustomConfrimDialog
        text={'آیا از حذف ویژگی مطمعن هستید؟'}
        open={openConfrimDialog}
        confrimButtonText="بـله"
        declineButtonText="خـیر"
        declineButtonVariant="contained"
        confrimButtonVariant="default"
        loadingDeclineButton={loading}
        loadingConfrimButton={loading}
        handleConfirm={handleConfirmDeleteFeature}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
    </AdminLayout>
  );
};

export default EditStockFeatures;
