import axios from 'axios';
import validator from 'validator';
import httpStatus from 'http-status';
import { API_URL, WEBSITE_URL } from './Constants';

const HttpClient = axios.create({
  baseURL: `${API_URL}/api/v1`,
  timeout: 30000,
});

export function AddUserTokenToHeader(token) {
  HttpClient.defaults.headers.common['user-token'] = token;
}
export function AddUserAdminTokenToHeader(token) {
  HttpClient.defaults.headers.common['admin-token'] = token;
}
export function RemoveUserTokenFromHeader() {
  delete axios.defaults.headers.common['user-token'];
}
export function RemoveUserAdminTokenFromHeader() {
  delete axios.defaults.headers.common['admin-token'];
}

// check exist user-token after refresh pages
const userToken = localStorage.getItem('user-token');
if (userToken && !validator.isEmpty(userToken)) AddUserTokenToHeader(userToken);

// check exist user-token after refresh pages
const adminToken = localStorage.getItem('admin-token');
if (adminToken && !validator.isEmpty(adminToken))
  AddUserAdminTokenToHeader(adminToken);

//error handler
export function HandleHttpError(error, publicContext) {
  if (axios.isAxiosError(error)) {
    if (error.response && error.response.status === httpStatus.FORBIDDEN) {
      publicContext.SnackBarControl.handleNewSnackBar('عدم دسترسی', 'error');
      setTimeout(() => {
        window.localStorage.clear();
        window.location.href = `${WEBSITE_URL}/admin`;
      }, 4000);
    } else if (
      error.response &&
      error.response.status === httpStatus.UNAUTHORIZED
    ) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'عدم دسترسی، در حال انتقال به صفحه ورود',
        'error'
      );
      setTimeout(() => {
        window.localStorage.clear();
        window.location.href = `${WEBSITE_URL}/login`;
      }, 4000);
    } else if (
      error.response &&
      (error.response.status === httpStatus.BAD_REQUEST ||
        error.response.status === httpStatus.TOO_MANY_REQUESTS)
    ) {
      publicContext.SnackBarControl.handleNewSnackBar(
        error.response.data.message,
        'warning'
      );
    } else {
      publicContext.SnackBarControl.handleNewSnackBar(
        'خطا در برقراری ارتباط',
        'warning'
      );
    }
  } else {
    publicContext.SnackBarControl.handleNewSnackBar(
      'خطا در برقراری ارتباط',
      'warning'
    );
  }
  return;
}

export default HttpClient;
