import { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import validator from 'validator';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PriceSplitter from '../../utils/PriceSplitter';
import { SITE_NAME } from '../../utils/Constants';
import PublicContext from '../../contexts/PublicContext';
import { TitleSetter } from '../../utils/HeadSetter';
import CustomConfrimDialog from '../../components/CustomConfirmDialog';

let addStockSchma = yup.object().shape({
  optionValue: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .notRequired()
    .max(200, 'نام حداکثر باید 200 حرفی باشد'),

  buyPrice: yup
    .number()
    .typeError('قیمت خرید باید عدد باشد')
    .test('len', 'قیمت خرید باید از 0 بیشتر باشد', (val) => {
      if (val > 0) return true;
      else return false;
    }),
  stock: yup
    .number()
    .typeError('موجودی باید عدد باشد')
    .test('len', 'موجودی باید 0 یا بیشتر از 0 باشد', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
  offPrice: yup
    .number()
    .typeError('قیمت تخفیف باید عدد باشد')
    .test('len', 'قیمت تخفیف باید 0 یا بیشتر از 0  باشد', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
  finalPrice: yup
    .number()
    .typeError('قیمت نهایی باید عدد باشد')
    .test('len', 'قیمت نهایی باید از 0 بیشتر باشد', (val) => {
      if (val > 0) return true;
      else return false;
    }),
  interestPrice: yup
    .number()
    .typeError('قیمت سود باید عدد باشد')
    .test('len', 'قیمت سود باید از -1 بیشتر باشد', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
  availableStock: yup
    .boolean()
    .typeError('در وارد کردن حداقل موجودی هشدار دقت نمایید'),
  secondHand: yup.boolean().typeError('در وارد کردن دست دوم بودن دقت نمایید'),
});

const EditStock = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [stockId, setStockId] = useState(params['stockId']);
  const [product, setProduct] = useState({});
  const [stocks, setStocks] = useState([]);
  const [editableStock, setEditableStock] = useState({});
  const [productDetails, setProductDetails] = useState('');
  const [errorMsg, setErrorMsg] = useState([]);
  const [optionValue, setOptionValue] = useState('');
  const [offPrice, setOffPrice] = useState(0);
  const [buyPrice, setBuyPrice] = useState(0);
  const [interestPrice, setInterestPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [stockCount, setStockCount] = useState(0);
  const [alertMinStock, setAlertMinStock] = useState(0);
  const [availableStock, setAvailableStock] = useState(true);
  const [secondHand, setSecondHand] = useState(false);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);

  const handleClearErrors = () => setErrorMsg([]);

  const GetStockDetails = () => {
    setLoading(true);

    HttpClient.post('/admin/products/get-one-stock', {
      stockId: stockId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setEditableStock(httpRes.data.data);
          setOptionValue(httpRes.data.data.OptionValue);
          setAvailableStock(Boolean(httpRes.data.data.Available));
          setSecondHand(Boolean(httpRes.data.data.SecondHand));
          setBuyPrice(Number(httpRes.data.data.BuyPrice));
          setInterestPrice(Number(httpRes.data.data.InterestPrice));
          setFinalPrice(Number(httpRes.data.data.FinalPrice));
          setOffPrice(Number(httpRes.data.data.OffPrice));
          setStockCount(Number(httpRes.data.data.Stock));
          setAlertMinStock(Number(httpRes.data.data.AlertMinimumStock));
          HttpClient.post('/admin/products/get-one', {
            id: httpRes.data.data.ProductId,
          })
            .then((httpRes) => {
              if (httpRes.status === httpStatus.OK) {
                setProduct(httpRes.data.data.productDetails);
                setStocks(httpRes.data.data.productStocks);
                setProductDetails(httpRes.data.data.productDetails);
                setLoading(false);
              }
            })
            .catch((error) => {
              HandleHttpError(error, publicContext);
              router('/admin/products');
            });
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        router('/admin/products');
      });
  };

  useEffect(() => {
    GetStockDetails();
  }, []);

  const handleEditStock = () => {
    setLoading(true);
    addStockSchma
      .validate(
        {
          optionValue: optionValue.trim(),
          buyPrice: buyPrice,
          stock: stockCount,
          offPrice: offPrice,
          finalPrice: finalPrice,
          interestPrice: interestPrice,
          availableStock: availableStock,
          secondHand: secondHand,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();

        HttpClient.post('/admin/products/edit-stock', {
          stockId: stockId,
          stock: stockCount,
          secondHand: secondHand,
          optionValue: optionValue.trim(),
          buyPrice: buyPrice,
          interestPrice: interestPrice,
          offPrice: offPrice,
          finalPrice: finalPrice,
          alertMinStock: alertMinStock,
          availableStock: availableStock,
        })
          .then((httpRes) => {
            if (httpRes.status === httpStatus.OK) {
              publicContext.SnackBarControl.handleNewSnackBar(
                'تغییرات ثبت شد',
                'success'
              );
            }
          })
          .catch((error) => {
            HandleHttpError(error, publicContext);
          });
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }
        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };

  const handleDeleteStock = () => {
    setLoading(true);
    setOpenConfrimDialog(false);

    HttpClient.post('/admin/products/remove-stock', {
      stockId: stockId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'گزینه نظر حذف شد',
            'success'
          );
          router(`/admin/products/manage-stock/${product.ProductId}`, {
            replace: true,
          });
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AdminLayout>
      {TitleSetter('ویرایش گزینه | ' + SITE_NAME)}
      <Grid container>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router(`/admin/products/manage-stock/${product.ProductId}`);
            }}
            size="small"
          >
            موجودی ها
          </Button>
          <Divider sx={{ marginY: 1 }} />
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={700} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              spacing={2.5}
              sx={{
                pt: 1.5,
              }}
            >
              <Grid item xs={12}>
                <Typography variant="body2" component="h6" textAlign="center">
                  {product.Name} (
                  {product.OptionName === 'default'
                    ? 'گزینه پیش فرض'
                    : `${product.OptionName} : 
                      ${
                        editableStock.OptionValue === 'default'
                          ? 'پیش فرض'
                          : editableStock.OptionValue
                      }
                    `}
                  )
                </Typography>
              </Grid>
              {errorMsg.length > 0 && (
                <Grid item xs={12}>
                  <Box sx={{ paddingY: 1 }}>
                    <Alert severity="warning">
                      <AlertTitle>
                        پیغام اعتبار سنجی
                        <IconButton color="inherit" onClick={handleClearErrors}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </AlertTitle>
                      {errorMsg.map((msg, index) => (
                        <div key={index}> - {msg}</div>
                      ))}
                    </Alert>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setAvailableStock(e.target.checked);
                      }}
                      checked={availableStock}
                    />
                  }
                  label="قابلیت نمایش و خرید"
                />
              </Grid>
              {product.OptionName !== 'default' && (
                <Grid item xs={12}>
                  <TextField
                    label="نام گزینه کالا"
                    fullWidth={true}
                    value={optionValue}
                    onChange={(e) => {
                      setOptionValue(e.target.value);
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  label="موجودی فعلی"
                  fullWidth={true}
                  value={stockCount}
                  variant="outlined"
                  onChange={(e) => {
                    if (validator.isInt(e.target.value, { gt: -1 })) {
                      setStockCount(Number(e.target.value));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="قیمت خرید"
                  fullWidth={true}
                  value={buyPrice}
                  onChange={(e) => {
                    if (
                      validator.isNumeric(e.target.value) &&
                      validator.isInt(e.target.value, { gt: 0 })
                    ) {
                      setBuyPrice(Number(e.target.value));

                      setFinalPrice(
                        Number(e.target.value) +
                          Number(interestPrice) -
                          Number(offPrice) <
                          0
                          ? 0
                          : Number(e.target.value) +
                              Number(interestPrice) -
                              Number(offPrice)
                      );
                    }
                  }}
                  helperText={`${PriceSplitter(buyPrice)} تومان`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="قیمت سود"
                  fullWidth={true}
                  value={interestPrice}
                  onChange={(e) => {
                    if (validator.isNumeric(e.target.value)) {
                      setInterestPrice(Number(e.target.value));
                      setFinalPrice(
                        Number(buyPrice) +
                          Number(e.target.value) -
                          Number(offPrice) <
                          0
                          ? 0
                          : Number(buyPrice) +
                              Number(e.target.value) -
                              Number(offPrice)
                      );
                    }
                  }}
                  helperText={`${PriceSplitter(interestPrice)} تومان`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="تخفیف"
                  fullWidth={true}
                  value={offPrice}
                  onChange={(e) => {
                    if (
                      validator.isInt(e.target.value, { gt: -1 }) &&
                      Number(buyPrice) + Number(interestPrice) >=
                        Number(e.target.value)
                    ) {
                      setOffPrice(Number(e.target.value));

                      setFinalPrice(
                        Number(buyPrice) +
                          Number(interestPrice) -
                          Number(e.target.value) <
                          0
                          ? 0
                          : Number(buyPrice) +
                              Number(interestPrice) -
                              Number(e.target.value)
                      );
                    }
                  }}
                  helperText={`${PriceSplitter(offPrice)} تومان - (${Math.ceil(
                    (100 * Number(offPrice)) /
                      (Number(buyPrice) + Number(interestPrice))
                  )} %)`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="قیمت نهایی"
                  fullWidth={true}
                  value={`${PriceSplitter(Number(finalPrice))} تومان`}
                  variant="filled"
                  disabled={true}
                  onChange={(e) => {
                    if (validator.isInt(e.target.value, { gt: 0 })) {
                      setFinalPrice(e.target.value);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="قیمت نهایی بدون تخفیف"
                  fullWidth={true}
                  value={`${PriceSplitter(
                    Number(finalPrice) + Number(offPrice)
                  )} تومان`}
                  variant="filled"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="حداقل موجودی برای هشدار"
                  fullWidth={true}
                  value={alertMinStock}
                  onChange={(e) => {
                    if (validator.isNumeric(e.target.value))
                      setAlertMinStock(Number(e.target.value));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setSecondHand(e.target.checked);
                      }}
                      checked={secondHand}
                    />
                  }
                  label="دست دوم"
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ marginY: 1 }} />
                <LoadingButton
                  variant="contained"
                  color="success"
                  startIcon={<SaveIcon />}
                  onClick={handleEditStock}
                >
                  ذخیره
                </LoadingButton>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: 'end',
                }}
              >
                <LoadingButton
                  sx={{ marginX: 1 }}
                  variant="contained"
                  color="info"
                  startIcon={<DisplaySettingsIcon />}
                  onClick={() => {
                    router(`/admin/products/edit-stock-features/${stockId}`);
                  }}
                >
                  ویژگی ها
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setOpenConfrimDialog(true);
                  }}
                >
                  حذف گزینه
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <CustomConfrimDialog
        text={'آیا از حذف گزینه مطمعن هستید؟'}
        open={openConfrimDialog}
        confrimButtonText="بـله"
        declineButtonText="خـیر"
        declineButtonVariant="contained"
        confrimButtonVariant="default"
        loadingDeclineButton={loading}
        loadingConfrimButton={loading}
        handleConfirm={handleDeleteStock}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
    </AdminLayout>
  );
};

export default EditStock;
