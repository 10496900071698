import provincesJson from './../assets/provinces.json';
import citiesJson from './../assets/cities.json';
function provinceIdToFaName(id) {
  return provincesJson.find((province) => province.id === Number(id)).name;
}

function cityIdToFaName(id) {
  return citiesJson.find((city) => city.id === Number(id)).name;
}

export { provinceIdToFaName, cityIdToFaName };
