import React, { useEffect, useState, useContext, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import DashboardIcon from '@mui/icons-material/Dashboard';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';

const Dashboard = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [loading, setLoading] = useState(true);
  const [newOrders, setNewOrders] = useState([]);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    HttpClient.post('/admin/notification/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setNotifications(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        setLoading(false);
      });
  }, []);

  const handleClickOnNotification = (url, id) => {
    setLoading(true);

    HttpClient.post('/admin/notification/remove', {
      id: id,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          router(url);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <AdminLayout>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {TitleSetter('داشبورد | ' + SITE_NAME)}
          <Box sx={{ textAlign: 'center' }}>
            <Button startIcon={<DashboardIcon />}>داشبورد</Button>
            <Divider sx={{ marginY: 1 }} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ maxHeight: 700, overflowY: 'auto', p: 1 }}>
            <Box sx={{ p: 1 }}>
              <Button startIcon={<NotificationsActiveIcon />}>
                اعلان های جدید
              </Button>
              <Divider sx={{ marginY: 0.5 }} />
            </Box>
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
              }}
            >
              {notifications.length === 0 && (
                <ListItemButton>
                  <ListItemText
                    sx={{ textAlign: 'center', color: 'GrayText' }}
                    primary={<>اعلانی وجود ندارد</>}
                  />
                </ListItemButton>
              )}
              {notifications.map((notification, index) => {
                return (
                  <Fragment key={index}>
                    <ListItemButton
                      key={index}
                      onClick={() => {
                        handleClickOnNotification(
                          notification.Url,
                          notification.NotificationId
                        );
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            <Chip label={index + 1} size="small" />{' '}
                            {notification.Title}
                          </>
                        }
                      />
                    </ListItemButton>
                    <Divider />
                  </Fragment>
                );
              })}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default Dashboard;
