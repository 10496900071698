import { useEffect, useState, useContext, createRef } from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import AddProductImageList from '../../components/admin/AddProductImageList';
import CategoriesTreeView from '../../components/admin/CategoriesTreeView';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';

let schema = yup.object().shape({
  productName: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .required('نام الزامی است')
    .min(3, 'نام حداقل باید 3 حرفی باشد')
    .max(200, 'نام حداکثر باید 200 حرفی باشد'),
  productDescription: yup
    .string()
    .typeError('توضیحات را درست وارد کنید')
    .required('توضیحات الزامی است')
    .min(3, 'توضیحات حداقل باید 3 حرفی باشد')
    .max(10000, 'توضیحات حداکثر باید 10000 حرفی باشد'),
  optionId: yup
    .number()
    .typeError('گزینه انتخاب باید عدد باشد')
    .test('len', 'گزینه انتخاب را لحاظ نمایید', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
  categories: yup
    .array()
    .test('len', 'حداقل یک دسته را برای کالا انتخاب کنید', (val) => {
      if (val && val.length > 0) return true;
      return false;
    }),
  imageList: yup
    .array()
    .test('len', 'حداقل یک تصویر را برای کالا انتخاب کنید', (val) => {
      if (val && val.length > 0) return true;
      return false;
    }),
});

const AddProduct = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);

  const imageFileRef = createRef();

  const handleChangeOption = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleClearErrors = () => setErrorMsg([]);

  const handleAddProduct = (e) => {
    setWaiting(true);
    let formData = new FormData();
    imageList.forEach((img) => {
      formData.append('images', img.data);
    });
    formData.append('productName', productName);
    formData.append('optionId', selectedOption);
    formData.append('categories', [...selectedCategoryList]);
    formData.append('productDescription', productDescription);

    HttpClient.post('/admin/products/add', formData)
      .then((httpRes) => {
        if (httpRes.status === httpStatus.CREATED) {
          publicContext.SnackBarControl.handleNewSnackBar(
            `[ ${productName} ] ثبت شد`,
            'success'
          );
          router('/admin/products');
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally((re) => {
        setWaiting(false);
      });
  };

  const handleFileChange = (e) => {
    const img = {
      imageUrl: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      fileName: e.target.files[0].name,
    };
    if (img.data.size > 1048576) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر تصویر 1048576 بایت (1 مگابایت)',
        'error'
      );
      return;
    }
    let _imgList = [...imageList];
    _imgList.push(img);
    setImageList(_imgList);
  };

  const handleDeleteImage = (img) => {
    let arrayOfObjects = [...imageList];
    let findedIndex = imageList.indexOf(img);
    if (findedIndex !== -1) {
      arrayOfObjects.splice(findedIndex, 1);
    }
    setImageList([...arrayOfObjects]);
  };

  const Input = styled('input')({
    display: 'none',
  });

  useEffect(() => {
    HttpClient.post('/admin/category/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setCategoryList(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });

    HttpClient.post('/admin/product-options/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setOptionList(httpRes.data.data);
          httpRes.data.data.map((option) => {
            if (option.Name === 'default') setSelectedOption(option.OptionId);
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  }, []);

  const handleChangeCheckbox = (CategoryId) => {
    let categoryId = Number(CategoryId);
    let _selectedCategoryList = [...selectedCategoryList];
    let findedIndex = _selectedCategoryList.indexOf(categoryId);

    if (findedIndex !== -1) {
      _selectedCategoryList.splice(findedIndex, 1);
    } else {
      _selectedCategoryList.push(categoryId);
    }
    setSelectedCategoryList([..._selectedCategoryList]);
  };

  const handleClickSave = () => {
    setLoading(true);
    schema
      .validate(
        {
          productName: productName,
          optionId: selectedOption,
          categories: selectedCategoryList,
          productDescription: productDescription,
          imageList: imageList,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        setOpenConfrimDialog(true);
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }

        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };
  return (
    <AdminLayout>
      {TitleSetter('افزودن کالا | ' + SITE_NAME)}

      <Grid container spacing={2} pb={10}>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router('/admin/products');
            }}
            size="small"
          >
            کالاها
          </Button>

          <Divider sx={{ marginY: 1 }} />

          <LoadingButton
            loading={loading || waiting}
            color="success"
            variant="contained"
            size="large"
            sx={{
              position: 'fixed',
              bottom: 10,
              left: 10,
              paddingY: 1,
              paddingX: 5,
              zIndex: (theme) => theme.zIndex.fab + 1,
            }}
            startIcon={<SaveIcon />}
            onClick={handleClickSave}
          >
            ذخیره
          </LoadingButton>
        </Grid>
        {errorMsg.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ paddingY: 1 }}>
              <Alert severity="warning">
                <AlertTitle>
                  پیغام اعتبار سنجی
                  <IconButton color="inherit" onClick={handleClearErrors}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </AlertTitle>
                {errorMsg.map((msg, index) => (
                  <div key={index}> - {msg}</div>
                ))}
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label="نام کالا"
                value={productName}
                onChange={(e) => {
                  setProductName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={5}
                maxRows={10}
                variant="filled"
                label="توضیحات"
                value={productDescription}
                onChange={(e) => {
                  setProductDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="option-label">گزینه انتخاب</InputLabel>
                <Select
                  labelId="option-label"
                  id="optionId"
                  value={selectedOption}
                  label="گزینه انتخاب"
                  onChange={handleChangeOption}
                >
                  {optionList.map((opt, index) => (
                    <MenuItem key={index} value={Number(opt.OptionId)}>
                      {String(opt.Name) === 'default' ? 'پیش فرض' : opt.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">انتخاب دسته بندی</Typography>
              <Divider sx={{ marginY: 0.5 }} />
              <Paper sx={{ p: 2, marginY: 1.5 }}>
                <CategoriesTreeView
                  list={categoryList}
                  selectedItems={selectedCategoryList}
                  handleChangeCheckbox={handleChangeCheckbox}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Input
                accept="image/*"
                id="images"
                type="file"
                onChange={handleFileChange}
                ref={imageFileRef}
              />
              <Button
                startIcon={<PhotoCamera />}
                aria-label="upload picture"
                variant="contained"
                onClick={() => {
                  imageFileRef.current.click();
                }}
              >
                انتخاب تصویر
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, marginY: 1.5 }}>
                <AddProductImageList
                  imageList={imageList}
                  handleDeleteImage={handleDeleteImage}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        loadingConfrimButton={waiting}
        loadingDeclineButton={waiting}
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        handleConfirm={handleAddProduct}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AdminLayout>
  );
};

export default AddProduct;
