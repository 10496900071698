import Helmet from 'react-helmet';

export function TitleSetter(titleText) {
  return (
    <Helmet>
      <title>{titleText}</title>
    </Helmet>
  );
}
export function MetaDescriptionSetter(Text) {
  return (
    <Helmet>
      <meta name="description" content={Text} />
    </Helmet>
  );
}
