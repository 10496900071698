import ImageList from '@mui/material/ImageList';
import Chip from '@mui/material/Chip';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export default function AddProductImageList({ imageList, handleDeleteImage }) {
  return (
    <ImageList sx={{ width: '100%', height: 300 }}>
      <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div">
          {imageList.length === 0 ? (
            <>حداقل یک تصویر را انتخاب کنید</>
          ) : (
            <>تصاویر</>
          )}
        </ListSubheader>
      </ImageListItem>
      {imageList.map((item, index) => (
        <ImageListItem key={index}>
          <img
            src={item.imageUrl}
            srcSet={item.imageUrl}
            alt={item.fileName}
            loading="lazy"
          />
          <ImageListItemBar
            title={
              index === 0 && (
                <Chip
                  label={'اصلی'}
                  component="span"
                  variant="filled"
                  color="success"
                  sx={{ marginY: 1 }}
                />
              )
            }
            subtitle={item.fileName}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
                onClick={() => {
                  handleDeleteImage(item);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
