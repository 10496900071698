import { useEffect, useState, useContext, Fragment, createRef } from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material';

import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';

let schema = yup.object().shape({
  categoryName: yup
    .string()
    .typeError('نام را درست وارد کنید')
    .required('نام الزامی است')
    .min(3, 'نام حداقل باید 3 حرفی باشد')
    .max(200, 'نام حداکثر باید 200 حرفی باشد'),

  parentId: yup
    .number()
    .typeError('کد سردسته باید عدد باشد')
    .test('len', 'کد سردسته را لحاظ نمایید', (val) => {
      if (val >= 0) return true;
      else return false;
    }),
});

const AddCategory = ({
  loading,
  setLoading,
  handleCloseFormDialog,
  getCategories,
  parentId = 0,
}) => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [categoryName, setCategoryName] = useState('');
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [imageFileName, setImageFileName] = useState();
  const [errorMsg, setErrorMsg] = useState([]);

  const handleClearErrors = () => setErrorMsg([]);

  const mainImageFileRef = createRef();

  const handleMainImageFileChange = (e) => {
    if (e.target.files[0].size > 1048576) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر تصویر 1048576 بایت (1 مگابایت)',
        'error'
      );
      return;
    }
    setImageFileName(URL.createObjectURL(e.target.files[0]));
    setImageFile(e.target.files[0]);
  };

  const handleAddCategory = () => {
    setLoading(true);
    let formData = new FormData();

    formData.append('image', imageFile);
    formData.append('parentId', parentId);
    formData.append('categoryName', categoryName);

    HttpClient.post('/admin/category/add', formData)
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          handleCloseFormDialog();
          getCategories(parentId);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setOpenConfrimDialog(false);
        setLoading(false);
      });
  };

  useEffect(() => {}, []);

  const handleClickSave = () => {
    if (!imageFile) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'تصویری را انتخاب کنید',
        'error'
      );
      return;
    }
    setLoading(true);
    schema
      .validate(
        {
          categoryName: categoryName.trim(),
          parentId: parentId,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        setOpenConfrimDialog(true);
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }
        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };
  const Input = styled('input')({
    display: 'none',
  });
  return (
    <>
      <Grid container spacing={2}>
        {errorMsg.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ paddingY: 1 }}>
              <Alert severity="warning">
                <AlertTitle>
                  پیغام اعتبار سنجی
                  <IconButton color="inherit" onClick={handleClearErrors}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </AlertTitle>
                {errorMsg.map((msg, index) => (
                  <div key={index}> - {msg}</div>
                ))}
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label="نام دسته"
                value={categoryName}
                onChange={(e) => {
                  setCategoryName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                accept="image/*"
                id="images"
                type="file"
                onChange={handleMainImageFileChange}
                ref={mainImageFileRef}
              />
              <Button
                startIcon={<PhotoCamera />}
                aria-label="upload picture"
                variant="contained"
                onClick={() => {
                  mainImageFileRef.current.click();
                }}
              >
                انتخاب تصویر
              </Button>
              <Divider sx={{ marginTop: 1 }} />
              <img
                style={{ width: '100%', marginTop: 10, marginBottom: 20 }}
                src={imageFileName}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                color="success"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleClickSave}
              >
                ذخیره
              </LoadingButton>
              <LoadingButton
                loading={loading}
                size="large"
                onClick={() => {
                  setCategoryName('');
                  handleCloseFormDialog();
                }}
              >
                بستن
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        loadingDeclineButton={loading}
        loadingConfrimButton={loading}
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        handleConfirm={handleAddCategory}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AddCategory;
