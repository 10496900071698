import { useEffect, useState, useContext, Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME, API_URL } from '../../utils/Constants';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import CustomAddFormDialog from '../../components/CustomFormDialog';
import CustomEditFormDialog from '../../components/CustomFormDialog';
import EditFeatureDefinition from './EditFeatureDefinition';
import AddFeatureDefinition from './AddFeatureDefinition';

const ProductFeatureDefinitions = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [loading, setLoading] = useState(true);
  const [features, setFeatures] = useState([]);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [featuresNameSearch, setFeaturesNameSearch] = useState('');
  const [willDeleteFeatureId, setWillDeleteFeatureId] = useState(0);
  const [willEditFeatureId, setWillEditFeatureId] = useState(0);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [openEditFormDialog, setOpenEditFormDialog] = useState(false);
  const [openAddFormDialog, setOpenAddFormDialog] = useState(false);

  const handleCloseAddFormDialog = () => {
    setOpenAddFormDialog(false);
  };

  const handleCloseEditFormDialog = () => {
    setOpenEditFormDialog(false);
  };

  const handleFeaturesNameSearch = (e) => {
    setFeaturesNameSearch(e.target.value);
    setFilteredFeatures([
      ...features.filter((feature) =>
        feature.Name.includes(e.target.value.trim())
      ),
    ]);
  };
  const GetList = (e) => {
    setLoading(true);

    HttpClient.post('/admin/product-feature-definitions/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setFeatures(httpRes.data.data);
          setFilteredFeatures([...httpRes.data.data]);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  };

  const handleDeleteFeature = () => {
    setOpenConfrimDialog(false);
    setLoading(true);
    HttpClient.post('/admin/product-feature-definitions/remove', {
      id: willDeleteFeatureId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          GetList();

          publicContext.SnackBarControl.handleNewSnackBar(
            'ویژگی مد نظر حذف شد',
            'success'
          );
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetList();
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('ویژگی ها | ' + SITE_NAME)}
      <Box sx={{ textAlign: 'center' }}>
        <Button startIcon={<DisplaySettingsIcon />}>ویژگی ها</Button>
        <Divider sx={{ marginY: 1 }} />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <Paper sx={{ p: 1 }} elevation={5}>
          <TextField
            sx={{ marginY: 2 }}
            fullWidth={true}
            value={featuresNameSearch}
            onChange={handleFeaturesNameSearch}
            variant="filled"
            label="جستجو"
            autoFocus={true}
          />
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              setOpenAddFormDialog(true);
            }}
          >
            افزودن
          </Button>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {filteredFeatures.map((feature, index) => (
              <Fragment key={index}>
                <ListItemButton alignItems="flex-start">
                  <ListItemText primary={feature.Name} />
                  <IconButton
                    color="primary"
                    sx={{ justifySelf: 'end' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setWillEditFeatureId(Number(feature.FeatureId));
                      setOpenEditFormDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    sx={{ justifySelf: 'end' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setWillDeleteFeatureId(Number(feature.FeatureId));
                      setOpenConfrimDialog(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemButton>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Paper>
      )}
      <CustomAddFormDialog
        dialogTitle={'افزودن ویژگی'}
        handleClose={handleCloseAddFormDialog}
        open={openAddFormDialog}
      >
        <AddFeatureDefinition
          setLoading={setLoading}
          loading={loading}
          handleCloseFormDialog={handleCloseAddFormDialog}
          getList={GetList}
        />
      </CustomAddFormDialog>

      <CustomEditFormDialog
        dialogTitle={'ویرایش ویژگی'}
        handleClose={handleCloseEditFormDialog}
        open={openEditFormDialog}
      >
        <EditFeatureDefinition
          getList={GetList}
          featureId={willEditFeatureId}
          handleCloseFormDialog={handleCloseEditFormDialog}
          loading={loading}
          setLoading={setLoading}
        />
      </CustomEditFormDialog>
      <CustomConfirmDialog
        text={'آیا از حذف ویژگی مطمعن هستید؟'}
        open={openConfrimDialog}
        confrimButtonText="بـله"
        declineButtonText="خـیر"
        declineButtonVariant="contained"
        confrimButtonVariant="default"
        loadingConfrimButton={loading}
        handleConfirm={handleDeleteFeature}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
    </AdminLayout>
  );
};

export default ProductFeatureDefinitions;
