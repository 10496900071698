import { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';
import PriceSplitter from '../../utils/PriceSplitter';

let schema = yup.object().shape({
  MAX_COUNT_PRODUCT_IN_BASKET: yup
    .string()
    .test(
      'len',
      'حداقل مقدار فیلد حداثر تعداد کالا در سبد، 0 و حداکثر 1000',
      (value) => {
        if (validator.isInt(value, { min: -1, max: 1000, max: 1000 }))
          return true;
        else return false;
      }
    ),
  MIN_FREE_DELIVER: yup
    .string()
    .test(
      'len',
      'حداقل مقدار فیلد حداقل میزان خرید برای ارسال رایگان، 0 است',
      (value) => {
        if (validator.isInt(value, { min: -1 })) return true;
        else return false;
      }
    ),
  INSTAGRAM_LINK: yup
    .string()
    .test('len', 'شناسه اینستاگرام حداکثر 400 کارکتر', (value) => {
      if (validator.isLength(value, { min: -1, max: 400 })) return true;
      else return false;
    }),
  TELEGRAM_LINK: yup
    .string()
    .test('len', 'شماره تلگرام حداکثر 400 کارکتر', (value) => {
      if (validator.isLength(value, { min: -1, max: 400 })) return true;
      else return false;
    }),
  PHONE_LINK: yup
    .string()
    .test('len', 'شماره راه ارتباطی حداکثر 400 کارکتر', (value) => {
      if (validator.isLength(value, { min: -1, max: 400 })) return true;
      else return false;
    }),
  ABOUT_DESC: yup
    .string()
    .test('len', 'متن درباره ما حداکثر 5000 کارکتر', (value) => {
      if (validator.isLength(value, { min: -1, max: 5000 })) return true;
      else return false;
    }),
  WHATSAPP_LINK: yup
    .string()
    .test('len', 'شماره واتساپ حداکثر 400 کارکتر', (value) => {
      if (validator.isLength(value, { min: -1, max: 400 })) return true;
      else return false;
    }),
  DELIVERY_PRICE: yup
    .string()
    .test('len', 'حداقل هزینه ارسال 0 است', (value) => {
      if (validator.isInt(value, { min: -1 })) return true;
      else return false;
    }),
  PHONE_FOR_ZARINPAL: yup
    .string()
    .test('len', 'در وارد کردن شماره تماس دقت نمایید', (value) => {
      if (
        !value ||
        value.trim().length !== 11 ||
        value.trim()[0] + value.trim()[1] !== '09'
      ) {
        return false;
      } else return true;
    }),
  EMAIL_FOR_ZARINPAL: yup
    .string()
    .test('len', 'در وارد کردن ایمیل دقت نمایید', (value) => {
      if (validator.isEmail(value.trim())) return true;
      else return false;
    }),
  DESC_FOR_ZARINPAL: yup
    .string()
    .test(
      'len',
      'توضیحات درگاه پرداخت باید حداقل 3 و حداکثر 50 حرف باشد',
      (value) => {
        if (validator.isLength(value.trim(), { min: 3, max: 50 })) return true;
      }
    ),
});

const EditSetting = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [loading, setLoading] = useState(true);
  const [MAX_COUNT_PRODUCT_IN_BASKET, setMAX_COUNT_PRODUCT_IN_BASKET] =
    useState('');
  const [ABOUT_DESC, setABOUT_DESC] = useState('');
  const [TELEGRAM_LINK, setTELEGRAM_LINK] = useState('');
  const [PHONE_LINK, setPHONE_LINK] = useState('');
  const [WHATSAPP_LINK, setWHATSAPP_LINK] = useState('');
  const [INSTAGRAM_LINK, setINSTAGRAM_LINK] = useState('');
  const [MIN_FREE_DELIVER, setMIN_FREE_DELIVER] = useState('');
  const [DELIVERY_PRICE, setDELIVERY_PRICE] = useState('');
  const [PHONE_FOR_ZARINPAL, setPHONE_FOR_ZARINPAL] = useState('');
  const [EMAIL_FOR_ZARINPAL, setEMAIL_FOR_ZARINPAL] = useState('');
  const [DESC_FOR_ZARINPAL, setDESC_FOR_ZARINPAL] = useState('');

  const [errorMsg, setErrorMsg] = useState([]);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);

  const handleClearErrors = () => setErrorMsg([]);

  const handleEdit = () => {
    setLoading(true);
    setOpenConfrimDialog(false);
    HttpClient.post('/admin/setting/edit', {
      MAX_COUNT_PRODUCT_IN_BASKET: MAX_COUNT_PRODUCT_IN_BASKET,
      MIN_FREE_DELIVER: MIN_FREE_DELIVER,
      DELIVERY_PRICE: DELIVERY_PRICE,
      PHONE_FOR_ZARINPAL: PHONE_FOR_ZARINPAL,
      EMAIL_FOR_ZARINPAL: EMAIL_FOR_ZARINPAL,
      DESC_FOR_ZARINPAL: DESC_FOR_ZARINPAL,
      TELEGRAM_LINK: TELEGRAM_LINK,
      PHONE_LINK: PHONE_LINK,
      WHATSAPP_LINK: WHATSAPP_LINK,
      INSTAGRAM_LINK: INSTAGRAM_LINK,
      ABOUT_DESC: ABOUT_DESC,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'تغییرات ذخیره شد',
            'success'
          );
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickSave = () => {
    setLoading(true);
    schema
      .validate(
        {
          MAX_COUNT_PRODUCT_IN_BASKET: MAX_COUNT_PRODUCT_IN_BASKET,
          MIN_FREE_DELIVER: MIN_FREE_DELIVER,
          DELIVERY_PRICE: DELIVERY_PRICE,
          PHONE_FOR_ZARINPAL: PHONE_FOR_ZARINPAL,
          EMAIL_FOR_ZARINPAL: EMAIL_FOR_ZARINPAL,
          DESC_FOR_ZARINPAL: DESC_FOR_ZARINPAL,
          TELEGRAM_LINK: TELEGRAM_LINK,
          PHONE_LINK: PHONE_LINK,
          WHATSAPP_LINK: WHATSAPP_LINK,
          INSTAGRAM_LINK: INSTAGRAM_LINK,
          ABOUT_DESC: ABOUT_DESC,
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        setOpenConfrimDialog(true);
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }

        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    HttpClient.post('/admin/setting/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setMAX_COUNT_PRODUCT_IN_BASKET(
            httpRes.data.data.MAX_COUNT_PRODUCT_IN_BASKET
          );
          setMIN_FREE_DELIVER(httpRes.data.data.MIN_FREE_DELIVER);
          setDELIVERY_PRICE(httpRes.data.data.DELIVERY_PRICE);
          setPHONE_FOR_ZARINPAL(httpRes.data.data.PHONE_FOR_ZARINPAL);
          setEMAIL_FOR_ZARINPAL(httpRes.data.data.EMAIL_FOR_ZARINPAL);
          setDESC_FOR_ZARINPAL(httpRes.data.data.DESC_FOR_ZARINPAL);
          setTELEGRAM_LINK(httpRes.data.data.TELEGRAM_LINK);
          setPHONE_LINK(httpRes.data.data.PHONE_LINK);
          setWHATSAPP_LINK(httpRes.data.data.WHATSAPP_LINK);
          setINSTAGRAM_LINK(httpRes.data.data.INSTAGRAM_LINK);
          setABOUT_DESC(httpRes.data.data.ABOUT_DESC);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('تنظیمات | ' + SITE_NAME)}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <Button startIcon={<SettingsIcon />}>تنظیمات</Button>
            <Divider sx={{ marginY: 1 }} />
          </Box>
        </Grid>
        {errorMsg.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ paddingY: 1 }}>
              <Alert severity="warning">
                <AlertTitle>
                  پیغام اعتبار سنجی
                  <IconButton color="inherit" onClick={handleClearErrors}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </AlertTitle>
                {errorMsg.map((msg, index) => (
                  <div key={index}> - {msg}</div>
                ))}
              </Alert>
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={MAX_COUNT_PRODUCT_IN_BASKET}
                onChange={(e) => {
                  if (validator.isInt(e.target.value))
                    setMAX_COUNT_PRODUCT_IN_BASKET(Number(e.target.value));
                }}
                fullWidth={true}
                variant="outlined"
                label="حداکثر تعداد کالا در سبد مشتری"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={MIN_FREE_DELIVER}
                onChange={(e) => {
                  if (validator.isInt(e.target.value))
                    setMIN_FREE_DELIVER(Number(e.target.value));
                }}
                fullWidth={true}
                variant="outlined"
                label="حداقل میزان خرید برای ارسال رایگان (تومان)"
                helperText={`${PriceSplitter(Number(MIN_FREE_DELIVER))} تومان`}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={DELIVERY_PRICE}
                onChange={(e) => {
                  if (validator.isInt(e.target.value))
                    setDELIVERY_PRICE(Number(e.target.value));
                }}
                fullWidth={true}
                label="هزینه ارسال (تومان)"
                helperText={`${PriceSplitter(Number(DELIVERY_PRICE))} تومان`}
                variant="outlined"
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={PHONE_FOR_ZARINPAL}
                onChange={(e) => {
                  setPHONE_FOR_ZARINPAL(e.target.value);
                }}
                fullWidth={true}
                label="شماره تماس درگاه پرداخت"
                placeholder="نمونه (09112345678)"
                variant="outlined"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={EMAIL_FOR_ZARINPAL}
                onChange={(e) => {
                  setEMAIL_FOR_ZARINPAL(e.target.value);
                }}
                fullWidth={true}
                label="ایمیل درگاه پرداخت"
                placeholder="نمونه (example@mail.com)"
                variant="outlined"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={DESC_FOR_ZARINPAL}
                onChange={(e) => {
                  setDESC_FOR_ZARINPAL(e.target.value);
                }}
                fullWidth={true}
                label=" توضیحات درگاه پرداخت"
                variant="outlined"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={TELEGRAM_LINK}
                onChange={(e) => {
                  setTELEGRAM_LINK(e.target.value);
                }}
                fullWidth={true}
                variant="outlined"
                label="شماره تلفن تلگرام"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={WHATSAPP_LINK}
                onChange={(e) => {
                  setWHATSAPP_LINK(e.target.value);
                }}
                fullWidth={true}
                variant="outlined"
                label="شماره تلفن واتساپ"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={PHONE_LINK}
                onChange={(e) => {
                  setPHONE_LINK(e.target.value);
                }}
                fullWidth={true}
                variant="outlined"
                label="شماره تلفن راه ارتباطی"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={INSTAGRAM_LINK}
                onChange={(e) => {
                  setINSTAGRAM_LINK(e.target.value);
                }}
                fullWidth={true}
                variant="outlined"
                label="شناسه اینستاگرام"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline={true}
                minRows={10}
                maxRows={20}
                value={ABOUT_DESC}
                onChange={(e) => {
                  setABOUT_DESC(e.target.value);
                }}
                fullWidth={true}
                variant="outlined"
                label="متن درباره ما"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                color="success"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleClickSave}
              >
                ذخیره
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        loadingDeclineButton={loading}
        loadingConfrimButton={loading}
        handleConfirm={handleEdit}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AdminLayout>
  );
};

export default EditSetting;
