import React, { useEffect, useState, useContext, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import validator from 'validator';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import ClearIcon from '@mui/icons-material/Clear';
import ViewListIcon from '@mui/icons-material/ViewList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdapterJalali from '@date-io/date-fns-jalali';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import {
  getOrderStatusColor,
  getOrderStatusText,
} from '../../utils/OrderStatusManager';
import { EnglishToFarsiDateAndTimeWithTimestamp } from '../../utils/DateManager';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';
import OrderStatusJson from './../../assets/orderStatus.json';
import provincesJson from './../../assets/provinces.json';
import citiesJson from './../../assets/cities.json';

const Orders = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  let nowTimestamp = Date.now();

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [ordersLength, setOrdersLength] = useState(0);
  const [fromTimestamp, setFromTimestamp] = useState(nowTimestamp);
  const [toTimestamp, setToTimestamp] = useState(nowTimestamp);
  const [orderId, setOrderId] = useState('');
  const [orderStatus, setOrderStatus] = useState(0);
  const [provinceId, setProvinceId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [ascending, setAscending] = useState(false);
  const [page, setPage] = useState(1);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [disabledCitySelect, setDisabledCitySelect] = useState(true);
  const [availableCities, setAvailableCities] = useState([]);
  const [availableProvinces, setAvailableProvinces] = useState([]);
  const [fromDate, setFromDate] = useState(new Date(nowTimestamp));
  const [toDate, setToDate] = useState(new Date(nowTimestamp));
  const [fromEnabledDate, setFromEnabledDate] = useState(false);
  const [toEnabledDate, setToEnabledDate] = useState(false);

  const getList = ({ limit = 60, offset = 0 }) => {
    if (fromEnabledDate && toEnabledDate) {
      if (Number(fromTimestamp) === Number(toTimestamp)) {
        publicContext.SnackBarControl.handleNewSnackBar(
          'تاریخ ها نباید برابر باشند',
          'warning'
        );
        return;
      }

      if (Number(fromTimestamp) > Number(toTimestamp)) {
        publicContext.SnackBarControl.handleNewSnackBar(
          'تاریخ مبدا نباید بزرگ تر باشد',
          'warning'
        );
        return;
      }
    }

    setLoading(true);
    HttpClient.post('/admin/orders/get-all', {
      fromTimestamp: fromEnabledDate ? fromTimestamp : 0,
      toTimestamp: toEnabledDate ? toTimestamp : 0,
      orderId: validator.isInt(`${orderId}`, { gt: -1 }) ? orderId : 0,
      orderStatus,
      provinceId,
      cityId,
      ascending,
      limit,
      offset,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setOrders([...httpRes.data.data.rows]);
          setOrdersLength(Number(httpRes.data.data.length));
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (event, value) => {
    setPage(Number(value));
    setCurrentOffset((Number(value) - 1) * 60);
    getList({
      limit: 60,
      offset: (Number(value) - 1) * 60,
    });
  };

  const handleClickClearFilter = async () => {
    setLoading(true);

    setDisabledCitySelect(true);
    setFromEnabledDate(false);
    setToEnabledDate(false);

    setFromDate(new Date(Date.now()));
    setToDate(new Date(Date.now()));
    setFromTimestamp(0);
    setToTimestamp(0);
    setOrderId('');
    setOrderStatus(0);
    setProvinceId(0);
    setCityId(0);
    setAscending(false);

    HttpClient.post('/admin/orders/get-all', {
      fromTimestamp: 0,
      toTimestamp: 0,
      orderId: 0,
      orderStatus: 0,
      provinceId: 0,
      cityId: 0,
      ascending: false,
      limit: 60,
      offset: 0,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setOrders([...httpRes.data.data.rows]);
          setOrdersLength(Number(httpRes.data.data.length));
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hanleChangeProvince = (e) => {
    // const filteredCities = [{ id: 0, name: 'انتخاب کنید' }];
    const filteredCities = [];
    if (Number(e.target.value) === 0) {
      setAvailableCities([...filteredCities]);
      setDisabledCitySelect(true);
      setProvinceId(0);
      setCityId(0);

      return;
    }
    citiesJson.forEach((city) => {
      if (city.province_id === Number(e.target.value))
        filteredCities.push(city);
    });
    setAvailableCities([...filteredCities]);
    setDisabledCitySelect(false);
    setProvinceId(Number(e.target.value));
    setCityId(0);
  };

  const handleChangeFromDateValue = (newValue) => {
    setFromDate(newValue);
    setFromTimestamp(newValue.getTime());
  };

  const handleChangeToDateValue = (newValue) => {
    setToDate(newValue);
    setToTimestamp(newValue.getTime());
  };
  const handleClickFilter = (newValue) => {
    getList({ offset: currentOffset });
  };

  useEffect(() => {
    setAvailableProvinces([...provincesJson]);
    setAvailableCities([]);
    // setAvailableCities([{ id: 0, name: 'انتخاب کنید' }]);

    getList({
      limit: 60,
      offset: 0,
    });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterJalali}>
      <AdminLayout>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {TitleSetter('سفارشات | ' + SITE_NAME)}
            <Box sx={{ textAlign: 'center' }}>
              <Button startIcon={<ViewListIcon />}>سفارشات</Button>
              <Divider sx={{ marginY: 1 }} />
            </Box>
          </Grid>
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={600}
              sx={{ borderRadius: 2 }}
            />
          ) : (
            <>
              <Grid item xs={12}>
                <Paper
                  elevation={1}
                  sx={{ maxHeight: 700, overflowY: 'auto', p: 1 }}
                >
                  <Grid container spacing={2.5}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        label="کد سفارش"
                        fullWidth={true}
                        value={orderId}
                        onChange={(e) => {
                          if (validator.isInt(e.target.value, { gt: -1 })) {
                            setOrderId(e.target.value);
                          } else {
                            setOrderId('');
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="order-status-label">وضعیت</InputLabel>
                        <Select
                          label="وضعیت"
                          labelId="order-status-label"
                          id="order-status-label"
                          value={orderStatus}
                          onChange={(e) => {
                            setOrderStatus(Number(e.target.value));
                          }}
                        >
                          <MenuItem value={0}>همه</MenuItem>

                          {OrderStatusJson.map((orderSts, index) => (
                            <MenuItem key={index} value={orderSts.id}>
                              {orderSts.faTitle}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="province-label">استان</InputLabel>
                        <Select
                          label="استان"
                          labelId="province-label"
                          id="province-label"
                          value={provinceId}
                          onChange={hanleChangeProvince}
                        >
                          <MenuItem value={0}>همه</MenuItem>

                          {availableProvinces.map((province, index) => (
                            <MenuItem key={index} value={province.id}>
                              {province.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="city-label">شهرستان</InputLabel>
                        <Select
                          labelId="city-label"
                          id="city"
                          fullWidth
                          value={cityId}
                          onChange={(e) => {
                            setCityId(Number(e.target.value));
                          }}
                          label="شهرستان"
                          disabled={disabledCitySelect}
                        >
                          <MenuItem value={0}>
                            {disabledCitySelect ? 'انتخاب کنید' : 'همه'}
                          </MenuItem>

                          {availableCities.map((city, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={city.id}
                                selected={city.id === 0 ? true : false}
                              >
                                {city.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={fromEnabledDate}
                            onChange={(e) => {
                              setFromEnabledDate(Boolean(e.target.checked));
                            }}
                          />
                        }
                        label="از تاریخ"
                      />
                      {fromEnabledDate === true ? (
                        <DateTimePicker
                          label="از"
                          disableMaskedInput={true}
                          ampm={false}
                          value={fromDate}
                          onChange={handleChangeFromDateValue}
                          renderInput={(props) => (
                            <TextField fullWidth={true} {...props} />
                          )}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={toEnabledDate}
                            onChange={(e) => {
                              setToEnabledDate(Boolean(e.target.checked));
                            }}
                          />
                        }
                        label="تا تاریخ"
                      />
                      {toEnabledDate === true ? (
                        <DateTimePicker
                          label="تا"
                          disableMaskedInput={true}
                          ampm={false}
                          value={toDate}
                          onChange={handleChangeToDateValue}
                          renderInput={(props) => (
                            <TextField fullWidth={true} {...props} />
                          )}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <LoadingButton
                        variant="contained"
                        loading={loading}
                        startIcon={<FilterAltIcon />}
                        sx={{ marginX: 1 }}
                        onClick={handleClickFilter}
                      >
                        فیلتر
                      </LoadingButton>
                      <LoadingButton
                        loading={loading}
                        startIcon={<ClearIcon />}
                        onClick={async () => {
                          await handleClickClearFilter();
                        }}
                      >
                        حذف فیلتر
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{ maxHeight: 700, overflowY: 'auto', p: 1 }}
                >
                  <List
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                    }}
                  >
                    {orders.length === 0 && (
                      <ListItemButton>
                        <ListItemText
                          sx={{ textAlign: 'center', color: 'GrayText' }}
                          primary={<>سفارشی وجود ندارد</>}
                        />
                      </ListItemButton>
                    )}
                    {orders.map((order, index) => {
                      return (
                        <Fragment key={index}>
                          <ListItemButton
                            key={index}
                            onClick={() => {
                              router(`/admin/order/${order.OrderId}`);
                            }}
                          >
                            <ListItemText
                              primary={
                                <>
                                  <Chip
                                    label={`#${order.OrderId}`}
                                    variant="outlined"
                                    sx={{ marginY: 0.25 }}
                                  />{' '}
                                  <Chip
                                    sx={{ marginY: 0.25 }}
                                    label={`تاریخ ثبت : (${EnglishToFarsiDateAndTimeWithTimestamp(
                                      order.CreateTimeStamp
                                    )})`}
                                  />{' '}
                                  <Chip
                                    color={getOrderStatusColor(
                                      order.CurrentStatus
                                    )}
                                    label={`وضعیت : (${getOrderStatusText(
                                      order.CurrentStatus
                                    )})`}
                                    sx={{ marginY: 0.25 }}
                                  />{' '}
                                </>
                              }
                            />
                          </ListItemButton>
                          <Divider />
                        </Fragment>
                      );
                    })}
                  </List>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <Divider sx={{ mb: 1 }} />
                    <Pagination
                      sx={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                      }}
                      count={Math.ceil(ordersLength / 60)}
                      defaultPage={1}
                      page={page}
                      onChange={handleChangePage}
                      siblingCount={1}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </AdminLayout>
    </LocalizationProvider>
  );
};

export default Orders;
