import { createRef } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TreeItem from '@mui/lab/TreeItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function CategoriesTreeView({
  handleChangeCheckbox,
  list = [],
  selectedItems = [],
}) {
  const treeItemRef = createRef();
  const calcTreeview = (parentId) => {
    return list.map(
      (item, index) =>
        Number(item.ParentCategoryId) == Number(parentId) && (
          <CustomTreeViewItem item={item} key={index} />
        )
    );
  };

  const CustomTreeViewItem = ({ item }) => {
    return (
      <TreeItem
        ref={treeItemRef}
        key={item.CategoryId}
        nodeId={item.CategoryId}
        label={
          <FormControlLabel
            onClick={(e) => {
              e.stopPropagation();
            }}
            control={
              <Checkbox
                checked={selectedItems.some(
                  (selecteditem) =>
                    Number(selecteditem) === Number(item.CategoryId)
                )}
                onChange={() =>
                  handleChangeCheckbox(item.CategoryId, item.Name)
                }
              />
            }
            label={item.Name}
          />
        }
      >
        {calcTreeview(item.CategoryId)}
      </TreeItem>
    );
  };

  return (
    <TreeView
      aria-label="انتخاب دسته بندی"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronLeftIcon />}
      sx={{ maxHeight: 400, flexGrow: 1, width: '100%', overflowY: 'auto' }}
    >
      {calcTreeview(0)}
    </TreeView>
  );
}
