import { useEffect, useState, useContext, Fragment } from 'react';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME, API_URL } from '../../utils/Constants';

const Products = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const [loading, setLoading] = useState(true);
  const [producs, setProducts] = useState([]);
  const [filteredProducs, setFilteredProducs] = useState([]);
  const [productNameSearch, setProductNameSearch] = useState('');

  const handleProductNameSearch = (e) => {
    setProductNameSearch(e.target.value);
    setFilteredProducs([
      ...producs.filter((product) =>
        product.Name.includes(e.target.value.trim())
      ),
    ]);
  };
  useEffect(() => {
    HttpClient.post('/admin/products/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setProducts(httpRes.data.data);
          setFilteredProducs([...httpRes.data.data]);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  }, []);
  return (
    <AdminLayout>
      {TitleSetter('کالاها | ' + SITE_NAME)}
      <Box sx={{ textAlign: 'center' }}>
        <Button startIcon={<PlaylistAddCheckRoundedIcon />}>کالاها</Button>
        <Divider sx={{ marginY: 1 }} />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <Paper sx={{ p: 1 }} elevation={5}>
          <TextField
            sx={{ marginY: 2 }}
            fullWidth={true}
            value={productNameSearch}
            onChange={handleProductNameSearch}
            variant="filled"
            label="جستجو"
            autoFocus={true}
          />
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              router('/admin/add-product');
            }}
          >
            افزودن
          </Button>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {filteredProducs.map((product, index) => (
              <Fragment key={index}>
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => {
                    router(`/admin/products/${product.ProductId}`);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={product.ProductName}
                      src={`${API_URL}${product.MainImage}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={product.Name}
                    secondary={
                      <Chip
                        label={`تعداد فروش : ${product.SellCount}`}
                        size="small"
                        component="span"
                      />
                    }
                  />
                </ListItemButton>
                <Divider variant="inset" component="li" />
              </Fragment>
            ))}
          </List>
        </Paper>
      )}
    </AdminLayout>
  );
};

export default Products;
