import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid } from '@mui/material';

export default function CustomConfrimDialog({
  open,
  text,
  handleConfirm,
  handleClose,
  confrimButtonText = 'تایید',
  declineButtonText = 'لغو',
  loadingConfrimButton = false,
  loadingDeclineButton = false,
  confrimButtonColor = 'primary',
  confrimButtonVariant = 'contained',
  declineButtonColor = 'primary',
  declineButtonVariant = 'text',
  textVariant = 'body1',
}) {
  return (
    <div>
      <Dialog
        dir="rtl"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant={textVariant}
            sx={{ fontSize: 14 }}
            component="span"
          >
            {text}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Grid container columnSpacing={4}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                sx={{ borderRadius: 2.5 }}
                onClick={handleClose}
                loading={loadingDeclineButton}
                color={declineButtonColor}
                variant={declineButtonVariant}
              >
                {declineButtonText}
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                sx={{ borderRadius: 2.5 }}
                onClick={handleConfirm}
                autoFocus
                variant={confrimButtonVariant}
                color={confrimButtonColor}
                loading={loadingConfrimButton}
              >
                {confrimButtonText}
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
