import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ViewListIcon from '@mui/icons-material/ViewList';
import MenuIcon from '@mui/icons-material/Menu';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import Dialog from '@mui/material/Dialog';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import BarChartIcon from '@mui/icons-material/BarChart';
import Typography from '@mui/material/Typography';
import StyleIcon from '@mui/icons-material/Style';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

import PublicContext from '../../contexts/PublicContext';
import HttpClient, {
  HandleHttpError,
  RemoveUserAdminTokenFromHeader,
} from '../../utils/HttpClient';
import httpStatus from 'http-status';
// import DeliveryPrice from '../../pages/admin/EditDeliveryPrice';

function listItemCustom(title, icon, handleClick) {
  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
}

export default function AppMenuDrawer() {
  const router = useNavigate();

  const publicContext = React.useContext(PublicContext);

  const [adminfullname, setAdminfullname] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [state, setState] = React.useState({
    left: false,
  });

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleLogout = () => {
    window.localStorage.removeItem('admin-token');
    window.localStorage.removeItem('admin-phone');
    RemoveUserAdminTokenFromHeader();
    router('/', { replace: true });
    setOpenDialog(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    )
      return;

    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    HttpClient.post(`/admin/manage-admins/get-admin-details`)
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setAdminfullname(httpRes.data.data.FullName);
          publicContext.AdminDetails.fullName = httpRes.data.data.FullName;
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        router('/admin', { replace: true });
      });
  }, []);

  return (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={toggleDrawer('left', true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <Box
          sx={{
            width: 250,
          }}
          role="presentation"
        >
          <Divider />
          <List>
            <List>
              <ListItem
                button
                onClick={toggleDrawer('left', false)}
                onKeyDown={toggleDrawer('left', false)}
                sx={{ p: 0.5, m: 0, mb: 1 }}
              >
                <ListItemIcon sx={{ p: 0, m: 0 }}>
                  <ChevronRightIcon /> بستن
                </ListItemIcon>
              </ListItem>
              <Divider />

              <ListItem
                button
                style={{
                  backgroundColor: '#ddd',
                  color: publicContext.ThemeMode.mode === 'dark' && '#000000de',
                }}
                onClick={() => {
                  router('/admin/profile');
                }}
              >
                <ListItemIcon>
                  <AccountCircleIcon
                    sx={{
                      color:
                        publicContext.ThemeMode.mode === 'dark' && '#000000de',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={window.localStorage.getItem('admin-phone')}
                  secondary={
                    <Typography
                      sx={{
                        color:
                          publicContext.ThemeMode.mode === 'dark' &&
                          '#000000de',
                      }}
                      component="span"
                      variant="body2"
                    >
                      {publicContext.AdminDetails.fullName}
                    </Typography>
                  }
                />
              </ListItem>
              {listItemCustom('داشبورد', <DashboardIcon />, () => {
                router('/admin');
              })}
              {listItemCustom('پست ها', <AddAPhotoIcon />, () => {
                router('/admin/posts');
              })}
              {listItemCustom('تنظیمات', <SettingsIcon />, () => {
                router('/admin/setting');
              })}
              {listItemCustom('گزارشات', <BarChartIcon />, () => {
                router('/admin/reports');
              })}
              {listItemCustom('کد تخفیف', <CardMembershipIcon />, () => {
                router('/admin/gift-codes');
              })}
              {listItemCustom('کالاها', <PlaylistAddCheckRoundedIcon />, () => {
                router('/admin/products');
              })}
              {listItemCustom('دسته بندی', <AutoAwesomeMosaicIcon />, () => {
                router('/admin/category');
              })}
              {listItemCustom('سفارشات', <ViewListIcon />, () => {
                router('/admin/orders');
              })}
              {listItemCustom('گزینه ها', <StyleIcon />, () => {
                router('/admin/product-options');
              })}
              {listItemCustom('ویژگی ها', <DisplaySettingsIcon />, () => {
                router('/admin/product-feature-definitions');
              })}
              {listItemCustom('کاربران', <SupervisedUserCircleIcon />, () => {
                router('/admin/customers');
              })}
              {listItemCustom('قرعه کشی', <LocalActivityIcon />, () => {
                router('/admin/lottery');
              })}
              {listItemCustom('مدیران', <GroupIcon />, () => {
                router('/admin/manage-admins');
              })}
              {listItemCustom('بنر ها', <ImageOutlinedIcon />, () => {
                router('/admin/banners');
              })}
            </List>
          </List>

          <Divider />

          <ListItem button onClick={handleClickOpenDialog}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="خروج از پنل" />
          </ListItem>
          <Dialog
            dir="rtl"
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'آیا مایل به خروج از پنل مدیریت خود هستید؟'}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={handleCloseDialog}
                variant="contained"
                color="primary"
              >
                خیر
              </Button>
              <Button onClick={handleLogout} autoFocus>
                بله
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Drawer>
    </div>
  );
}
