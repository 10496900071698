import { useEffect, useState, useContext, Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME, API_URL } from '../../utils/Constants';
import CustomAddFormDialog from '../../components/CustomFormDialog';
import CustomEditFormDialog from '../../components/CustomFormDialog';
import AddCategory from './AddCategory';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import EditCategory from './EditCategory';

const Categories = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [parentId, setParentId] = useState(params['parentId']);
  const [willDeleteCatId, setWillDeleteCatId] = useState(0);
  const [willEditCatId, setWillEditCatId] = useState(0);
  const [parentCategory, setParentCategory] = useState({});
  const [categories, setCategories] = useState([]);
  const [openAddFormDialog, setOpenAddFormDialog] = useState(false);
  const [openEditFormDialog, setOpenEditFormDialog] = useState(false);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);

  const handleCloseAddFormDialog = () => {
    setOpenAddFormDialog(false);
  };

  const handleCloseEditFormDialog = () => {
    setOpenEditFormDialog(false);
  };

  const handleDeleteCategory = () => {
    setLoading(true);
    setOpenConfrimDialog(false);
    HttpClient.post('/admin/category/remove', {
      id: willDeleteCatId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'دسته مد نظر حذف شد',
            'success'
          );

          getCategories(parentId);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCategories = (_parentId = parentId) => {
    setLoading(true);
    HttpClient.post('/admin/category/get-all-subs', {
      parentId: parentId ? parentId : 0,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setParentCategory(httpRes.data.data.parentCategory);
          setCategories(httpRes.data.data.subCats);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        router('/admin', { replace: true });
      });
  };

  useEffect(() => {
    getCategories();
  }, [parentId]);

  return (
    <AdminLayout>
      {TitleSetter('دسته بندی ها | ' + SITE_NAME)}
      <Box sx={{ textAlign: 'center' }}>
        <Button startIcon={<AutoAwesomeMosaicIcon />}>دسته بندی ها</Button>
        <Divider sx={{ marginY: 1 }} />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <Paper sx={{ p: 1 }} elevation={5}>
          <Box sx={{ marginY: 1.5, textAlign: 'center' }}>
            <Chip
              variant="outlined"
              label={
                parentCategory && parentCategory.Name
                  ? parentCategory.Name
                  : 'دسته اصلی'
              }
            />
          </Box>
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              setOpenAddFormDialog(true);
            }}
          >
            افزودن
          </Button>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {parentCategory && Number(parentCategory.ParentCategoryId) >= 0 ? (
              <Fragment>
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => {
                    setParentId(parentCategory.ParentCategoryId);
                  }}
                >
                  <ListItemText
                    primary={
                      <Chip
                        label={'بازگشت'}
                        size="small"
                        icon={<ChevronRightIcon />}
                      />
                    }
                  />
                </ListItemButton>
                <Divider />
              </Fragment>
            ) : (
              <></>
            )}
            {categories.map((catItem, index) => (
              <Fragment key={index}>
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => {
                    setParentId(catItem.CategoryId);
                  }}
                >
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xl={1} lg={1} md={2} sm={2} xs={3}>
                          <img
                            src={`${API_URL}${catItem.ImageUrl}`}
                            style={{
                              width: '100%',
                              borderRadius: 10,
                              aspectRatio: '1/1',
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={11}
                          lg={11}
                          md={10}
                          sm={10}
                          xs={9}
                          sx={{
                            position: 'relative',
                            pl: 1,
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              transform: 'translateY(-50%)',
                            }}
                          >
                            {<Chip label={catItem.CategoryId} size="small" />}{' '}
                            {catItem.Name}
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  />
                  <IconButton
                    color="primary"
                    sx={{ justifySelf: 'end' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setWillEditCatId(Number(catItem.CategoryId));
                      setOpenEditFormDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    sx={{ justifySelf: 'end' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setWillDeleteCatId(Number(catItem.CategoryId));
                      setOpenConfrimDialog(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemButton>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Paper>
      )}
      <CustomAddFormDialog
        dialogTitle={'افزودن دسته'}
        handleClose={handleCloseAddFormDialog}
        open={openAddFormDialog}
      >
        <AddCategory
          parentId={parentId}
          setLoading={setLoading}
          loading={loading}
          handleCloseFormDialog={handleCloseAddFormDialog}
          getCategories={getCategories}
        />
      </CustomAddFormDialog>

      <CustomEditFormDialog
        dialogTitle={'ویرایش دسته'}
        handleClose={handleCloseEditFormDialog}
        open={openEditFormDialog}
      >
        <EditCategory
          getCategories={getCategories}
          categoryId={willEditCatId}
          handleCloseFormDialog={handleCloseEditFormDialog}
          loading={loading}
          setLoading={setLoading}
          parentId={parentId}
        />
      </CustomEditFormDialog>

      <CustomConfirmDialog
        text={'آیا از حذف دسته مطمعن هستید؟'}
        open={openConfrimDialog}
        confrimButtonText="بـله"
        declineButtonText="خـیر"
        declineButtonVariant="contained"
        confrimButtonVariant="default"
        loadingConfrimButton={loading}
        handleConfirm={handleDeleteCategory}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
    </AdminLayout>
  );
};

export default Categories;
