import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import validator from 'validator';
import httpStatus from 'http-status';
import axios from 'axios';

import SimpleSnakBar from '../components/SimpleSnakBar';
import { TitleSetter } from '../utils/HeadSetter';
import { SITE_NAME } from '../utils/Constants';
import HttpClient, { AddUserAdminTokenToHeader } from '../utils/HttpClient';

const AdminLogin = () => {
  const router = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [sneakBar, setSneakBar] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    // localStorage.removeItem('user-phone');
  });

  const handleConfrimValidation = async () => {
    setWaiting(true);

    HttpClient.post('/auth/admin-auth', {
      phone: phone,
      password: password,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          localStorage.setItem('admin-token', httpRes.data.data.token);
          localStorage.setItem('admin-phone', phone);
          AddUserAdminTokenToHeader(httpRes.data.data.token);
          return router('/admin', { replace: true });
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          if (
            error.response &&
            (error.response.status === httpStatus.BAD_REQUEST ||
              error.response.status === httpStatus.TOO_MANY_REQUESTS)
          ) {
            setSneakBar({
              open: true,
              message: error.response.data.message,
            });
          } else {
            setSneakBar({
              open: true,
              message: 'خطا در برقراری ارتباط',
            });
          }
        } else {
          setSneakBar({
            open: true,
            message: 'خطا در برقراری ارتباط',
          });
        }
      })
      .finally(() => {
        setWaiting(false);
      });
  };

  const handleCloseSneakBar = () => {
    setSneakBar({
      ...sneakBar,
      open: false,
    });
  };

  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      validator.isInt(phone) &&
      validator.isLength(phone, { max: 11, min: 11 }) &&
      validator.isLength(password, { min: 12 }) &&
      phone[0] + phone[1] === '09'
    ) {
      handleConfrimValidation();
    } else {
      setSneakBar({
        open: true,
        message: 'شماره و کلمه عبور را درست وارد کنید',
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {TitleSetter('ورود مدیران | ' + SITE_NAME)}

      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ورود به پنل مدیر
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            value={phone}
            onChange={handleChangePhone}
            id="phone"
            placeholder="نمونه : 09123456789"
            label="شماره موبایل"
            name="phone"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={handleChangePassword}
            id="password"
            label="کلمه عبور"
            name="password"
            type="password"
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loadingPosition="end"
            endIcon={<PhonelinkLockIcon />}
            sx={{ mt: 3, mb: 2 }}
            loading={waiting}
          >
            ورود
          </LoadingButton>
        </Box>
      </Box>
      <SimpleSnakBar
        open={sneakBar.open}
        message={sneakBar.message}
        handleClose={handleCloseSneakBar}
      />
    </Container>
  );
};

export default AdminLogin;
