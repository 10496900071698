import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ redirectPath = '/login', children }) => {
  window.localStorage.setItem('return-url', window.location.pathname);
  const userPhone = localStorage.getItem('user-phone');
  const userToken = localStorage.getItem('user-token');
  if (!userPhone || !userToken) return <Navigate to={redirectPath} replace />;
  else return children ? children : <Outlet />;
};

const AdminProtectedRoute = ({ redirectPath = '/admin-login', children }) => {
  const adminToken = localStorage.getItem('admin-token');
  if (!adminToken) return <Navigate to={redirectPath} replace />;
  else return children ? children : <Outlet />;
};

export { ProtectedRoute, AdminProtectedRoute };
