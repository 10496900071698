import moment from 'jalali-moment';
const CurrentDate = () => {
  try {
    const d = new Date(Date.now());
    return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
  } catch (error) {}
};
const ChangeFullDateTimeToDateEN = (fulldate) => {
  try {
    return (
      fulldate.getFullYear() +
      '-' +
      (fulldate.getMonth() + 1) +
      '-' +
      fulldate.getDate()
    );
  } catch (error) {}
};
const EnglishToFarsi = (date) => {
  try {
    return moment(date, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD');
  } catch (error) {}
};
const FarsiToEnglish = (date) => {
  try {
    return moment().locale('en').format(date);
  } catch (error) {}
};
const EnglishToFarsiWithTimestamp = (timestamp) => {
  try {
    const d = new Date(Number(timestamp));
    return moment(d, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD');
  } catch (error) {}
};

const EnglishToFarsiDateAndTimeWithTimestamp = (timestamp) => {
  const d = new Date(Number(timestamp));
  try {
    return moment(d).locale('fa').format('YYYY/M/D HH:mm:ss');
  } catch (error) {}
};

const TimeStampToCharDate = (v) => {
  return moment(Date(v)).locale('fa').format('D MMMM YYYY');
};
export {
  CurrentDate,
  EnglishToFarsi,
  FarsiToEnglish,
  EnglishToFarsiWithTimestamp,
  EnglishToFarsiDateAndTimeWithTimestamp,
  ChangeFullDateTimeToDateEN,
  TimeStampToCharDate,
};
