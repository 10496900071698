import { useEffect, useState, useContext, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import {
  EnglishToFarsi,
  EnglishToFarsiDateAndTimeWithTimestamp,
} from '../../utils/DateManager';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME, API_URL } from '../../utils/Constants';

const Customers = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [customersList, setCustomersList] = useState(0);
  const [customersLength, setCustomersLength] = useState([]);

  const handleChangePage = (event, value) => {
    setPage(value);
    GetList({
      offset: (value - 1) * 100,
    });
  };

  const GetList = ({ offset = 0 }) => {
    setLoading(true);
    HttpClient.post('/admin/customers/get-all', {
      offset: offset,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setCustomersList(httpRes.data.data.rows);

          setCustomersLength(httpRes.data.data.length);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        router('/admin', { replace: true });
      });
  };

  useEffect(() => {
    GetList({ offset: 0 });
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('کاربران | ' + SITE_NAME)}
      <Box sx={{ textAlign: 'center' }}>
        <Button startIcon={<SupervisedUserCircleIcon />}>کاربران</Button>
        <Divider sx={{ marginY: 1 }} />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <>
          <Paper sx={{ p: 1 }} elevation={5}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {customersList.map((customerItem, index) => (
                <Fragment key={index}>
                  <ListItemButton alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Box sx={{ mb: 1 }}>
                          {
                            <Chip
                              label={customerItem.UserId}
                              variant="outlined"
                            />
                          }{' '}
                          {<Chip label={customerItem.Phone} />}{' '}
                        </Box>
                      }
                      secondary={`تاریخ ثبت نام : ${EnglishToFarsiDateAndTimeWithTimestamp(
                        customerItem.FirstSignin
                      )}`}
                    />

                    <Chip
                      variant="outlined"
                      label={
                        Boolean(customerItem.Disabled) ? 'غیر فعال' : 'فعال'
                      }
                      color={
                        Boolean(customerItem.Disabled) ? 'error' : 'success'
                      }
                    />
                  </ListItemButton>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </Paper>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Divider sx={{ mb: 1 }} />
              <Pagination
                sx={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  justifyItems: 'center',
                }}
                count={Math.ceil(customersLength / 100)}
                defaultPage={1}
                page={page}
                onChange={handleChangePage}
                siblingCount={1}
              />
            </Grid>
          </Grid>
        </>
      )}
    </AdminLayout>
  );
};

export default Customers;
