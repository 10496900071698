import { useState, useContext, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { Routes, Route, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarProvider, useSnackbar } from 'notistack';
import httpStatus from 'http-status';

import AdminRoutes from './utils/AdminRoutes';
import NotFound from './pages/NotFound';
import RedirectedRoute from './utils/RedirectedRoute';
import PublicContext from './contexts/PublicContext';
import SimpleSnakBar from './components/SimpleSnakBar';
import HttpClient, { HandleHttpError } from './utils/HttpClient';
import {
  Backdrop,
  Box,
  Container,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import AdminLogin from './pages/AdminLogin';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
  direction: 'rtl',
  typography: {
    fontFamily: `"peyda", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        alignItemsFlexStart: { textAlign: 'right' },
      },
    },
  },
});

export const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  const storageThemeMode = window.localStorage.getItem('theme-mode') || 'dark';
  const router = useNavigate();

  const publicContext = useContext(PublicContext);
  const [loading, setLoading] = useState(true);
  const [failedLoad, setFailedLoad] = useState(false);
  const [deliveryAreas, setDeliveryAreas] = useState({ list: [] });
  const [basketCount, setBasketCount] = useState(0);
  const [themeMode, setThemeMode] = useState(storageThemeMode);
  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: '#1976d2',
      },
    },
    direction: 'rtl',
    typography: {
      fontFamily: `peyda`,
      fontSize: 14,
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          alignItemsFlexStart: { textAlign: 'right' },
        },
      },
      MuiButton: {
        styleOverrides: {
          borderRaduis: 3,
        },
      },
    },
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const enqueueSnackbarAction = (
    <>
      <IconButton color="inherit" size="small" sx={{ marginX: 1.5 }}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </>
  );

  const handleNewSnackBar = (
    message = 'عملیات با موفقیت انجام شد',
    variant = 'default',
    autoHideDuration = 4000,
    anchorOrigin = { horizontal: 'center', vertical: 'top' },
    haveCloseButton = true
  ) => {
    const key = enqueueSnackbar(message, {
      variant,
      autoHideDuration,
      anchorOrigin: anchorOrigin,
      action: haveCloseButton && enqueueSnackbarAction,

      onClick: () => {
        closeSnackbar(key);
      },
    });
  };

  function getBasketCount() {
    HttpClient.post('/shop/get-basket')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          let basketCount = httpRes.data.data.filter((item) => item.Stock > 0);
          setBasketCount(basketCount.length);
          window.localStorage.setItem('user-basket-count', basketCount.length);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  }

  const HandleIncreaseBasket = () => {
    setBasketCount(basketCount + 1);
  };

  const HandleDecreaseBasket = () => {
    setBasketCount(basketCount - 1);
  };

  const HandleUpdateBasketCount = () => {
    getBasketCount();
  };

  const HandleUpdateThemeMode = (mode) => {
    setThemeMode(mode);
    window.localStorage.setItem('theme-mode', mode);
  };
  const updateAreas = async () => {
    // HttpClient.get('/info/get-areas')
    //   .then((httpRes) => {
    //     if (httpRes.status === httpStatus.OK) {
    //       setDeliveryAreas({ list: [...httpRes.data.data.list] });
    //       setLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     HandleHttpError(error, publicContext);
    //   });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router]);
  useEffect(() => {
    // updateAreas();
  }, []);

  return (
    <PublicContext.Provider
      value={{
        ...publicContext,
        SnackBarControl: {
          handleNewSnackBar: handleNewSnackBar,
        },
        BasketCounterControl: {
          HandleIncreaseBasket: HandleIncreaseBasket,
          HandleDecreaseBasket: HandleDecreaseBasket,
          HandleUpdateBasketCount: HandleUpdateBasketCount,
          GetBasketCount: getBasketCount,
          BasketCount: basketCount,
        },
        theme: theme,
        ThemeMode: {
          mode: themeMode,
          HandleUpdateThemeMode: HandleUpdateThemeMode,
        },
        setDeliveryAreas: setDeliveryAreas,
        deliveryAreas: deliveryAreas,
        updateAreas: updateAreas,
        setFailedLoad: setFailedLoad,
      }}
    >
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <div
            dir="rtl"
            style={
              {
                // visibility:
              }
            }
          >
            <CssBaseline />

            <Routes>
              <Route path="admin-login" element={<AdminLogin />}></Route>
              {AdminRoutes()}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Backdrop
            open={failedLoad}
            sx={{
              zIndex: 999999,
              backgroundColor:
                theme.palette.mode == 'dark' ? '#072849fa' : '#808285fa',
            }}
          >
            <Box textAlign="center">
              <Typography
                variant="div"
                fontSize={14}
                fontWeight={700}
                textAlign="center"
                sx={{ pb: 1 }}
              >
                خطا در دریافت اطلاعات
              </Typography>
              <Box textAlign="center">
                <Typography
                  variant="div"
                  fontSize={12}
                  textAlign="center"
                  sx={{ pb: 2 }}
                >
                  اتصال اینترنت خود را بررسی کنید
                </Typography>
                <br />
                <Button
                  variant="contained"
                  sx={{ borderRadius: 2.5, mt: 3 }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  تلاش مجدد
                </Button>
              </Box>
            </Box>
          </Backdrop>
        </ThemeProvider>
      </CacheProvider>
    </PublicContext.Provider>
  );
}
export default App;
