import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Paper } from '@mui/material';
import { API_URL } from '../../utils/Constants';

export default function ManageProductImageList({
  imageList = [],
  handleDeleteImage,
}) {
  return (
    <Paper sx={{ p: 1 }} elevation={5}>
      <ImageList sx={{ width: '100%', height: 300 }}>
        <ImageListItem key="Subheader" cols={2}>
          <ListSubheader component="div">
            {imageList.length === 0 && <>گالری خالی است</>}
          </ListSubheader>
        </ImageListItem>
        {imageList.map((item, index) => (
          <ImageListItem key={index}>
            <img
              src={`${API_URL}${item.Url}`}
              srcSet={`${API_URL}${item.Url}`}
              loading="lazy"
            />
            <ImageListItemBar
              // subtitle={item.Title}
              actionIcon={
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`info about ${item.title}`}
                  onClick={() => {
                    handleDeleteImage(item);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Paper>
  );
}
