import { useEffect, useState, useContext, createRef } from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import { TitleSetter } from '../../utils/HeadSetter';
import { API_URL, SITE_NAME } from '../../utils/Constants';
import Delete from '@mui/icons-material/Delete';
import { TimeStampToCharDate } from '../../utils/DateManager';

const ImagesExt = [
  '.png',
  '.svg',
  '.jpg',
  '.gif',
  '.jpeg',
  '.avif',
  '.webp',
  '.heif',
  '.heic',
];

const EditPost = () => {
  const router = useNavigate();

  const publicContext = useContext(PublicContext);
  const params = useParams();

  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [post, setPost] = useState(null);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [mediaId, setMediaId] = useState(null);

  const imageFileRef = createRef();
  const imageEditFileRef = createRef();

  const handleClearErrors = () => setErrorMsg([]);

  const handleEditProduct = async (e) => {
    setUploadPercent(0);
    setOpenConfrimDialog(false);
    setWaiting(true);
    handleClearErrors();

    HttpClient.post(
      '/admin/posts/edit',
      {
        postId: params['postId'],
        text: document.getElementById('text-input').value,
        title: document.getElementById('title-input').value,
        titleHead: document.getElementById('title-head-input').value,
        metaDescription: document.getElementById('metaDescription-input').value,
        metaKeyword: document.getElementById('metaKeyword-input').value,
      },
      {
        onUploadProgress: (progressEvent) => {
          setUploadPercent(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      }
    )
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(`ثبت شد`, 'success');
        }
      })
      .catch((error) => {
        setErrorMsg(error.response.data.data.errors);
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setWaiting(false);
      });
  };

  const handleFileChange = (e) => {
    let _file = null;
    if (e.target.files[0]) {
      _file = e.target.files[0];
    }

    const img = {
      imageUrl: URL.createObjectURL(_file),
      data: _file,
      fileName: _file.name,
      _id: Math.random() * 100000,
      component: _file.type.includes('image/') ? 'img' : 'video',
    };
    if (img.data.size > 1048576 * 100) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر فایل (100 مگابایت)',
        'error'
      );
      return;
    }
    let formData = new FormData();
    formData.append('image', _file);
    formData.append('postId', params['postId']);

    setWaiting(true);
    HttpClient.post('/admin/posts/add-image', formData, {
      onUploadProgress: (progressEvent) => {
        setUploadPercent(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            `افزوده شد`,
            'success'
          );
          fetchData();
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setWaiting(false);
      });
  };
  const handleEditFileChange = (e) => {
    let _file = null;
    if (e.target.files[0]) {
      _file = e.target.files[0];
    }

    const img = {
      imageUrl: URL.createObjectURL(_file),
      data: _file,
      fileName: _file.name,
      _id: Math.random() * 100000,
      component: _file.type.includes('image/') ? 'img' : 'video',
    };
    if (img.data.size > 1048576 * 100) {
      publicContext.SnackBarControl.handleNewSnackBar(
        'حداکثر سایز هر فایل (100 مگابایت)',
        'error'
      );
      return;
    }
    let formData = new FormData();
    formData.append('image', _file);
    formData.append('postId', params['postId']);
    formData.append('mediaId', mediaId);

    HttpClient.post('/admin/posts/replace-image', formData, {
      onUploadProgress: (progressEvent) => {
        setUploadPercent(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setMediaId(null);
          publicContext.SnackBarControl.handleNewSnackBar(
            `افزوده شد`,
            'success'
          );
          fetchData();
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setWaiting(false);
      });
  };
  const handleDeleteImage = (mediaId) => {
    let asnwer = window.confirm('آیا مایل به حذف هستید');
    if (asnwer) {
      setWaiting(true);
      HttpClient.post(
        '/admin/posts/remove-image',
        {
          mediaId,
        },
        {
          onUploadProgress: (progressEvent) => {
            setUploadPercent(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
        }
      )
        .then((httpRes) => {
          if (httpRes.status === httpStatus.OK) {
            publicContext.SnackBarControl.handleNewSnackBar(
              `حذف شد`,
              'success'
            );
            fetchData();
          }
        })
        .catch((error) => {
          HandleHttpError(error, publicContext);
        })
        .finally(() => {
          setWaiting(false);
        });
    }
  };

  const Input = styled('input')({
    display: 'none',
  });

  useEffect(() => {}, []);

  const handleClickSave = () => {
    setOpenConfrimDialog(true);
  };

  function fetchData(_page) {
    setLoading(true);
    HttpClient.post('/admin/posts/get-one', {
      postId: params['postId'],
    })
      .then(async (httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setPost(httpRes.data.data);
          document.getElementById('text-input').value =
            httpRes.data.data.post.Text;
          document.getElementById('title-input').value =
            httpRes.data.data.post.Title;
          document.getElementById('title-head-input').value =
            httpRes.data.data.post.TitleHead;
          document.getElementById('metaDescription-input').value =
            httpRes.data.data.post.MetaDescription;
          document.getElementById('metaKeyword-input').value =
            httpRes.data.data.post.MetaKeyword;
          let imgs = [];
          await Promise.all(
            httpRes.data.data.medias.map(async (media) => {
              let ext =
                media.MediaUrl.split('.')[media.MediaUrl.split('.').length - 1];

              imgs.push({
                MediaUrl: media.MediaUrl,
                component: ImagesExt.includes(`.${ext.toLowerCase()}`)
                  ? 'img'
                  : 'video',
                _id: media.PostsMediaId,
              });
            })
          );
          setImages(imgs);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleAcceptToggle(accept, commentId) {
    setLoading(true);
    HttpClient.post('/admin/posts/toggle-accept-comment', {
      commentId,
      accept,
    })
      .then(async (httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setPost(httpRes.data.data);
          document.getElementById('text-input').value =
            httpRes.data.data.post.Text;
          document.getElementById('title-input').value =
            httpRes.data.data.post.Title;
          document.getElementById('metaDescription-input').value =
            httpRes.data.data.post.MetaDescription;
          document.getElementById('metaKeyword-input').value =
            httpRes.data.data.post.MetaKeyword;
          let imgs = [];
          await Promise.all(
            httpRes.data.data.medias.map(async (media) => {
              let ext =
                media.MediaUrl.split('.')[media.MediaUrl.split('.').length - 1];

              imgs.push({
                MediaUrl: media.MediaUrl,
                component: ImagesExt.includes(`.${ext.toLowerCase()}`)
                  ? 'img'
                  : 'video',
                _id: media.PostsMediaId,
              });
            })
          );
          setImages(imgs);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleDeletePost() {
    let asnwer = window.confirm('آیا مایل به حذف هستید');
    if (asnwer) {
      setLoading(true);
      HttpClient.post('/admin/posts/delete', {
        postId: params['postId'],
      })
        .then(async (httpRes) => {
          if (httpRes.status === httpStatus.OK) {
            router('/admin/posts', {
              replace: true,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          HandleHttpError(error, publicContext);
        });
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <AdminLayout>
      {TitleSetter('ویرایش پست | ' + SITE_NAME)}

      <Grid container spacing={2} pb={10}>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router('/admin/posts');
            }}
            size="small"
          >
            پست ها
          </Button>

          <Divider sx={{ marginY: 1 }} />

          <LoadingButton
            loading={loading || waiting}
            color="success"
            variant="contained"
            size="large"
            sx={{
              position: 'fixed',
              bottom: 10,
              left: 10,
              paddingY: 1,
              paddingX: 5,
              zIndex: (theme) => theme.zIndex.fab + 1,
            }}
            startIcon={<SaveIcon />}
            onClick={handleClickSave}
          >
            ذخیره
          </LoadingButton>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="caption" textAlign={'center'}>
                لایک ها ({post?.likes})
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                accept="image/*,video/*"
                id="images"
                type="file"
                onChange={handleFileChange}
                ref={imageFileRef}
              />
              <input
                accept="image/*,video/*"
                id="images-edit"
                type="file"
                style={{
                  display: 'none',
                }}
                onChange={handleEditFileChange}
                ref={imageEditFileRef}
              />
              <Button
                startIcon={<PhotoCamera />}
                aria-label="upload picture"
                variant="contained"
                onClick={() => {
                  imageFileRef.current.click();
                }}
              >
                افزودن فیلم یا تصویر
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {images.map((img) => {
                  return (
                    <Grid item xs={6} md={3} key={img._id}>
                      <Box
                        sx={{
                          p: 2,
                          border: '2px solid gray',
                          borderRadius: 4,
                          height: '100%',
                          position: 'relative',
                        }}
                      >
                        <Box
                          component={img.component}
                          sx={{
                            width: '100%',
                            maxWidth: 600,
                            height: 'auto',
                          }}
                          src={`${API_URL}${img.MediaUrl}`}
                          controls={true}
                          width={50}
                          height={50}
                        />
                        <IconButton
                          color="error"
                          sx={{
                            mt: 1,
                            position: 'absolute',
                            left: '-10px',
                            top: '-18px',
                            backgroundColor: '#FFFFFF',
                          }}
                          size="small"
                          varinat="filled"
                          onClick={() => {
                            handleDeleteImage(img._id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                        <IconButton
                          color="primary"
                          sx={{
                            mt: 1,
                            position: 'absolute',
                            right: '-10px',
                            top: '-18px',
                            backgroundColor: '#FFFFFF',
                          }}
                          size="small"
                          varinat="filled"
                          onClick={() => {
                            imageEditFileRef.current.click();
                            setMediaId(img._id);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                label="کپشن"
                id="text-input"
                InputLabelProps={{ shrink: true }}
              />
              {errorMsg?.text && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.text}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="عنوان"
                id="title-input"
                InputLabelProps={{ shrink: true }}
              />
              {errorMsg?.title && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.title}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="عنوان صفحه"
                id="title-head-input"
                InputLabelProps={{ shrink: true }}
              />
              {errorMsg?.title && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.titleHead}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="توضیحات"
                id="metaDescription-input"
                InputLabelProps={{ shrink: true }}
              />
              {errorMsg?.metaDescription && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.metaDescription}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="کلمه های کلیدی"
                id="metaKeyword-input"
                InputLabelProps={{ shrink: true }}
              />
              {errorMsg?.metaKeyword && (
                <Typography color={'darkred'} sx={{ mt: 1, mb: 2 }}>
                  {errorMsg.metaKeyword}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                startIcon={<Delete />}
                aria-label="upload picture"
                variant="contained"
                onClick={handleDeletePost}
              >
                حذف
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 6 }}>
            <Grid item xs={12}>
              <Typography variant="h4" textAlign={'center'}>
                نظرات ({post?.comments.length})
              </Typography>
            </Grid>
            {post && post.comments && post.comments.length > 0
              ? post.comments.map((comment) => {
                  return (
                    <Grid item xs={12} key={comment.CommentId}>
                      <Paper sx={{ p: 2, borderRadius: 4 }} elevation={5}>
                        <Box
                          sx={{
                            fontSize: 14,
                            mb: 1,
                          }}
                        >
                          {comment.Text}
                        </Box>
                        <Box
                          sx={{
                            fontSize: 12,
                            mb: 3,
                            textAlign: 'end',
                          }}
                        >
                          {TimeStampToCharDate(comment.CreatedTimestamp)}
                        </Box>
                        {comment.Accept ? (
                          <LoadingButton
                            loading={loading}
                            onClick={() => {
                              handleAcceptToggle(false, comment.CommentId);
                            }}
                            color="error"
                            variant="contained"
                          >
                            رد تایید
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            loading={loading}
                            onClick={() => {
                              handleAcceptToggle(true, comment.CommentId);
                            }}
                            color="success"
                            variant="contained"
                          >
                            {' '}
                            تایید
                          </LoadingButton>
                        )}
                      </Paper>
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        loadingConfrimButton={waiting}
        loadingDeclineButton={waiting}
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        handleConfirm={handleEditProduct}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        open={waiting}
      >
        <CircularProgressWithLabel value={uploadPercent} />{' '}
      </Backdrop>
    </AdminLayout>
  );
};

export default EditPost;
