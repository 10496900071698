import React, { useEffect, useState, useContext, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import httpStatus from 'http-status';
import ClearIcon from '@mui/icons-material/Clear';
import BarChartIcon from '@mui/icons-material/BarChart';
import PrintIcon from '@mui/icons-material/Print';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterJalali from '@date-io/date-fns-jalali';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useReactToPrint } from 'react-to-print';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import { EnglishToFarsiDateAndTimeWithTimestamp } from '../../utils/DateManager';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME } from '../../utils/Constants';
import provincesJson from './../../assets/provinces.json';
import citiesJson from './../../assets/cities.json';
import CategoriesTreeView from '../../components/admin/CategoriesTreeView';
import {
  cityIdToFaName,
  provinceIdToFaName,
} from '../../utils/LocationConvertor';
import PriceSplitter from '../../utils/PriceSplitter';

const Reports = () => {
  const publicContext = useContext(PublicContext);

  let nowTimestamp = Date.now();

  const handlePrintReport = useReactToPrint({
    content: () => ReportRef.current,
  });
  const handlePrintOrdersReport = useReactToPrint({
    content: () => OrdersReportRef.current,
  });

  const ReportRef = useRef();
  const OrdersReportRef = useRef();

  const [loading, setLoading] = useState(true);
  const [fromTimestamp, setFromTimestamp] = useState(nowTimestamp);
  const [toTimestamp, setToTimestamp] = useState(nowTimestamp);
  const [provinceId, setProvinceId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [ascending, setAscending] = useState(false);
  const [disabledCitySelect, setDisabledCitySelect] = useState(true);
  const [availableCities, setAvailableCities] = useState([]);
  const [availableProvinces, setAvailableProvinces] = useState([]);
  const [fromDate, setFromDate] = useState(new Date(nowTimestamp));
  const [toDate, setToDate] = useState(new Date(nowTimestamp));
  const [reportResult, setReportResult] = useState({});
  const [fromEnabledDate, setFromEnabledDate] = useState(false);
  const [toEnabledDate, setToEnabledDate] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedCategoryListItem, setSelectedCategoryListItem] = useState([]);

  const getList = () => {
    if (fromEnabledDate && toEnabledDate) {
      if (Number(fromTimestamp) === Number(toTimestamp)) {
        publicContext.SnackBarControl.handleNewSnackBar(
          'تاریخ ها نباید برابر باشند',
          'warning'
        );
        return;
      }

      if (Number(fromTimestamp) > Number(toTimestamp)) {
        publicContext.SnackBarControl.handleNewSnackBar(
          'تاریخ مبدا نباید بزرگ تر باشد',
          'warning'
        );
        return;
      }
    }
    setLoading(true);
    HttpClient.post('/admin/reports/get-all', {
      categories: selectedCategoryList,
      fromTimestamp: fromEnabledDate ? fromTimestamp : 0,
      toTimestamp: toEnabledDate ? toTimestamp : 0,
      cityId: cityId,
      provinceId: provinceId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setReportResult(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hanleChangeProvince = (e) => {
    const filteredCities = [];
    if (Number(e.target.value) === 0) {
      setAvailableCities([...filteredCities]);
      setDisabledCitySelect(true);
      setProvinceId(0);
      setCityId(0);

      return;
    }
    citiesJson.forEach((city) => {
      if (city.province_id === Number(e.target.value))
        filteredCities.push(city);
    });
    setAvailableCities([...filteredCities]);
    setDisabledCitySelect(false);
    setProvinceId(Number(e.target.value));
    setCityId(0);
  };

  const handleClickClearFilter = async () => {
    setLoading(true);

    setDisabledCitySelect(true);
    setFromEnabledDate(false);
    setToEnabledDate(false);

    setFromDate(new Date(Date.now()));
    setToDate(new Date(Date.now()));
    setFromTimestamp(0);
    setToTimestamp(0);
    setProvinceId(0);
    setCityId(0);

    HttpClient.post('/admin/reports/get-all', {
      categories: [],
      fromTimestamp: 0,
      toTimestamp: 0,
      orderStatus: 0,
      provinceId: 0,
      cityId: 0,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setReportResult(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeCheckbox = async (CategoryId, CategoryName) => {
    let categoryId = Number(CategoryId);
    let _selectedCategoryList = [...selectedCategoryList];

    let _selectedCategoryListItem = [...selectedCategoryListItem];

    let findedIndex = _selectedCategoryList.indexOf(categoryId);

    let findedItem = _selectedCategoryListItem.find(
      (item) => Number(item.id) === categoryId
    );

    let findedIndexItem = _selectedCategoryListItem.indexOf(findedItem);
    if (findedIndexItem !== -1) {
      _selectedCategoryListItem.splice(findedIndexItem, 1);
    } else {
      _selectedCategoryListItem.push({ id: categoryId, catName: CategoryName });
    }
    setSelectedCategoryListItem([..._selectedCategoryListItem]);

    if (findedIndex !== -1) {
      _selectedCategoryList.splice(findedIndex, 1);
    } else {
      _selectedCategoryList.push(categoryId);
    }
    setSelectedCategoryList([..._selectedCategoryList]);
  };

  const handleChangeToDateValue = (newValue) => {
    setToDate(newValue);
    setToTimestamp(newValue.getTime());
  };

  const handleClickFilter = (newValue) => {
    getList({});
  };

  const handleChangeFromDateValue = (newValue) => {
    setFromDate(newValue);
    setFromTimestamp(newValue.getTime());
  };

  useEffect(() => {
    setAvailableProvinces([...provincesJson]);
    HttpClient.post('/admin/category/get-all')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setCategoryList(httpRes.data.data);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });

    getList({});
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterJalali}>
      <AdminLayout>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {TitleSetter('گزارشات | ' + SITE_NAME)}
            <Box sx={{ textAlign: 'center' }}>
              <Button startIcon={<BarChartIcon />}>گزارشات</Button>
              <Divider sx={{ marginY: 1 }} />
            </Box>
          </Grid>
          {loading ? (
            <Grid item xs={12}>
              <Skeleton variant="rectangular" height={700} width="100%" />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="province-label">استان</InputLabel>
                      <Select
                        label="استان"
                        labelId="province-label"
                        id="province-label"
                        value={provinceId}
                        onChange={hanleChangeProvince}
                      >
                        <MenuItem value={0}>همه</MenuItem>

                        {availableProvinces.map((province, index) => (
                          <MenuItem key={index} value={province.id}>
                            {province.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="city-label">شهرستان</InputLabel>
                      <Select
                        labelId="city-label"
                        id="city"
                        fullWidth
                        value={cityId}
                        onChange={(e) => {
                          setCityId(Number(e.target.value));
                        }}
                        label="شهرستان"
                        disabled={disabledCitySelect}
                      >
                        <MenuItem value={0}>
                          {disabledCitySelect ? 'انتخاب کنید' : 'همه'}
                        </MenuItem>

                        {availableCities.map((city, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={city.id}
                              selected={city.id === 0 ? true : false}
                            >
                              {city.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={fromEnabledDate}
                          onChange={(e) => {
                            setFromEnabledDate(Boolean(e.target.checked));
                            setFromTimestamp(
                              Boolean(e.target.checked) ? Date.now() : 0
                            );
                          }}
                        />
                      }
                      label="از تاریخ"
                    />
                    {fromEnabledDate === true ? (
                      <DateTimePicker
                        label="از"
                        disableMaskedInput={true}
                        ampm={false}
                        value={fromDate}
                        onChange={handleChangeFromDateValue}
                        renderInput={(props) => (
                          <TextField fullWidth={true} {...props} />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={toEnabledDate}
                          onChange={(e) => {
                            setToEnabledDate(Boolean(e.target.checked));
                            setToTimestamp(
                              Boolean(e.target.checked) ? Date.now() : 0
                            );
                          }}
                        />
                      }
                      label="تا تاریخ"
                    />
                    {toEnabledDate === true ? (
                      <DateTimePicker
                        label="تا"
                        disableMaskedInput={true}
                        ampm={false}
                        value={toDate}
                        onChange={handleChangeToDateValue}
                        renderInput={(props) => (
                          <TextField fullWidth={true} {...props} />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ marginY: 1 }} />
                    <Typography variant="body1">انتخاب دسته بندی</Typography>
                    <Paper sx={{ p: 2, marginY: 1.5 }}>
                      <CategoriesTreeView
                        list={categoryList}
                        selectedItems={selectedCategoryList}
                        handleChangeCheckbox={handleChangeCheckbox}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      variant="contained"
                      loading={loading}
                      startIcon={<FilterAltIcon />}
                      sx={{ marginX: 1 }}
                      onClick={handleClickFilter}
                    >
                      فیلتر گزارش
                    </LoadingButton>
                    <LoadingButton
                      loading={loading}
                      startIcon={<ClearIcon />}
                      onClick={async () => {
                        await handleClickClearFilter();
                      }}
                    >
                      حذف فیلتر
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} ref={ReportRef}>
                <Paper sx={{ m: 1, p: 2.5 }} elevation={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6" textAlign="center">
                        گزارش کلی | {SITE_NAME}
                      </Typography>
                      <Divider sx={{ marginY: 1 }} />
                    </Grid>
                    {fromEnabledDate ||
                    toEnabledDate ||
                    provinceId != 0 ||
                    selectedCategoryListItem.length > 0 ||
                    cityId != 0 ? (
                      <Grid item xs={12}>
                        <Paper elevation={6} sx={{ p: 2 }}>
                          <Grid container spacing={2}>
                            {fromEnabledDate ? (
                              <Grid item xs={12}>
                                <>
                                  از تاریخ :{' '}
                                  {EnglishToFarsiDateAndTimeWithTimestamp(
                                    fromTimestamp
                                  )}
                                </>
                              </Grid>
                            ) : (
                              <></>
                            )}
                            {toEnabledDate ? (
                              <Grid item xs={12}>
                                <>
                                  تا تاریخ :{' '}
                                  {EnglishToFarsiDateAndTimeWithTimestamp(
                                    toTimestamp
                                  )}
                                </>
                              </Grid>
                            ) : (
                              <></>
                            )}
                            {provinceId != 0 ? (
                              <Grid item xs={12}>
                                <>استان : {provinceIdToFaName(provinceId)}</>
                              </Grid>
                            ) : (
                              <></>
                            )}
                            {cityId != 0 ? (
                              <Grid item xs={12}>
                                <>استان : {cityIdToFaName(cityId)}</>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {selectedCategoryListItem.length > 0 ? (
                              <Grid item xs={12}>
                                دسته بندی های :
                                {selectedCategoryListItem.map(
                                  (catItem, index) => {
                                    return (
                                      <Chip
                                        variant="outlined"
                                        key={index}
                                        label={catItem.catName}
                                        size="small"
                                        sx={{ m: 0.25 }}
                                      />
                                    );
                                  }
                                )}
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Paper>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      مجموع پرداختی درگاه :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(reportResult.SumPayAmount)} تومان
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      مجموع تعداد فروش کالا (گزینه ها) :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {reportResult.CountOfSellStock} عدد واحد
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      مجموع فروش بدون احتساب کرایه :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(reportResult.SumSellPrice)} تومان
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      مجموع کارمزد درگاه :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(
                            Number(reportResult.SumPaymentGatewayFee) / 10
                          )}{' '}
                          تومان
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      مجموع تخفیف گزینه کالا ها :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(reportResult.SumOffPrice)} تومان
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      مجموع کد تخفیف ها :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(reportResult.SumOffPriceForGifts)}{' '}
                          تومان
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      مجموع تخفیف ها :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(
                            reportResult.SumOffPriceForGifts +
                              reportResult.SumOffPrice
                          )}{' '}
                          تومان
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      مجموع کرایه ها :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(reportResult.SumDeliveryPrice)} تومان
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      مجموع خرید های گزینه کالاها :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(reportResult.SumBuyPrice)} تومان
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      کل سود فروش بدون کسر کارمزد :{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(reportResult.SumInterestPrice)} تومان
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} sx={{ fontSize: 12 }}>
                      کل سود فروش با کسر کارمزد (ناخالص):{' '}
                      {
                        <Typography
                          component={'span'}
                          fontSize={13}
                          fontWeight={800}
                        >
                          {PriceSplitter(
                            Number(reportResult.SumInterestPrice) -
                              Number(reportResult.SumPaymentGatewayFee) / 10
                          )}{' '}
                          تومان
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button startIcon={<PrintIcon />} onClick={handlePrintReport}>
                  چاپ گزارش کلی
                </Button>
                <Divider sx={{ marginY: 2 }} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} ref={OrdersReportRef}>
                    <Paper sx={{ p: 1.5, m: 1 }} elevation={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" textAlign="center">
                            گزارش لیست سفارشات | {SITE_NAME}
                          </Typography>
                          <Divider sx={{ marginY: 1 }} />
                        </Grid>
                        {fromEnabledDate ||
                        toEnabledDate ||
                        provinceId != 0 ||
                        selectedCategoryListItem.length > 0 ||
                        cityId != 0 ? (
                          <Grid item xs={12}>
                            <Paper elevation={6} sx={{ p: 2 }}>
                              <Grid container spacing={2}>
                                {fromEnabledDate ? (
                                  <Grid item xs={12}>
                                    <>
                                      از تاریخ :{' '}
                                      {EnglishToFarsiDateAndTimeWithTimestamp(
                                        fromTimestamp
                                      )}
                                    </>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {toEnabledDate ? (
                                  <Grid item xs={12}>
                                    <>
                                      تا تاریخ :{' '}
                                      {EnglishToFarsiDateAndTimeWithTimestamp(
                                        toTimestamp
                                      )}
                                    </>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {provinceId != 0 ? (
                                  <Grid item xs={12}>
                                    <>
                                      استان : {provinceIdToFaName(provinceId)}
                                    </>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {cityId != 0 ? (
                                  <Grid item xs={12}>
                                    <>استان : {cityIdToFaName(cityId)}</>
                                  </Grid>
                                ) : (
                                  <></>
                                )}

                                {selectedCategoryListItem.length > 0 ? (
                                  <Grid item xs={12}>
                                    دسته بندی های :
                                    {selectedCategoryListItem.map(
                                      (catItem, index) => {
                                        return (
                                          <Chip
                                            variant="outlined"
                                            key={index}
                                            label={catItem.catName}
                                            size="small"
                                            sx={{ m: 0.25 }}
                                          />
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      <List>
                        {reportResult.orders.length === 0 && (
                          <ListItemButton>
                            <ListItemText sx={{ textAlign: 'center' }}>
                              سفارشی وجود ندارد
                            </ListItemText>
                          </ListItemButton>
                        )}
                        {reportResult.orders.map((orderItem, index) => {
                          return (
                            <ListItemButton
                              key={index}
                              sx={{
                                p: 2,
                                border: '2px solid gray',
                                borderRadius: 3,
                                mt: 1,
                              }}
                              onClick={() => {
                                window.open(
                                  `/admin/order/${orderItem.OrderId}`,
                                  '_blank'
                                );
                              }}
                            >
                              <Chip
                                label={orderItem.OrderId}
                                variant="outlined"
                                size="small"
                                sx={{ mr: 2 }}
                              />
                              <Typography variant="subtitle2">
                                نهایی فاکتور بدون کرایه :{' '}
                                {PriceSplitter(orderItem.SumSellPrice)} تومان
                                <br />
                                کرایه :{' '}
                                {parseInt(orderItem.DeliveryPrice) > 0
                                  ? `${PriceSplitter(
                                      orderItem.DeliveryPrice
                                    )} تومان`
                                  : `رایگان`}{' '}
                                <br />
                                پرداختی :{' '}
                                {PriceSplitter(
                                  parseInt(orderItem.DeliveryPrice) +
                                    parseInt(orderItem.SumSellPrice) -
                                    ((orderItem.OffCodeValue &&
                                      orderItem.OffCodeValue.length) > 0
                                      ? parseInt(orderItem.OffCodePrice)
                                      : 0)
                                )}{' '}
                                تومان
                                <br />
                                زمان ثبت :{' '}
                                {EnglishToFarsiDateAndTimeWithTimestamp(
                                  orderItem.CreateTimeStamp
                                )}
                              </Typography>
                              <Divider />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      startIcon={<PrintIcon />}
                      onClick={handlePrintOrdersReport}
                    >
                      چاپ لیست سفارشات
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </AdminLayout>
    </LocalizationProvider>
  );
};

export default Reports;
