import { useEffect, useState, useContext, Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StyleIcon from '@mui/icons-material/Style';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import { TitleSetter } from '../../utils/HeadSetter';
import { SITE_NAME, API_URL } from '../../utils/Constants';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import CustomAddFormDialog from '../../components/CustomFormDialog';
import CustomEditFormDialog from '../../components/CustomFormDialog';
import EditOptionDefinition from './EditOptionDefinition';
import AddOptionDefinition from './AddOptionDefinition';

const ProductOptions = () => {
  const publicContext = useContext(PublicContext);

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [optionsNameSearch, setOptionsNameSearch] = useState('');
  const [willDeleteOptionId, setWillDeleteOptionId] = useState(0);
  const [willEditOptionId, setWillEditOptionId] = useState(0);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [openEditFormDialog, setOpenEditFormDialog] = useState(false);
  const [openAddFormDialog, setOpenAddFormDialog] = useState(false);

  const handleCloseAddFormDialog = () => {
    setOpenAddFormDialog(false);
  };

  const handleCloseEditFormDialog = () => {
    setOpenEditFormDialog(false);
  };

  const handleOptionsNameSearch = (e) => {
    setOptionsNameSearch(e.target.value);
    setFilteredOptions([
      ...options.filter((option) =>
        option.Name.includes(e.target.value.trim())
      ),
    ]);
  };
  const GetList = (e) => {
    setLoading(true);

    HttpClient.post('/admin/product-options/get-all-for-edit')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setOptions(httpRes.data.data);
          setFilteredOptions([...httpRes.data.data]);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      });
  };

  const handleDeleteOption = () => {
    setOpenConfrimDialog(false);
    setLoading(true);
    HttpClient.post('/admin/product-options/remove', {
      id: willDeleteOptionId,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          GetList();

          publicContext.SnackBarControl.handleNewSnackBar(
            'گزینه مد نظر حذف شد',
            'success'
          );
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetList();
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('گزینه ها | ' + SITE_NAME)}
      <Box sx={{ textAlign: 'center' }}>
        <Button startIcon={<StyleIcon />}>گزینه ها</Button>
        <Divider sx={{ marginY: 1 }} />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <Paper sx={{ p: 1 }} elevation={5}>
          <TextField
            sx={{ marginY: 2 }}
            fullWidth={true}
            value={optionsNameSearch}
            onChange={handleOptionsNameSearch}
            variant="filled"
            label="جستجو"
            autoFocus={true}
          />
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              setOpenAddFormDialog(true);
            }}
          >
            افزودن
          </Button>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {filteredOptions.map((option, index) => (
              <Fragment key={index}>
                <ListItemButton alignItems="flex-start">
                  <ListItemText primary={option.Name} />
                  <IconButton
                    color="primary"
                    sx={{ justifySelf: 'end' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setWillEditOptionId(Number(option.OptionId));
                      setOpenEditFormDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    sx={{ justifySelf: 'end' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setWillDeleteOptionId(Number(option.OptionId));
                      setOpenConfrimDialog(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemButton>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Paper>
      )}
      <CustomAddFormDialog
        dialogTitle={'افزودن گزینه'}
        handleClose={handleCloseAddFormDialog}
        open={openAddFormDialog}
      >
        <AddOptionDefinition
          setLoading={setLoading}
          loading={loading}
          handleCloseFormDialog={handleCloseAddFormDialog}
          getList={GetList}
        />
      </CustomAddFormDialog>

      <CustomEditFormDialog
        dialogTitle={'ویرایش گزینه'}
        handleClose={handleCloseEditFormDialog}
        open={openEditFormDialog}
      >
        <EditOptionDefinition
          getList={GetList}
          optionId={willEditOptionId}
          handleCloseFormDialog={handleCloseEditFormDialog}
          loading={loading}
          setLoading={setLoading}
        />
      </CustomEditFormDialog>
      <CustomConfirmDialog
        text={'آیا از حذف گزینه مطمعن هستید؟'}
        open={openConfrimDialog}
        confrimButtonText="بـله"
        declineButtonText="خـیر"
        declineButtonVariant="contained"
        confrimButtonVariant="default"
        loadingConfrimButton={loading}
        handleConfirm={handleDeleteOption}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
    </AdminLayout>
  );
};

export default ProductOptions;
