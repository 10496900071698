import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Typography, Box } from '@mui/material';

// export const WEBSITE_URL = `http://localhost:3022`;
// export const API_URL = `http://localhost:5050`;
export const WEBSITE_URL = `https://admin.psnshop.org`;
export const API_URL = `https://api.psnshop.org`;

export const SITE_NAME = `PSN Shop`;
export const APPBAR_TITLE = `PSN Shop | پی اس ان شاپ`;
export const DELIVERY_TIME = () => {
  return (
    <>
      <Box sx={{ mb: 0.5 }}>
        <Typography variant="caption" color="primary">
          <ScheduleSendIcon fontSize="inherit" sx={{ mr: 0.5 }} />
          ارسال به شهرستان ها 48 ساعته
        </Typography>
      </Box>
    </>
  );
};

export const PRIVACY_TEXT = () => {
  return (
    <div
      style={{
        fontSize: 15,
        textAlign: 'justify',
      }}
    >
      قوانین پی اس ان شاپ تاریخ آخرین به‌روزرسانی صفحه قوانین و مقررات سایت پی
      اس ان شاپ 1 مرداد 1401 بوده است و فرض بر این است که حداکثر تا 1 روز پس از
      این تاریخ کاربران سایت آن را مطالعه کرده و از مفاد آن کاملا آگاه هستند. پی
      اس ان شاپ می‌تواند برای ارائه بهتر خدمات خود، نسبت به به‌روزرسانی و ایجاد
      تغییرات در قوانین و مقررات سایت، با ذکر دقیق تاریخ، اقدام نماید و از
      کاربران هم انتظار می‌رود نسبت به تغییرات انجام پذیرفته آگاهی کامل کسب
      نمایند.
      <br />
      عضویت در سایت پی اس ان شاپ رایگان می‌باشد. اشخاص برای عضویت باید به بخش
      عضویت مراجعه نمایند. ثبت‌نام در سایت و تکمیل فرم عضویت که در آن اطلاعاتی
      نظیر شماره تماس و آدرس دریافت می‌شود به‌منزله آگاهی و پذیرش کامل قوانین و
      مقررات سایت پی اس ان شاپ می‌باشد.
      <br /> هر شخصی که به هر مقصود و به هراندازه‌ای از سایت پی اس ان شاپ
      استفاده نماید به معنای آن است که از تمام قوانین و مقررات سایت آگاهی کامل
      دارد و آن را به‌صورت کامل پذیرفته و تابع آن می‌باشد. اشخاص برای عضویت در
      سایت باید از اطلاعات و مشخصات هویتی خودشان استفاده نمایند و سایت پی اس ان
      شاپ هیچ‌گونه مسئولیتی در ازای تخلف از این بخش ندارد. اشخاص باید در ایجاد
      حساب کاربری خود دقت کافی نمایند تا اطلاعات و مشخصات واقعی خود را صحیح و
      کامل ثبت نمایند. پی اس ان شاپ مسئولیتی در قبال نادرست بودن اطلاعات کاربر
      ندارد. در صورتی که معلوم شود شخصی به تبلیغات منفی، نادرست، ناروا و یا
      مغرضانه علیه پی اس ان شاپ پرداخته یا می‌پردازد، خواه این فعالیت‌ها در فضای
      آنلاین باشد خواه آفلاین، پی اس ان شاپ می‌تواند حساب کاربر را حذف و شخص
      خاطی را از سایت اخراج نماید.
      <br /> اطلاعات کاربران نزد سایت محفوظ است و پی اس ان شاپ تمام تلاش خود را
      در جهت حفظ اطلاعات کاربران انجام می‌دهد و سعی می‌کند از امکانات و
      زیرساخت‌های لازم برای این مقصود استفاده نماید. سایت پی اس ان شاپ تمام تلاش
      خود را جهت به‌روزرسانی صفحات و مطالب خود می‌نماید.
      <br />
      سایت می‌تواند بنا به هر دلیلی به‌صورت آزادانه اقدام به حذف یا از دسترس
      خارج نمودن موقتی یا دائمی صفحه خاصی از سایت نماید و این اقدام حقی برای
      اعتراض به کاربران نمی‌دهد. کاربر باید در حفظ و نگهداری اطلاعات خود کوشا
      باشد و اطلاعاتی مانند نام کاربری، اطلاعات تماس و رمزهای عبور را نزد خود
      محرمانه نگه دارد. در صورت تخلف از مفاد این ماده و افشای اطلاعات کاربر،
      سایت پی اس ان شاپ هیچ مسئولیتی ندارد و تمام مسئولیت‌ها و عواقب بر عهده خود
      کاربر است. تمام محتوای سایت پی اس ان شاپ مانند محتوای مقالات، لوگو،
      تصاویر، صوت‌ها، ویدیوها و … متعلق به شرینی سرای پی اس ان شاپ می‌باشد و
      بدون موافقت و اجازه پی اس ان شاپ، هیچ‌کس اجازه استفاده، کپی، بازآفرینی و
      یا هرگونه اقدام خلاف قانون دیگری را نسبت به هر یک از موارد ذکرشده و امثال
      آن ندارد.
      <br />
      شایان ذکر است اگر محتوای سایت از منبع داخلی یا خارجی دیگری اخذشده باشد به
      آن منبع ارجاع داده ‌شده است.
      <br />
      کاربر باید دقت نماید تا آدرس و کدپستی محل دریافت را کاملا دقیق و صحیح وارد
      نماید. درصورتی‌که آدرس اعلام‌شده از سوی کاربر غیردقیق باشد و محصول به
      خریدار نرسد پی اس ان شاپ هیچ‌گونه مسئولیتی ندارد.
      <br />
      در خصوص کالاها نیز پیامکی که حاوی لینک جزئیات سفارش است برای خریدار ارسال
      می‌گردد و در صورت عدم دریافت پیامک، حقی از خریدار نسبت به استفاده از
      کالاهای خریداری‌شده زایل نمی‌گردد. در صورتی که کالایی موجود در سایت توسط
      اعضا خریداری شده باشد که در فروشگاه موجود نباشد؛ با تماس به اعضا، اقدام به
      اعلام عدم موجودی به اعضا و حذف سفارش از سایت میگردد. زمان ارسال کالاهایی
      که برچسبی با عنوان (فقط درون شهری) را دارند معمولا 1 الی 2 ساعته در
      مازندران، محمودآباد تحویل داده می شود، دیگر شهرها معمولا 2 الی 3 روز کاری
      می‌باشد که ممکن است زودتر یا دیرتر تحویل شود.
      <br /> کالاهایی که دارای برچسب (فقط درون شهری) را دارد فقط در شهرستان
      محمودآباد واقع در استان مازندران و حومه قابل ارسال می باشد، چنانچه کاربر
      کالایی با برچسب (فقط درون شهری) را از دیگر استان یا شهر غیر از استان
      مازندران، شهرستان محمودآباد انجام دهد پی اس ان شاپ مسئولیتی را برعهده
      نمیگیرد و میبایست با پشتیبانی پی اس ان شاپ تماس حاصل نماید. منظور از فورس
      ماژور اتفاقاتی با منشا خارجی می‌باشد که خارج از اراده سایت بوده و بدون پیش
      بینی محقق شده باشد؛ مانند قطع سرتاسری اینترنت. در صورت وقوع فورس ماژور پی
      اس ان شاپ هیچ مسئولیتی مانند از دسترس خارج شدن سایت نخواهد داشت. همچنین در
      مواقعی ممکن است بنا به دلایلی سایت با مشکلاتی مانند از دسترس خارج شدن یا
      کندی مواجه شود که در این صورت نیز پی اس ان شاپ مسئولیتی ندارد.
      <br />
      تمام محصولات پی اس ان شاپ فقط از طریق سایت پی اس ان شاپ به نشانی
      (https://psnshop.com) به فروش می‌رسد. هیچ‌یک از محصولات پی اس ان شاپ در
      هیچ مرجع آنلاین دیگری قابل‌ارائه نیست.
      <br />
    </div>
  );
};
