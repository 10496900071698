import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { SITE_NAME } from '../utils/Constants';
import { TitleSetter } from '../utils/HeadSetter';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const NotFound = () => {
  return (
    <Box>
      {TitleSetter('404 | ' + SITE_NAME)}
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Paper sx={{ p: 2, m: 2 }}>
            <Typography variant="h4">{SITE_NAME}</Typography>
            <Divider sx={{ m: 3 }} />
            <Typography variant="h5">صفحه مورد نظر یافت نشد</Typography>
            <Link to={'/admin'} replace={true}>
              <Button
                endIcon={<KeyboardArrowLeftIcon />}
                sx={{ m: 3 }}
                variant="contained"
                size="large"
              >
                صفحه اصلی
              </Button>
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFound;
