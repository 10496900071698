import { useEffect, useState, useContext, Fragment, createRef } from 'react';
import * as yup from 'yup';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate, useParams } from 'react-router-dom';
import httpStatus from 'http-status';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import AdminLayout from '../../layouts/AdminLayout';
import HttpClient, { HandleHttpError } from '../../utils/HttpClient';
import PublicContext from '../../contexts/PublicContext';
import CustomConfirmDialog from '../../components/CustomConfirmDialog';
import { TitleSetter } from '../../utils/HeadSetter';
import { API_URL, SITE_NAME } from '../../utils/Constants';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PriceSplitter from '../../utils/PriceSplitter';

let schema = yup.object().shape({
  ProductStockId: yup.number().required('انتخاب محصول الزامی است'),

  OffPrice: yup
    .number()
    .required('مبلغ تخفیف الزامی است')
    .min(0, 'باید از 0 بزرگتر باشد'),

  CountRemain: yup.number().required('تعداد الزامی است'),

  Code: yup
    .string()
    .required('کد الزامی است')
    .min(3, 'کد حداقل باید 1 حرفی باشد')
    .max(200, 'کد حداکثر باید 200 حرفی باشد'),

  //   .min(3, 'نام حداقل باید 3 حرفی باشد')
  //   .max(80, 'نام حداکثر باید 80 حرفی باشد'),

  // phone: yup
  //   .string()
  //   .test('len', 'شماره مانند نمونه باید 11 رقمی باشد', (value) => {
  //     if (!value || value.length !== 11 || value[0] + value[1] !== '09')
  //       return false;
  //     else return true;
  //   }),
});

const EditGiftCode = () => {
  const router = useNavigate();
  const params = useParams();

  const publicContext = useContext(PublicContext);

  const [loading, setLoading] = useState(true);
  const [OffPrice, setOffPrice] = useState(0);
  const [CountRemain, setCountRemain] = useState(0);
  const [Code, setCode] = useState('');
  const [Stcoks, setStocks] = useState([]);
  const [GiftId, setGiftId] = useState(params['giftId']);
  const [selectedStockId, setSelectedStocksId] = useState(null);

  const [errorMsg, setErrorMsg] = useState([]);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);

  const handleClearErrors = () => setErrorMsg([]);

  const handleChangeOffPrice = (e) => {
    setOffPrice(e.target.value);
  };
  const handleChangeCountRemain = (e) => {
    setCountRemain(e.target.value);
  };
  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  const handleEdit = () => {
    setLoading(true);
    HttpClient.post('/admin/gift-codes/edit', {
      GiftId: GiftId,
      ProductStockId: selectedStockId,
      OffPrice,
      CountRemain,
      Code,
    })
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          publicContext.SnackBarControl.handleNewSnackBar(
            'ویرایش شد',
            'success'
          );
          router('/admin/gift-codes');
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickSave = () => {
    setLoading(true);
    schema
      .validate(
        {
          ProductStockId: selectedStockId,
          OffPrice: OffPrice,
          CountRemain: CountRemain,
          Code: Code.trim(),
        },
        { abortEarly: false }
      )
      .then((dataObj) => {
        if (errorMsg.length > 0) handleClearErrors();
        setOpenConfrimDialog(true);
      })
      .catch(async function (err) {
        if (err instanceof Error && err.errors) {
          setErrorMsg([...err.errors]);
        }

        publicContext.SnackBarControl.handleNewSnackBar(
          'در وارد کردن اطلاعات دقت کنید',
          'warning'
        );
      });
    setLoading(false);
  };
  const fetchInitData = async (_GiftId) => {
    setLoading(true);
    HttpClient.get('/admin/gift-codes/get-all-stocks')
      .then((httpRes) => {
        if (httpRes.status === httpStatus.OK) {
          setStocks([...httpRes.data.data]);

          // get gift id
          HttpClient.post('/admin/gift-codes/get-one', {
            GiftId: GiftId,
          })
            .then((httpRes) => {
              if (httpRes.status === httpStatus.OK) {
                setOffPrice(parseInt(httpRes.data.data.GiftOffPrice) || null);
                setCountRemain(httpRes.data.data.CountRemain || null);
                setCode(httpRes.data.data.Code || null);
                setSelectedStocksId(httpRes.data.data.ProductStockId || null);
                setLoading(false);
              }
            })
            .catch((error) => {
              HandleHttpError(error, publicContext);
              router('/admin/gift-codes');
            });
        }
      })
      .catch((error) => {
        HandleHttpError(error, publicContext);
        router('/admin/gift-codes');
      });
  };
  function handleChangeStock(event) {
    setSelectedStocksId(event.target.value);
  }
  useEffect(() => {
    fetchInitData();
  }, []);

  return (
    <AdminLayout>
      {TitleSetter('ویرایش کد تخفیف | ' + SITE_NAME)}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronRightIcon />}
            variant="contained"
            onClick={() => {
              router('/admin/gift-codes');
            }}
            size="small"
          >
            کد تخفیف
          </Button>

          <Divider sx={{ marginY: 1 }} />
        </Grid>
        {errorMsg.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ paddingY: 1 }}>
              <Alert severity="warning">
                <AlertTitle>
                  پیغام اعتبار سنجی
                  <IconButton color="inherit" onClick={handleClearErrors}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </AlertTitle>
                {errorMsg.map((msg, index) => (
                  <div key={index}> - {msg}</div>
                ))}
              </Alert>
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="stock-label">محصول</InputLabel>
                <Select
                  labelId="stock-label"
                  id="stock"
                  value={selectedStockId}
                  label="محصول"
                  onChange={handleChangeStock}
                >
                  {Stcoks.map((item) => {
                    return (
                      <MenuItem
                        value={item.ProductStockId}
                        key={item.ProductStockId}
                        sx={{ fontSize: 12 }}
                      >
                        <Box>
                          <Box
                            component={'img'}
                            src={`${API_URL}${item.MainImage}`}
                            width={30}
                            height={30}
                            sx={{
                              aspectRatio: '1/1',
                              mx: 1,
                              borderRadius: 1,
                              display: 'inline-flex',
                            }}
                          />{' '}
                          {item.Name} -&nbsp;<span>({item.OptionValue})</span>
                          -&nbsp;
                          <span>({PriceSplitter(item.FinalPrice)} تومان)</span>
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={OffPrice}
                onChange={handleChangeOffPrice}
                fullWidth={true}
                variant="outlined"
                label="مبلغ تخفیف"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={Code}
                onChange={handleChangeCode}
                fullWidth={true}
                variant="outlined"
                label="کد"
                placeholder="نمونه : (JT65uy5v6)"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                value={CountRemain}
                onChange={handleChangeCountRemain}
                fullWidth={true}
                variant="outlined"
                label="تعداد قابل اسفتده (مانده)"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                color="success"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleClickSave}
              >
                ذخیره
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        text={'آیا اطلاعات وارد شده را تایید میکنید؟'}
        open={openConfrimDialog}
        confrimButtonText="بــله"
        declineButtonText="خـیر"
        declineButtonVariant="default"
        confrimButtonVariant="contained"
        loadingDeclineButton={loading}
        loadingConfrimButton={loading}
        handleConfirm={handleEdit}
        handleClose={() => {
          setOpenConfrimDialog(false);
        }}
      />
      <Backdrop
        sx={{
          backgroundColor:
            publicContext.ThemeMode.mode === 'dark' ? '#bcbcbcb0' : '#181818f0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AdminLayout>
  );
};

export default EditGiftCode;
